import React, { useMemo, useState } from 'react'
import FileDownload from 'js-file-download'
import { toast } from 'react-toastify'

import {
    Button,
    CircularProgress,
    IconButton,
    Modal,
    Paper,
    Slide,
    Tooltip,
} from '@material-ui/core'

import { Pagination } from '@material-ui/lab'
import TuneIcon from '@material-ui/icons/Tune'

import {
    ListVendor,
    SEE_VENDOR_FINANCES,
    Service,
    UnitConfig,
    WorkSpaceUser,
    User,
    WorkorderType,
} from '../../models'

import {
    Container,
    CreateWorkorderForm,
    FilterChips,
    MessageForm,
    SideDrawer,
    SideDrawerContainer,
    WorkorderDetailForm,
    WorkorderEditForm,
    WorkorderFilterForm,
} from '../../components'
import { _useFinderSelection, usePagination, useUser } from '../../hooks'
import {
    CreateWorkOrderRequest,
    FinderLocationSelection,
    FinderSelectionMode,
    WorkorderResponse,
} from '../../store'

import { workorderContext } from '../../contexts'
import { ScheduleFinderProps } from '../Scheduler_v2/SchedulerFinder'
import { BaseCreateWorkorderFormProps } from '../../components/WorkorderLib/CreateWorkorderForm/types'
import { hasPermission } from '../../models/Users/services'

import {
    DRAWER_W,
    VH,
    TOP_TOOL_BAR_H,
    AI_LIST_HEADER_H,
    AI_LIST_FOOTER_H,
    AI_HOME_HEADER,
    JANK_SIZE,
} from './contants'
import { AiDrawerId, AiFormState, closedAiFormState } from './types'
import { SearchField } from '../../components/SearchField'

import { ReactComponent as EZTurnLogo } from '../../assets/Logo.svg'
import { EZNowSelectionDrawer } from '../EZNow/EZNowSelectionDrawer'
import { CreateEZNowModal } from '../EZNow/CreateEZNowModal'
import { ActionItemRow } from './ActionItemRow'
import { ArrowDownward, FontDownload, GetApp } from '@material-ui/icons'
import { axiosInstance } from '../../helpers'

interface Props extends ScheduleFinderProps, BaseCreateWorkorderFormProps {
    serviceList: Service[]
    vendorList: ListVendor[]
    unitConfigList: UnitConfig[]
    userList: User[]
    workspaceUser?: WorkSpaceUser
    isServiceRequestList?: boolean
}

export const ActionItemList = (props: Props) => {
    const { theme, isServiceRequestList } = props

    const [formState, setFormState] = useState<AiFormState>(closedAiFormState)

    const openFilterDrawer = () => {
        setFormState({
            ...closedAiFormState,
            filterFormOpen: true,
        })
    }

    const openDetailDrawer = (
        idx: number,
        selectionId: number,
        drawerId: AiDrawerId,
    ) => {
        setFormState({
            ...closedAiFormState,
            detailFormOpen: {
                index: idx,
                id: selectionId,
                drawerId: drawerId,
            },
        })
    }

    const finderFilterSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.RootSelection,
        selectionMode: FinderSelectionMode.Recursive,
    })

    const {
        loadingState,
        createWorkorders,
        workorderFilterState,
        setWorkorderFilterState,
        filteredWorkorderList,
        getChipData,
        updateWorkorderState,
        updateWorkorder,
        transitionWorkorder,
        getWorkorderList,
        workorderSelectionMap: ezNowWorkorderSelectionMap,
        setWorkorderSelectionMap: setEZNowWorkorderSelectionMap,
        deleteWorkorder,
        getServiceRequestChannel,
    } = workorderContext()

    const { workspaceUser } = useUser()

    const [EZNowMode, setEZNowMode] = useState(false)
    const [openEZNowModal, setOpenEZNowModal] = useState(false)
    const [createEZNowRequest, setCreateEZNowRequest] = useState(false)

    const [createModalOpen, setCreateModalOpen] = useState(false)

    const [search, setSearch] = useState('')

    const searchFilteredList = useMemo(() => {
        const lcSearch = search.toLocaleLowerCase()
        return filteredWorkorderList.filter((wo) => {
            const lcTitle = wo.title.toLocaleLowerCase()
            const lsUnitName = wo.unit_name?.toLocaleLowerCase()
            const titleValid = lcTitle.includes(lcSearch)
            const unitNameValid = lsUnitName?.includes(lcSearch)
            const tenantValid =
                isServiceRequestList &&
                wo.service_request?.tenant
                    .toLocaleLowerCase()
                    .includes(lcSearch)
            return titleValid || tenantValid || unitNameValid
        })
    }, [filteredWorkorderList, search])

    const { page, setPage, pageData, numPages, start, end } = usePagination(
        searchFilteredList ?? [],
    )

    const handleCreateWorkorders = (request: CreateWorkOrderRequest) => {
        createWorkorders(request, createEZNowRequest)
            .then(() => {
                setCreateModalOpen(false)
                if (createEZNowRequest) {
                    setOpenEZNowModal(true)
                    setCreateEZNowRequest(false)
                }
            })
            .catch((e) => {
                let errorMessage = <span>Error</span>
                if (e.response?.data?.message !== undefined) {
                    errorMessage = (
                        <Container
                            style={{
                                flexDirection: 'column',
                            }}
                        >
                            <span
                                style={{
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Error
                            </span>
                            <span>- {e.response?.data?.message}</span>
                        </Container>
                    )
                }
                toast.error(errorMessage, {
                    autoClose: 3000,
                })
            })
    }

    const headerStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.darkGreen.main,
    }

    const selectedWo: WorkorderResponse | undefined =
        pageData[formState.detailFormOpen.index]

    const drawerContainerOpen =
        formState.filterFormOpen ||
        (selectedWo?.id === formState.detailFormOpen.id &&
            formState.detailFormOpen.drawerId !== AiDrawerId.editActionItem)

    const openServiceRequestChannel = (
        index: number,
        workorder: WorkorderResponse,
    ) => {
        if (workorder.service_request) {
            if (workorder.service_request.channel_id) {
                setFormState({
                    ...formState,
                    detailFormOpen: {
                        ...formState.detailFormOpen,
                        index: index,
                        id: workorder.id,
                        drawerId: AiDrawerId.serviceRequest,
                    },
                })
            } else {
                getServiceRequestChannel(workorder).then((channelId) => {
                    setFormState({
                        ...formState,
                        detailFormOpen: {
                            ...formState.detailFormOpen,
                            index: index,
                            id: workorder.id,
                            drawerId: AiDrawerId.serviceRequest,
                        },
                    })
                })
            }
        }
    }

    return (
        <SideDrawerContainer open={drawerContainerOpen} width={DRAWER_W}>
            <Container
                style={{
                    border: `1px solid ${theme.palette.grey[400]}`,
                    borderRadius: theme.shape.borderRadius,
                    margin: theme.spacing(1),
                    flex: 1,
                    flexDirection: 'column',
                    maxWidth: '100%',
                }}
            >
                {/* Header */}
                <Container
                    style={{
                        flexDirection: 'column',
                        borderBottom: `1px solid ${theme.palette.grey[400]}`,
                        padding: theme.spacing(1),
                        minHeight: AI_LIST_HEADER_H,
                        maxHeight: AI_LIST_HEADER_H,
                    }}
                >
                    <Container
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                            alignItems: 'center',
                            height: 60,
                            display: 'flex',
                            flexWrap: 'wrap',
                            // width: '100%',
                        }}
                    >
                        {isServiceRequestList ? (
                            <span>Service Requests</span>
                        ) : (
                            <span>Action Items</span>
                        )}

                        <div style={{ flex: 1 }} />
                        {!isServiceRequestList && (
                            <Tooltip title="EZNow">
                                <IconButton
                                    onClick={() => {
                                        setEZNowMode(!EZNowMode)
                                        setWorkorderFilterState({
                                            ...workorderFilterState,
                                            canBeEZNow: !EZNowMode,
                                        })
                                    }}
                                    size="small"
                                >
                                    <EZTurnLogo
                                        style={{ height: 40, width: 40 }}
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                        {!isServiceRequestList && (
                            <Button
                                variant="contained"
                                style={{
                                    marginLeft: theme.spacing(2),
                                    backgroundColor: 'white',
                                    color: theme.palette.primary.dark,
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setCreateModalOpen(true)}
                            >
                                + Create Action Item
                            </Button>
                        )}
                        <Tooltip title="Download Excel Report">
                            <IconButton
                                onClick={() => {
                                    axiosInstance
                                        .post(
                                            'stats/workorder-report/',
                                            {
                                                workorder_list: searchFilteredList.map(
                                                    (wo) => wo.id,
                                                ),
                                            },
                                            { responseType: 'blob' },
                                        )
                                        .then((response) => {
                                            FileDownload(
                                                new Blob([response.data]),
                                                `itemized-report.xlsx`,
                                            )
                                        })
                                }}
                            >
                                <GetApp fontSize="large" />
                            </IconButton>
                        </Tooltip>

                        <IconButton onClick={openFilterDrawer}>
                            <TuneIcon fontSize="large" />
                        </IconButton>
                    </Container>
                    <SearchField
                        value={search}
                        style={{ maxWidth: 300 }}
                        variant="outlined"
                        onChange={(e) => setSearch(e.target.value)}
                    />

                    <FilterChips theme={theme} chips={getChipData()} />

                    <Container>
                        <span
                            style={{
                                ...headerStyle,
                                minWidth: 300,
                                maxWidth: 300,
                            }}
                        >
                            Info
                        </span>
                    </Container>
                </Container>

                {/* Body */}
                <Container
                    style={{
                        minHeight: `calc(${VH} - ${TOP_TOOL_BAR_H} - ${AI_LIST_HEADER_H} - ${AI_LIST_FOOTER_H} - ${AI_HOME_HEADER} - ${JANK_SIZE})`,
                        maxHeight: `calc(${VH} - ${TOP_TOOL_BAR_H} - ${AI_LIST_HEADER_H} - ${AI_LIST_FOOTER_H} - ${AI_HOME_HEADER} - ${JANK_SIZE})`,
                        flexDirection: 'column',
                        flex: 1,
                        overflowY: 'auto',
                        overflowX: 'auto', // Add this line to enable horizontal scrolling
                        maxWidth: '100%',
                    }}
                >
                    {loadingState.getWorkorderList ? (
                        <Container
                            style={{
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CircularProgress size={100} />
                        </Container>
                    ) : (
                        pageData.map((workorder, idx) => {
                            return (
                                <ActionItemRow
                                    key={`ACTION_ITEM_WORKORDER_${workorder.id}`}
                                    workorder={workorder}
                                    onClick={() => {
                                        if (EZNowMode) {
                                            const newEditSelection = {
                                                ...ezNowWorkorderSelectionMap,
                                            }

                                            if (
                                                newEditSelection[
                                                    workorder.id
                                                ] !== undefined
                                            ) {
                                                delete newEditSelection[
                                                    workorder.id
                                                ]
                                            } else {
                                                newEditSelection[
                                                    workorder.id
                                                ] = workorder
                                            }

                                            setEZNowWorkorderSelectionMap(
                                                newEditSelection,
                                            )
                                        } else {
                                            openDetailDrawer(
                                                idx,
                                                workorder.id,
                                                AiDrawerId.actionItem,
                                            )
                                        }
                                    }}
                                    isSelected={
                                        idx ===
                                            formState.detailFormOpen.index &&
                                        workorder.id ===
                                            formState.detailFormOpen.id
                                    }
                                    openMessageDrawer={() => {
                                        openDetailDrawer(
                                            idx,
                                            workorder.id,
                                            AiDrawerId.message,
                                        )
                                    }}
                                    drawerOpen={drawerContainerOpen}
                                    EZNowSelected={
                                        EZNowMode &&
                                        ezNowWorkorderSelectionMap[
                                            workorder.id
                                        ] !== undefined
                                    }
                                    user={props.workspaceUser}
                                    transitionWorkorder={transitionWorkorder}
                                    openServiceRequestChannelDrawer={() =>
                                        openServiceRequestChannel(
                                            idx,
                                            workorder,
                                        )
                                    }
                                />
                            )
                        })
                    )}
                </Container>

                {/* Footer */}
                <Container
                    style={{
                        borderTop: `1px solid ${theme.palette.grey[400]}`,
                        padding: theme.spacing(1),
                        minHeight: AI_LIST_FOOTER_H,
                        maxHeight: AI_LIST_FOOTER_H,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Pagination
                        count={numPages}
                        page={page}
                        onChange={(_: unknown, newPage: number) => {
                            setPage(newPage)
                        }}
                    />
                    <span>
                        {start} - {end} of {filteredWorkorderList.length}
                    </span>
                </Container>

                <Modal
                    open={createModalOpen}
                    onClose={() => {
                        setCreateModalOpen(false)
                        setCreateEZNowRequest(false)
                    }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Slide direction="up" in={createModalOpen}>
                        <Paper style={{}}>
                            <CreateWorkorderForm
                                {...props}
                                onClickCreate={handleCreateWorkorders}
                                createEZNowRequest={createEZNowRequest}
                                setCreateEZNowRequest={setCreateEZNowRequest}
                            />
                        </Paper>
                    </Slide>
                </Modal>

                <Modal
                    open={
                        formState.detailFormOpen.drawerId ===
                        AiDrawerId.editActionItem
                    }
                    onClose={() => setFormState(closedAiFormState)}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Slide
                        direction="up"
                        in={
                            formState.detailFormOpen.drawerId ===
                            AiDrawerId.editActionItem
                        }
                    >
                        <Paper style={{}}>
                            <WorkorderEditForm
                                theme={theme}
                                serviceList={props.serviceList}
                                root={props.root}
                                userList={props.userList}
                                vendorList={props.vendorList}
                                workorder={selectedWo}
                                handleClose={() =>
                                    setFormState(closedAiFormState)
                                }
                                areaConfigMap={props.areaConfigMap}
                                handleSubmit={(request) => {
                                    return updateWorkorder(request)
                                }}
                                loading={loadingState.updateWorkorder}
                            />
                        </Paper>
                    </Slide>
                </Modal>

                <SideDrawer
                    open={formState.filterFormOpen}
                    width={DRAWER_W}
                    handleClose={() => setFormState(closedAiFormState)}
                >
                    <WorkorderFilterForm
                        theme={theme}
                        root={props.root}
                        finderSelection={finderFilterSelection}
                        serviceList={props.serviceList}
                        vendorList={props.vendorList}
                        userList={props.userList}
                        unitConfigList={props.unitConfigList}
                        workorderFilterState={workorderFilterState}
                        setWorkorderFilterState={setWorkorderFilterState}
                    />
                </SideDrawer>

                <EZNowSelectionDrawer
                    open={EZNowMode}
                    selectedWorkorderMap={ezNowWorkorderSelectionMap}
                    setSelectedWorkordersMap={setEZNowWorkorderSelectionMap}
                    onClose={() => {
                        setEZNowMode(false)
                        setWorkorderFilterState({
                            ...workorderFilterState,
                            canBeEZNow: false,
                        })
                    }}
                    setOpenEZNowModal={setOpenEZNowModal}
                />

                <CreateEZNowModal
                    open={openEZNowModal}
                    handleClose={() => {
                        setOpenEZNowModal(false)
                        getWorkorderList({
                            params: {
                                workorder_type: WorkorderType.ACTION_ITEM,
                            },
                        })
                    }}
                    selectedWorkordersMap={ezNowWorkorderSelectionMap}
                    setSelectedWorkordersMap={setEZNowWorkorderSelectionMap}
                />

                <SideDrawer
                    open={
                        selectedWo?.id === formState.detailFormOpen.id &&
                        formState.detailFormOpen.drawerId ===
                            AiDrawerId.actionItem
                    }
                    width={DRAWER_W}
                    handleClose={() => setFormState(closedAiFormState)}
                >
                    <WorkorderDetailForm
                        workspaceUser={workspaceUser}
                        theme={theme}
                        handleClose={() => setFormState(closedAiFormState)}
                        workorder={selectedWo}
                        openMessageDrawer={() => {
                            openDetailDrawer(
                                formState.detailFormOpen.index,
                                formState.detailFormOpen.id,
                                AiDrawerId.message,
                            )
                        }}
                        showFinances={hasPermission(
                            props.workspaceUser,
                            SEE_VENDOR_FINANCES,
                        )}
                        onClickEdit={() =>
                            openDetailDrawer(
                                formState.detailFormOpen.index,
                                formState.detailFormOpen.id,
                                AiDrawerId.editActionItem,
                            )
                        }
                        deleteWorkorder={deleteWorkorder}
                    />
                </SideDrawer>

                <SideDrawer
                    open={
                        selectedWo?.id === formState.detailFormOpen.id &&
                        formState.detailFormOpen.drawerId === AiDrawerId.message
                    }
                    width={DRAWER_W}
                    handleClose={() => setFormState(closedAiFormState)}
                >
                    <MessageForm
                        theme={theme}
                        channelId={selectedWo?.channel}
                        secondaryChannelId={selectedWo?.inv_ins_channel}
                        afterCreateMessage={() => {
                            if (selectedWo) {
                                updateWorkorderState({
                                    ...selectedWo,
                                    message_count:
                                        (selectedWo.message_count ?? 0) + 1,
                                })
                            }
                        }}
                        secondaryTitle="From Inventory Inspection"
                    />
                </SideDrawer>

                <SideDrawer
                    open={
                        selectedWo?.id === formState.detailFormOpen.id &&
                        formState.detailFormOpen.drawerId ===
                            AiDrawerId.serviceRequest
                    }
                    width={DRAWER_W}
                    handleClose={() => setFormState(closedAiFormState)}
                    title="Tenant Service Request Messages"
                >
                    <MessageForm
                        theme={theme}
                        channelId={
                            selectedWo?.service_request?.channel_id ?? undefined
                        }
                        afterCreateMessage={() => {
                            if (selectedWo && selectedWo.service_request) {
                                updateWorkorderState({
                                    ...selectedWo,
                                    service_request: {
                                        ...selectedWo.service_request,
                                        message_count:
                                            (selectedWo.service_request
                                                .message_count ?? 0) + 1,
                                        unread_count: 0,
                                    },
                                })
                            }
                        }}
                    />
                </SideDrawer>
            </Container>
        </SideDrawerContainer>
    )
}
