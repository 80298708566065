import { useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import { axiosInstance } from '../helpers/axios'
import { useUser } from './useUser'
import {
    ChatLoadingState,
    ProjectPlanResponse,
    HelpVideo,
    Conversation,
    ChatMessageContent,
    FeedbackContext,
    MessageFeedback,
    ConversationAnalysisResponse,
    Workspace,
} from '../navigation/layouts/Lori/types'
import { useHistory } from 'react-router-dom'
import { VENDOR } from '../helpers/permissions'

export const useLori = () => {
    const [loadingState, setLoadingState] = useState<ChatLoadingState>({
        videoLibrary: false,
        conversationHistory: false,
        conversationAnalysis: false,
    })

    const [projectPlan, setProjectPlan] = useState<ProjectPlanResponse | null>(
        null,
    )
    const [projectPlanList, setProjectPlanList] = useState<
        ProjectPlanResponse[]
    >([])
    const [videoLibrary, setVideoLibrary] = useState<HelpVideo[]>([])
    const [categories, setCategories] = useState<string[]>([])
    const [conversationHistory, setConversationHistory] = useState<
        Conversation[]
    >([])
    const [pinnedConversations, setPinnedConversations] = useState<
        Conversation[]
    >([])
    const [
        activeConversation,
        setActiveConversation,
    ] = useState<Conversation | null>(null)
    const [thinking, setThinking] = useState<boolean>(false)
    const [socket, setSocket] = useState<WebSocket | null>(null)
    const [conversationFeedback, setConversationFeedback] = useState<
        MessageFeedback[]
    >([])

    const [
        conversationAnalysis,
        setConversationAnalysis,
    ] = useState<ConversationAnalysisResponse | null>(null)

    const [workspaces, setWorkspaces] = useState<Workspace[]>([
        { id: -1, name: 'All' },
    ])

    const { workspaceUser } = useUser()
    const history = useHistory()
    const WEBSOCKET_BASE_URL = process.env.REACT_APP_WEBSOCKET_BASE_URL

    // load video library for lori
    useEffect(() => {
        getVideoLibrary()
    }, [])

    useEffect(() => {
        const timeout = setTimeout(() => {
            createWebSocket()
        }, 10000)
        return () => {
            if (socket) {
                socket.close()
            }
        }
    }, [activeConversation?.id, workspaceUser?.active_workspace.id])

    // get updated data for user for different workspace
    useEffect(() => {
        console.log('workspace user changed')
        // close the socket so that the correct conversation id can be set in the socket.
        if (socket) {
            socket.close()
        }
        setActiveConversation(null)
        getConversationHistory(workspaceUser?.active_workspace.id)
        getProjectPlanList(true)
    }, [workspaceUser?.active_workspace.id])

    useEffect(() => {
        if (activeConversation === null) {
            updateConversationState(true)
        }
    }, [conversationHistory])

    const createConversation = async () => {
        await axiosInstance
            .post('/chat/conversation/', {})
            .then((res) => {
                setActiveConversation(res.data)
                setThinking(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getConversationHistory = async (apartmentId: number | undefined) => {
        if (apartmentId === undefined) return
        setLoadingState((prevState) => ({
            ...prevState,
            conversationHistory: true,
        }))
        axiosInstance
            .get('/chat/conversation/', {
                params: {
                    apartment_id: apartmentId,
                },
            })
            .then((res) => {
                if (res.data.length === 0) {
                    createConversation()
                    return
                }
                setConversationHistory(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoadingState((prevState) => ({
                    ...prevState,
                    conversationHistory: false,
                }))
            })
    }

    const updateConversationState = async (setActive: boolean = false) => {
        if (conversationHistory.length === 0) return
        // Set pinned conversations
        const pinned = conversationHistory.filter(
            (conversation: Conversation) => conversation.pinned,
        )
        setPinnedConversations(pinned)

        // set the latest conversation as the active conversation
        if (setActive) {
            const mostRecentConversation = conversationHistory.reduce(
                (latest: Conversation, current: Conversation) => {
                    return new Date(current.created_date) >
                        new Date(latest.created_date)
                        ? current
                        : latest
                },
                conversationHistory[0],
            )
            setActiveConversation(mostRecentConversation)
            getFeedback(mostRecentConversation.id)
        }
    }

    const pinConversation = async (conversationId: number | null) => {
        if (conversationId === null) return
        await axiosInstance
            .post(`/chat/conversation/${conversationId}/pin/`)
            .then((res) => {
                // setActiveConversation(res.data)
                setPinnedConversations([...pinnedConversations, res.data])
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getFeedback = async (conversationId: number) => {
        await axiosInstance
            .get(`/chat/conversation/${conversationId}/get_feedback/`)
            .then((res) => {
                setConversationFeedback(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const addFeedback = async (
        feedback: boolean,
        context: FeedbackContext,
        chatMessageContentId: number,
    ) => {
        await axiosInstance
            .post(
                `/chat/conversation/${activeConversation?.id}/add_feedback/`,
                {
                    feedback,
                    context,
                    chat_message_content_id: chatMessageContentId,
                },
            )
            .then((res) => {
                // Update local feedback state
                setConversationFeedback((prev) => {
                    const existing = prev.findIndex(
                        (f) => f.message_id === chatMessageContentId,
                    )
                    if (existing >= 0) {
                        return prev.map((f) =>
                            f.message_id === chatMessageContentId
                                ? { ...f, feedback }
                                : f,
                        )
                    }
                    return [
                        ...prev,
                        { message_id: chatMessageContentId, feedback },
                    ]
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const addVideo = async (url: string, category: string) => {
        await axiosInstance
            .post('/chat/help_video_library/', { url, category })
            .then((res) => {
                toast.success('Video added')
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const removeVideo = async (id: number) => {
        await axiosInstance
            .delete(`/chat/help_video_library/${id}/`)
            .then((res) => {
                toast.success('Video removed')
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const updateVideo = async (helpVideo: HelpVideo) => {
        await axiosInstance
            .put(`/chat/help_video_library/${helpVideo.id}/`, helpVideo)
            .then((res) => {
                setVideoLibrary((prevLibrary) =>
                    prevLibrary.map((video) =>
                        video.id === helpVideo.id ? helpVideo : video,
                    ),
                )
                toast.success('Video updated')
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const addVideoView = async (id: number) => {
        await axiosInstance
            .post(`/chat/help_video_library/${id}/add_video_view/`)
            .then((res) => {
                console.log('video view added', res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getVideoLibrary = async () => {
        setLoadingState((prevState) => ({ ...prevState, videoLibrary: true }))
        await axiosInstance
            .get('/chat/help_video_library/')
            .then((res) => {
                setVideoLibrary(res.data)
                setLoadingState((prevState) => ({
                    ...prevState,
                    videoLibrary: false,
                }))
            })
            .catch((err) => {
                console.log(err)
                setLoadingState((prevState) => ({
                    ...prevState,
                    videoLibrary: false,
                }))
            })
    }

    const getCategories = async () => {
        await axiosInstance
            .get('/chat/help_video_library/categories/')
            .then((res) => {
                console.log('categories', res.data)
                const formattedCategories = res.data.map((category: string) => {
                    // Split by underscore, capitalize first letter of each word, then join with space
                    return category
                        .split('_')
                        .map(
                            (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1),
                        )
                        .join(' ')
                })
                setCategories(formattedCategories)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const addCategory = async (category: string) => {
        await axiosInstance
            .post('/chat/help_video_library/add_category/', { category })
            .then((res) => {
                setCategories([...categories, category])
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getProjectPlanList = async (force_company: boolean = false) => {
        await axiosInstance
            .get('/chat/project_plan/', { params: { force_company } })
            .then((res) => {
                setProjectPlanList(res.data)
                const mostRecentProjectPlan = res.data.reduce(
                    (
                        latest: ProjectPlanResponse,
                        current: ProjectPlanResponse,
                    ) => {
                        return new Date(current.created_date) >
                            new Date(latest.created_date)
                            ? current
                            : latest
                    },
                    res.data[0],
                )
                setProjectPlan(mostRecentProjectPlan)
            })
    }

    const updateTask = async (taskId: number, completed: boolean) => {
        await axiosInstance
            .post('/chat/project_plan/update_task/', {
                task_id: taskId,
                completed,
            })
            .then((res) => {
                setProjectPlan((prevPlan) => {
                    if (!prevPlan) return null
                    return {
                        ...prevPlan,
                        tasks: prevPlan.tasks.map((task) =>
                            task.id === taskId ? { ...task, completed } : task,
                        ),
                    }
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const createWebSocket = useCallback(() => {
        if (
            !activeConversation?.id ||
            !WEBSOCKET_BASE_URL ||
            !workspaceUser?.active_workspace.id ||
            workspaceUser?.active_workspace.company_type === VENDOR
        ) {
            return
        }

        // Add reconnection attempt counter
        const reconnectAttempt = (socket as any)?.reconnectAttempt || 0
        const maxReconnectDelay = 32000 // 32 seconds max delay
        const baseDelay = 10000 // 1 second initial delay

        const url = `${WEBSOCKET_BASE_URL}chat/${activeConversation.id}/${workspaceUser?.active_workspace.id}/`
        const newSocket = new WebSocket(url)

        console.log(newSocket)

        // Store reconnection attempt counter on socket instance
        ;(newSocket as any).reconnectAttempt = reconnectAttempt

        newSocket.onopen = function () {
            console.log('WebSocket connection opened')
            ;(newSocket as any).reconnectAttempt = 0
        }

        newSocket.onmessage = function (e) {
            const message: ChatMessageContent = JSON.parse(e.data)
            if (message.content_type === 'error') {
                setThinking(false)
                return
            }
            setThinking(false)
            setActiveConversation((prevConversation) => {
                if (!prevConversation) return null
                return {
                    ...prevConversation,
                    chat_messages: [
                        ...prevConversation.chat_messages,
                        {
                            id: message.id,
                            role: message.role,
                            content_type: message.content_type,
                            function_call: message.function_call,
                            function_call_response:
                                message.function_call_response,
                            message_items: message.message_items,
                        },
                    ],
                }
            })
        }

        newSocket.onclose = function (e) {
            console.log('WebSocket closed:', e)
            if (e.code === 1000) {
                // 1000 is the code for a normal close
                // If the connection is closed by the server, we don't need to reconnect
                return
            }

            // Calculate delay with exponential backoff
            const delay = Math.min(
                Math.pow(2, (newSocket as any).reconnectAttempt) * baseDelay,
                maxReconnectDelay,
            )

            setTimeout(() => {
                // Increment reconnection attempt counter
                const ns = newSocket as any
                ns.reconnectAttempt++
                console.log(
                    `Attempting to reconnect... Attempt ${ns.reconnectAttempt}`,
                )
                createWebSocket()
            }, delay)
        }

        newSocket.onerror = function (error) {
            console.log('WebSocket error:', error)
        }

        setSocket(newSocket)
    }, [activeConversation?.id, workspaceUser?.active_workspace.id])

    const handleSendMessage = (
        message: string,
        displayMessage: boolean = true,
    ) => {
        if (message.trim()) {
            socket?.send(JSON.stringify({ message: message }))
            if (displayMessage && activeConversation) {
                setActiveConversation((prevConversation) => {
                    if (!prevConversation) return null
                    return {
                        ...prevConversation,
                        chat_messages: [
                            ...prevConversation.chat_messages,
                            {
                                id: null,
                                role: 'user',
                                content_type: 'text',
                                function_call: null,
                                message_items: [
                                    {
                                        key: 'message',
                                        value: message,
                                    },
                                ],
                            },
                        ],
                    }
                })
                setThinking(true)
            }
        }
    }

    const getConversationAnalysis = async (
        page_size?: number,
        workspace_id?: number,
        exclude_ezos_users?: boolean,
        page?: number,
        order_by?: string,
        order?: 'asc' | 'desc',
    ) => {
        await axiosInstance
            .get(`/chat/conversation-analytics/`, {
                params: {
                    page_size,
                    workspace_id,
                    exclude_ezos_users,
                    page,
                    order_by,
                    order,
                },
            })
            .then((res) => {
                setConversationAnalysis(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getWorkspaces = async () => {
        await axiosInstance
            .get('/chat/conversation-analytics/workspaces')
            .then((res) => {
                setWorkspaces([{ id: -1, name: 'All' }, ...res.data])
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return {
        loadingState,
        projectPlan,
        categories,
        conversationHistory,
        thinking,
        setThinking,
        addVideo,
        removeVideo,
        videoLibrary,
        getVideoLibrary,
        getCategories,
        addCategory,
        updateTask,
        activeConversation,
        setActiveConversation,
        createConversation,
        socket,
        getConversationHistory,
        pinConversation,
        pinnedConversations,
        getProjectPlanList,
        projectPlanList,
        updateVideo,
        addVideoView,
        addFeedback,
        handleSendMessage,
        conversationFeedback,
        getConversationAnalysis,
        conversationAnalysis,
        getWorkspaces,
        workspaces,
    }
}

export type LoriController = ReturnType<typeof useLori>
