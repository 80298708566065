import React from 'react'
import { makeStyles, Theme, Typography } from '@material-ui/core'
import { ChatMessageContent } from '../../types'
import { Container } from '../../../../../components/Container'
import { DeadlineCard } from './CustomCards/DeadlineCard'
import { ProjectCard } from './CustomCards/ProjectCard'
import { HowToVideoCard } from './CustomCards/HowToVideoCard'
import { ContactSupportButton } from './CustomCards/ContactSupportButton'
import { LoriController } from '../../../../../hooks/useLori'
import { UserFeedback } from './CustomCards/UserFeedback'
import { RenderCards } from './RenderCards'

interface ChatMessageProps {
    message: ChatMessageContent
    theme: Theme
    loriController: LoriController
}

export const ChatMessage = ({
    message,
    theme,
    loriController,
}: ChatMessageProps) => {
    const { role, function_call } = message
    const isUser = role === 'user'

    const doNotShowMessage =
        'I am not happy with those results. Can I contact support.'

    const assistantMessage = () => {
        if (message.message_items && message.message_items.length > 0) {
            if (message.message_items[0]?.value !== doNotShowMessage) {
                return message.message_items[0]?.value
            }
        }
        return null
    }

    return (
        <Container
            style={{
                display: 'flex',
                marginBottom: theme.spacing(1),
                justifyContent: isUser ? 'flex-end' : 'flex-start',
                alignItems: 'center',
                position: 'relative',
                padding: 0,
            }}
        >
            <div
                style={{
                    position: 'relative',
                    maxWidth: function_call ? '100%' : '90%',
                    width: function_call ? 'calc(100% - 24px)' : 'auto',
                    flex: function_call ? 1 : 'initial',
                }}
            >
                {function_call ? (
                    <RenderCards
                        function_call={function_call}
                        loriController={loriController}
                        message={message}
                    />
                ) : (
                    <Typography
                        component="div"
                        style={{
                            padding: theme.spacing(1, 1),
                            borderRadius: theme.shape.borderRadius * 3,
                            backgroundColor: isUser
                                ? theme.palette.primary.main
                                : theme.palette.grey[100],
                            color: isUser
                                ? theme.palette.primary.contrastText
                                : theme.palette.text.primary,
                            borderBottomRightRadius: isUser
                                ? theme.shape.borderRadius
                                : undefined,
                            borderBottomLeftRadius: !isUser
                                ? theme.shape.borderRadius
                                : undefined,
                            borderTopLeftRadius: isUser
                                ? theme.shape.borderRadius * 2
                                : undefined,
                            borderTopRightRadius: !isUser
                                ? theme.shape.borderRadius * 2
                                : undefined,
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {assistantMessage()}
                    </Typography>
                )}
            </div>
        </Container>
    )
}
