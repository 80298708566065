import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { IdentifiableObject, ServiceRequest } from '../models'
import { axiosInstance } from '../helpers'
import { WorkorderResponse } from '../store'

export const useServiceRequest = () => {
    const [serviceRequestList, setServiceRequestList] = useState<
        ServiceRequest[]
    >([])

    const [
        serviceRequestFilterState,
        setServiceRequestFilterState,
    ] = useState<ServiceRequestFilterState>({
        searchText: '',
        sortValue: ServiceRequestSort.submittedDesc,
    })

    const getServiceRequestList = async (request: ServiceRequestRequest) => {
        try {
            const res = await axiosInstance.get(
                'service-request/apartment_service_request/',
                request,
            )
            const serviceRequests: ServiceRequest[] = res.data
            setServiceRequestList(serviceRequests)
            return serviceRequests
        } catch (e) {
            toast.error('Error getting service requests')
            return Promise.reject(e)
        }
    }

    const createServiceRequestWorkorder = async (
        request: CreateServiceRequestWorkorderRequest,
    ) => {
        try {
            const res = await axiosInstance.post(
                'service-request/apartment_service_request/create_workorder/',
                request,
            )
            const workorder: WorkorderResponse = res.data
            toast.success('Workorder created successfully!')
            _removeIdentifiable(request.service_request, setServiceRequestList)
            return workorder
        } catch (e: any) {
            toast.error(e.response.data.message)
            return null
        }
    }

    const deleteServiceRequest = async (serviceRequest: ServiceRequest) => {
        try {
            const res = await axiosInstance.delete(
                `service-request/apartment_service_request/${serviceRequest.id}/`,
            )
            toast.success('Service request deleted successfully!')
            _removeIdentifiable(serviceRequest.id, setServiceRequestList)
        } catch (e) {
            toast.error('Error deleting service request')
            return Promise.reject(e)
        }
    }

    const _removeIdentifiable = <T extends IdentifiableObject>(
        deleteValue: number,
        dispatch: Dispatch<SetStateAction<T[]>>,
    ) => {
        dispatch((old) => {
            const safeOldValue = old ? [...old] : []

            return safeOldValue.filter((v) => v.id !== deleteValue)
        })
    }

    const filteredServiceRequestList = useMemo(() => {
        const filter = serviceRequestFilterState
        return serviceRequestList
            .filter((serviceRequest) => {
                const searchValid =
                    filter.searchText === '' ||
                    serviceRequest.tenant
                        ?.toLocaleLowerCase()
                        .includes(filter.searchText.toLocaleLowerCase()) ||
                    serviceRequest.unit_name
                        ?.toLocaleLowerCase()
                        .includes(filter.searchText.toLocaleLowerCase())

                return searchValid
            })
            .sort((serviceRequestA, serviceRequestB) => {
                switch (filter.sortValue) {
                    case ServiceRequestSort.submittedDesc:
                        return (
                            new Date(serviceRequestB.created_date).getTime() -
                            new Date(serviceRequestA.created_date).getTime()
                        )
                    case ServiceRequestSort.submittedAsc:
                        return (
                            new Date(serviceRequestA.created_date).getTime() -
                            new Date(serviceRequestB.created_date).getTime()
                        )
                    case ServiceRequestSort.tenantAsc:
                        return serviceRequestA.tenant.localeCompare(
                            serviceRequestB.tenant,
                        )
                    case ServiceRequestSort.tenantDesc:
                        return serviceRequestB.tenant.localeCompare(
                            serviceRequestA.tenant,
                        )
                    case ServiceRequestSort.unitAsc:
                        return (serviceRequestA.unit_name || '').localeCompare(
                            serviceRequestB.unit_name || '',
                        )
                    case ServiceRequestSort.unitDesc:
                        return (serviceRequestB.unit_name || '').localeCompare(
                            serviceRequestA.unit_name || '',
                        )
                    default:
                        return 0
                }
            })
    }, [serviceRequestList, serviceRequestFilterState])

    return {
        serviceRequestList,
        filteredServiceRequestList,
        serviceRequestFilterState,
        setServiceRequestFilterState,
        getServiceRequestList,
        createServiceRequestWorkorder,
        deleteServiceRequest,
    }
}

export interface ServiceRequestRequest {
    params?: {
        no_workorder?: boolean
    }
}

export interface CreateServiceRequestWorkorderRequest {
    service_request: number
    vendor: number
    service: number
    start_date?: string
    end_date?: string
    title: string
    priority?: boolean
}

export enum ServiceRequestSort {
    submittedAsc,
    submittedDesc,
    tenantAsc,
    tenantDesc,
    unitAsc,
    unitDesc,
}

export interface ServiceRequestFilterState {
    searchText: string
    sortValue: ServiceRequestSort
}
