import React, { useState, useEffect } from 'react'
import { Container } from '../../components/Container'
import {
    Checkbox,
    ListItemText,
    MenuItem,
    FormControl,
    Typography,
    useTheme,
    InputLabel,
    IconButton,
    Tooltip,
    CircularProgress,
} from '@material-ui/core'
import Plot from 'react-plotly.js'
import { KPIController } from '../../hooks/useKPI'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { normalizeString } from '../../helpers/stringHelpers'
import {
    formatNumber,
    padNumberForAlignment,
} from '../../helpers/numberHelpers'
import { DamageStatus } from '../../models/Lease'
import CheckIcon from '@material-ui/icons/CheckCircle'
import { currencyFormatterNoDecimals } from '../../helpers/CurrencyFormatter'

export const Damages = ({
    KPIController,
}: {
    KPIController: KPIController
}) => {
    const theme = useTheme()
    const [plotWidth, setPlotWidth] = useState(0)
    const [plotHeight, setPlotHeight] = useState(0)

    const apartmentStatusChoices = [
        'ACTIVE',
        'IN_ACTIVE',
        'SUSPENDED',
        'IN_HOUSE',
        'TEST',
    ]
    const [selectedApartmentStatus, setSelectedApartmentStatus] = useState<
        string[]
    >(['ACTIVE', 'IN_ACTIVE'])

    const damageStatusChoices = [
        'PENDING',
        'APPROVED',
        'DENIED',
        'PRE_APPROVED',
        'SYNC_PENDING',
        'ENTRATA_SYNCED',
        'SYNC_FAILED',
    ]
    const [selectedDamageStatus, setSelectedDamageStatus] = useState<string[]>(
        damageStatusChoices,
    )

    useEffect(() => {
        KPIController.getDamages(selectedApartmentStatus, selectedDamageStatus)
    }, [])

    // set the plot size
    useEffect(() => {
        const updateDimensions = () => {
            // Get the container element and calculate dimensions
            const container = document.querySelector('.plot-container')
            if (container) {
                // Subtracting 20px from each dimension for padding
                setPlotWidth(container.clientWidth - 20)
                setPlotHeight(container.clientHeight - 20)
            }
        }
        // Initial calculation
        updateDimensions()

        // Add resize listener
        window.addEventListener('resize', updateDimensions)

        // Cleanup
        return () => window.removeEventListener('resize', updateDimensions)
    }, [])

    const handleApartmentStatusChange = (
        event: SelectChangeEvent<typeof selectedApartmentStatus>,
    ) => {
        const {
            target: { value },
        } = event
        setSelectedApartmentStatus(
            typeof value === 'string' ? value.split(',') : value,
        )
    }

    const handleDamageStatusChange = (
        event: SelectChangeEvent<typeof selectedDamageStatus>,
    ) => {
        const {
            target: { value },
        } = event
        setSelectedDamageStatus(
            typeof value === 'string' ? value.split(',') : value,
        )
    }

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                height: 'calc(100vh - 200px)',
            }}
            className="plot-container"
        >
            {/* header */}
            <Container
                style={{
                    flexDirection: 'row',
                }}
            >
                {/* selectors */}
                <Container
                    style={{
                        flexDirection: 'row',
                    }}
                >
                    <FormControl
                        variant="outlined"
                        style={{
                            width: 300,
                            margin: theme.spacing(2),
                        }}
                    >
                        <InputLabel id="apartment-status-label">
                            Apartment Status
                        </InputLabel>
                        <Select
                            labelId="apartment-status-label"
                            value={selectedApartmentStatus}
                            onChange={handleApartmentStatusChange}
                            multiple
                            renderValue={(selected) => ''}
                        >
                            {apartmentStatusChoices.map((status) => (
                                <MenuItem key={status} value={status}>
                                    <Checkbox
                                        checked={selectedApartmentStatus.includes(
                                            status,
                                        )}
                                    />
                                    <ListItemText
                                        primary={normalizeString(status)}
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        variant="outlined"
                        style={{
                            width: 300,
                            margin: theme.spacing(2),
                        }}
                    >
                        <InputLabel id="damage-status-label">
                            Damage Status
                        </InputLabel>
                        <Select
                            labelId="damage-status-label"
                            value={selectedDamageStatus}
                            onChange={handleDamageStatusChange}
                            multiple
                            renderValue={(selected) => ''}
                        >
                            {damageStatusChoices.map((status) => (
                                <MenuItem key={status} value={status}>
                                    <Checkbox
                                        checked={selectedDamageStatus.includes(
                                            status,
                                        )}
                                    />
                                    <ListItemText
                                        primary={normalizeString(status)}
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Container>

                {/* apply button */}
                <Container
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Tooltip title="Apply Filters">
                        <IconButton
                            onClick={() => {
                                KPIController.getDamages(
                                    selectedApartmentStatus,
                                    selectedDamageStatus,
                                )
                                KPIController.setDamages(null)
                            }}
                        >
                            <CheckIcon
                                style={{
                                    color: theme.palette.primary.main,
                                }}
                                fontSize="large"
                            />
                        </IconButton>
                    </Tooltip>
                </Container>

                {/* showing the selected filters */}
                <Container
                    style={{
                        flexDirection: 'column',
                    }}
                >
                    <Container
                        style={{
                            flexDirection: 'column',
                        }}
                    >
                        <Container
                            style={{
                                flexDirection: 'column',
                            }}
                        >
                            <Typography>Apartment Status Filter: </Typography>
                            <Typography
                                style={{
                                    fontWeight: 'bold',
                                }}
                            >
                                {KPIController.displayFilteredApartmentStatus
                                    .map((status) => normalizeString(status))
                                    .join(', ')}
                            </Typography>
                            <Typography>Damage Status Filter: </Typography>
                            <Typography
                                style={{
                                    fontWeight: 'bold',
                                }}
                            >
                                {KPIController.displayFilteredDamageStatus
                                    .map((status) => normalizeString(status))
                                    .join(', ')}
                            </Typography>
                        </Container>
                    </Container>
                </Container>
            </Container>

            {/* plot */}
            <Container
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {KPIController.damages ? (
                    <Plot
                        data={[
                            {
                                type: 'bar',
                                x: KPIController.damages.x,
                                y: KPIController.damages.y,
                                text: KPIController.damages.y.map((value) =>
                                    currencyFormatterNoDecimals.format(value),
                                ),
                                textposition: 'outside',
                                textfont: {
                                    size: 14,
                                },
                                marker: { color: '#6b71d1' },
                                hovertemplate:
                                    '%{x|%B, %Y}<br>Cost: %{y}<extra></extra>',
                            },
                        ]}
                        layout={{
                            width: plotWidth,
                            height: plotHeight,
                            title: {
                                text: 'Damages',
                            },
                            margin: { t: 50, b: 50, l: 50, r: 50 },
                        }}
                        useResizeHandler={true}
                        style={{ width: '100%', height: '100%' }}
                    />
                ) : (
                    <Container
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress />
                    </Container>
                )}
            </Container>
        </Container>
    )
}
