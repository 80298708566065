import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import { IconButton, makeStyles, Tooltip, useTheme } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import { _useFinderSelection, useCompany, useUser } from '../../../hooks'
import {
    AddRemoveVendorAptRequest,
    UpdateWorkOrderRequest,
    WorkorderResponse,
} from '../../../store'
import { format, isToday } from 'date-fns'
import { VendorCell } from '../JobBoardComponents/VendorCell'
import { VendorHeaderCell } from '../JobBoardComponents/VendorHeaderCell'

import {
    ListVendor,
    Service,
    Schedule,
    ModelMap,
    Company,
    WorkorderType,
} from '../../../models'
import { resolveRoute, Routes } from '../../../helpers'
import { JbDrawerId } from '../types'
import { workorderContext } from '../../../contexts'
import { toast } from 'react-toastify'

interface Props {
    filteredWorkorderList: WorkorderResponse[]
    workorderList: WorkorderResponse[]
    vendorList: ListVendor[]
    serviceList: Service[]
    workorderMap: Map<string, WorkorderResponse[]>
    sortedVendors: ListVendor[]
    dateRange: Date[]
    scheduleList: Schedule[]
    isMultiselectMode: boolean
    selectedWorkorders: ModelMap<WorkorderResponse>
    apartmentMap: ModelMap<Company>
    organization: number | null
    apartmentList: Company[]
    apartmentVendorMap: Record<number, number[]>
    isLoading: boolean
    setSelectedWorkorders: React.Dispatch<
        React.SetStateAction<ModelMap<WorkorderResponse>>
    >
    openDetailDrawer: (
        workorder: WorkorderResponse,
        drawerId: JbDrawerId,
    ) => void
    addVendorToApartment: (req: AddRemoveVendorAptRequest) => Promise<void>
}

const useStyles = makeStyles((theme) => ({
    jobBoardContainer: {
        height: '100%',
        overflow: 'auto',
        position: 'relative',
    },
    tableContainer: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 'max-content',
    },
    headerRow: {
        display: 'flex',
        position: 'sticky',
        top: 0,
        zIndex: 3,
        backgroundColor: theme.palette.background.paper,
    },
    dateColumn: {
        position: 'sticky',
        left: 0,
        zIndex: 2,
        backgroundColor: theme.palette.background.paper,
        boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
    },
    dateHeaderCell: {
        padding: theme.spacing(1, 1, 0.5, 1),
        minWidth: 120,
        maxWidth: 120,
        fontWeight: theme.typography.fontWeightBold,
    },
    dateCell: {
        padding: theme.spacing(0.5, 1),
        minWidth: 120,
        maxWidth: 120,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    dateText: {
        ...theme.typography.subtitle1,
        fontWeight: theme.typography.fontWeightBold,
    },
    workorderCount: {
        ...theme.typography.caption,
        color: theme.palette.text.secondary,
    },
    todayCell: {
        backgroundColor: theme.palette.primary.light,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    vendorHeaderCell: {
        padding: theme.spacing(0, 1),
        maxWidth: 300,
        minWidth: 300,
        marginRight: theme.spacing(2),
    },
    workorderCell: {
        padding: theme.spacing(0, 1),
        minWidth: 300,
        maxWidth: 300,
        marginRight: theme.spacing(2),
    },
    row: {
        display: 'flex',
        minHeight: 100,
    },
    tabStyle: {
        ...theme.typography.subtitle1,
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'none',
    },
    listContainer: {
        flex: 1,
        width: '100%',
        height: 'calc(100% - 80px)',
    },
}))

export const OrganizationJobBoard = (props: Props) => {
    const {
        filteredWorkorderList,
        workorderList,
        vendorList,
        workorderMap,
        sortedVendors,
        serviceList,
        dateRange,
        scheduleList,
        isMultiselectMode,
        selectedWorkorders,
        apartmentMap,
        organization,
        apartmentList,
        apartmentVendorMap,
        isLoading,
        setSelectedWorkorders,
        openDetailDrawer,
        addVendorToApartment,
    } = props

    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()

    const [
        draggedWorkorder,
        setDraggedWorkorder,
    ] = useState<WorkorderResponse | null>(null)

    const [todayExpandedAccordions, setTodayExpandedAccordions] = useState<
        Set<string>
    >(new Set())
    const [areAccordionsExpanded, setAreAccordionsExpanded] = useState(false)

    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const scrollIntervalRef = useRef<number | null>(null)

    const { workspaceUser } = useUser()

    const { updateVendorServices } = useCompany()

    const {
        updateWorkorder,
        updateWorkorderState,
        transitionWorkorder,
        deleteWorkorder,
        updateWoWithNextAvailableDate,
        getServiceRequestChannel,
    } = workorderContext()

    useEffect(() => {
        // Clean up the interval on component unmount
        return () => {
            if (scrollIntervalRef.current) {
                clearInterval(scrollIntervalRef.current)
            }
        }
    }, [])

    const handleDragStart = (e: React.DragEvent, workorderId: string) => {
        const workorder = filteredWorkorderList.find(
            (wo) => wo.id.toString() === workorderId,
        )
        if (workorder && !workorder.price_locked) {
            e.dataTransfer.setData('text/plain', workorderId)
            setDraggedWorkorder(workorder)
            startAutoScroll(e)
        } else {
            e.preventDefault()
        }
    }

    const startAutoScroll = (
        e: React.DragEvent,
        columnElement?: HTMLElement | null,
    ) => {
        // Clear any existing interval
        if (scrollIntervalRef.current) {
            clearInterval(scrollIntervalRef.current)
        }

        scrollIntervalRef.current = window.setInterval(() => {
            if (scrollContainerRef.current) {
                const container = scrollContainerRef.current
                const containerRect = container.getBoundingClientRect()
                const horizontalScrollSpeed = 5
                const verticalScrollSpeed = 5

                // Horizontal scrolling
                if (e.clientX > containerRect.right - 50) {
                    container.scrollLeft += horizontalScrollSpeed
                } else if (e.clientX < containerRect.left + 50) {
                    container.scrollLeft -= horizontalScrollSpeed
                }

                // Vertical scrolling
                if (columnElement) {
                    const columnRect = columnElement.getBoundingClientRect()
                    if (e.clientY > columnRect.bottom - 50) {
                        columnElement.scrollTop += verticalScrollSpeed
                    } else if (e.clientY < columnRect.top + 50) {
                        columnElement.scrollTop -= verticalScrollSpeed
                    }
                }
            }
        }, 16) // ~60fps
    }

    const stopAutoScroll = () => {
        if (scrollIntervalRef.current) {
            clearInterval(scrollIntervalRef.current)
            scrollIntervalRef.current = null
        }
    }

    const handleDrop = (
        workorderId: string,
        newId: number,
        newDateKey: string,
    ) => {
        const workorder = workorderList?.find(
            (wo) => wo.id.toString() === workorderId,
        )

        let serviceValid = false
        let newVendor: ListVendor | null = null

        newVendor = vendorList.find((v) => v.id === newId) ?? null

        if (newId === -1) {
            serviceValid = true
        } else {
            serviceValid =
                newVendor?.services.some(
                    (service) => service.id === workorder?.service_id,
                ) ?? false
        }

        if (workorder && !workorder.price_locked && serviceValid) {
            // Parse the newDateKey
            const [year, month, day] = newDateKey.split('-').map(Number)

            // Create new Date objects from the original start_date and end_date
            const originalStartDate = new Date(workorder.start_date)
            const originalEndDate = new Date(workorder.end_date)

            // Check if original start_date and end_date have the same date
            const sameOriginalDate =
                originalStartDate.getFullYear() ===
                    originalEndDate.getFullYear() &&
                originalStartDate.getMonth() === originalEndDate.getMonth() &&
                originalStartDate.getDate() === originalEndDate.getDate()

            // Create a new Date object for start_date with the new date and original time
            const newStartDate = new Date(
                year,
                month - 1, // JavaScript months are 0-indexed
                day,
                originalStartDate.getHours(),
                originalStartDate.getMinutes(),
                originalStartDate.getSeconds(),
                originalStartDate.getMilliseconds(),
            )

            // Calculate the difference in days between the new and original start dates
            const daysDifference = Math.round(
                (newStartDate.getTime() - originalStartDate.getTime()) /
                    (1000 * 60 * 60 * 24),
            )

            let newEndDate
            if (sameOriginalDate) {
                // If original dates were the same, set new end_date to new date with original end time
                newEndDate = new Date(
                    year,
                    month - 1,
                    day,
                    originalEndDate.getHours(),
                    originalEndDate.getMinutes(),
                    originalEndDate.getSeconds(),
                    originalEndDate.getMilliseconds(),
                )
            } else {
                // If original dates were different, adjust the end date by the same number of days
                newEndDate = new Date(originalEndDate)
                newEndDate.setDate(newEndDate.getDate() + daysDifference)
            }

            const updatedWorkorder = {
                ...workorder,
                start_date: newStartDate.toISOString(),
                end_date: newEndDate.toISOString(),
            }

            const request: UpdateWorkOrderRequest = {
                workorderId: workorder.id,
                body: {
                    start_date: newStartDate.toISOString(),
                    end_date: newEndDate.toISOString(),
                },
                params: {
                    apartment_id: workorder.apartment_id,
                },
            }

            if (newVendor) {
                updatedWorkorder.vendor_id = newVendor.id
                updatedWorkorder.user_id = null
                request.body.assign_to_id = newVendor.id
                request.body.assign_to_type = 'vendor'
            }

            // Update the workorder in your our local state
            updateWorkorderState(updatedWorkorder)

            // Update workorder on the backend
            updateWorkorder(request).catch((e) => {
                updateWorkorderState(workorder)
                toast.error(e.response.data.message)
            })
        }

        setDraggedWorkorder(null)
        stopAutoScroll()
    }

    const handleDragOver = (
        e: React.DragEvent,
        columnElement: HTMLElement | null,
    ) => {
        e.preventDefault()
        startAutoScroll(e, columnElement)
    }

    const handleDragEnd = () => {
        setDraggedWorkorder(null)
        stopAutoScroll()
    }

    // FIXME: This is a temporary function to navigate to the schedule detail page.
    const navigateToScheduleDetail = (scheduleId: number) => {
        const project = scheduleList.find(
            (project) => project.id === scheduleId,
        )

        let route = Routes.apartmentScheduleDetail
        if (project && !project.active) {
            route = Routes.apartmentScheduleArchivedDetail
        }

        history.push(resolveRoute(route, ':id', scheduleId))
    }

    const handleSelectWorkorder = (workorder: WorkorderResponse) => {
        setSelectedWorkorders((prev) => {
            const newMap = { ...prev }
            if (workorder.id in newMap) {
                delete newMap[workorder.id]
            } else {
                newMap[workorder.id] = workorder
            }
            return newMap
        })
    }

    const toggleExpandAllForToday = (dateKey: string) => {
        const workordersForToday = workorderMap.get(dateKey) || []

        if (areAccordionsExpanded) {
            setTodayExpandedAccordions(new Set())
        } else {
            setTodayExpandedAccordions(
                new Set(
                    workordersForToday.map(
                        (wo) => `${wo.vendor_id}-${wo.apartment_id}`,
                    ),
                ),
            )
        }

        setAreAccordionsExpanded(!areAccordionsExpanded)
    }

    const handleDropOnVendor = (workorderId: string, newVendorId: number) => {
        const workorder = workorderList?.find(
            (wo) => wo.id.toString() === workorderId,
        )

        let serviceValid = false
        let newVendor: ListVendor | null = null

        newVendor = vendorList.find((v) => v.id === newVendorId) ?? null

        if (newVendorId === -1) {
            serviceValid = true
        } else {
            serviceValid =
                newVendor?.services.some(
                    (service) => service.id === workorder?.service_id,
                ) ?? false
        }

        if (workorder && !workorder.price_locked && serviceValid) {
            updateWoWithNextAvailableDate(
                workorder,
                newVendorId,
                workorder.apartment_id,
            ).catch(() => updateWorkorderState(workorder))
        }
    }

    // Pre-compute workorders by vendor and date
    const workordersByVendorAndDate = useMemo(() => {
        const map = new Map<string, Map<number, WorkorderResponse[]>>()

        dateRange.forEach((date) => {
            const dateKey = format(date, 'yyyy-MM-dd')
            const vendorMap = new Map<number, WorkorderResponse[]>()

            const dateWorkorders = workorderMap.get(dateKey) || []
            sortedVendors.forEach((vendor) => {
                vendorMap.set(
                    vendor.id,
                    dateWorkorders.filter((wo) => wo.vendor_id === vendor.id),
                )
            })

            map.set(dateKey, vendorMap)
        })

        return map
    }, [workorderMap, dateRange, sortedVendors])

    // Memoized function to get workorders
    const getWorkordersForVendorAndDate = useCallback(
        (dateKey: string, vendorId: number) => {
            return workordersByVendorAndDate.get(dateKey)?.get(vendorId) || []
        },
        [workordersByVendorAndDate],
    )

    // Render a single date row
    const renderDateRow = useCallback(
        (date: Date) => {
            const dateKey = format(date, 'yyyy-MM-dd')
            const isDateToday = isToday(date)
            const workorderCount = workorderMap.get(dateKey)?.length ?? 0

            return (
                <div key={dateKey} className={classes.row}>
                    <div
                        className={`${classes.dateColumn} ${classes.dateCell} ${
                            isDateToday ? classes.todayCell : ''
                        }`}
                    >
                        <span className={classes.dateText}>
                            {format(date, 'EEE, MMM d')}
                        </span>
                        <span className={classes.workorderCount}>
                            {workorderCount} workorder
                            {workorderCount !== 1 ? 's' : ''}
                        </span>
                        {isDateToday && (
                            <Tooltip
                                title={
                                    areAccordionsExpanded
                                        ? 'Collapse All'
                                        : 'Expand All'
                                }
                            >
                                <IconButton
                                    onClick={() =>
                                        toggleExpandAllForToday(dateKey)
                                    }
                                    aria-label={
                                        areAccordionsExpanded
                                            ? 'Collapse All'
                                            : 'Expand All'
                                    }
                                >
                                    {areAccordionsExpanded ? (
                                        <ExpandLessIcon />
                                    ) : (
                                        <ExpandMoreIcon />
                                    )}
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                    {sortedVendors.map((vendor) => (
                        <div
                            key={`${dateKey}-${vendor.id}`}
                            className={classes.workorderCell}
                        >
                            <VendorCell
                                vendor={vendor}
                                dateKey={dateKey}
                                workorders={getWorkordersForVendorAndDate(
                                    dateKey,
                                    vendor.id,
                                )}
                                theme={theme}
                                workspaceUser={workspaceUser}
                                transitionWorkorder={transitionWorkorder}
                                openDetailDrawer={openDetailDrawer}
                                onDragStart={handleDragStart}
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                                draggedWorkorder={draggedWorkorder}
                                isLastColumn={
                                    vendor.id ===
                                    sortedVendors[sortedVendors.length - 1].id
                                }
                                navigateToScheduleDetail={
                                    navigateToScheduleDetail
                                }
                                deleteWorkorder={deleteWorkorder}
                                isMultiselectMode={isMultiselectMode}
                                selectedWorkorders={selectedWorkorders}
                                handleSelectWorkorder={handleSelectWorkorder}
                                apartmentMap={apartmentMap}
                                apartmentVendorMap={apartmentVendorMap}
                                organizationView
                                expandedAccordions={
                                    isDateToday
                                        ? todayExpandedAccordions
                                        : undefined
                                }
                                setExpandedAccordions={
                                    isDateToday
                                        ? setTodayExpandedAccordions
                                        : undefined
                                }
                            />
                        </div>
                    ))}
                </div>
            )
        },
        [
            sortedVendors,
            workorderMap,
            draggedWorkorder,
            selectedWorkorders,
            todayExpandedAccordions,
            getWorkordersForVendorAndDate,
        ],
    )

    // Split the date range into chunks for rendering
    const dateChunks = useMemo(() => {
        const chunks: Date[][] = []
        const chunkSize = 7 // Render a week at a time

        for (let i = 0; i < dateRange.length; i += chunkSize) {
            chunks.push(dateRange.slice(i, i + chunkSize))
        }

        return chunks
    }, [dateRange])

    // Memoize the vendor header cells
    const vendorHeaders = useMemo(() => {
        return sortedVendors.map((vendor) => (
            <div
                key={`VENDOR_${vendor.id}`}
                className={classes.vendorHeaderCell}
            >
                <VendorHeaderCell
                    vendor={vendor}
                    theme={theme}
                    draggedWorkorder={draggedWorkorder}
                    serviceList={serviceList}
                    apartmentVendorMap={apartmentVendorMap}
                    apartmentList={apartmentList}
                    updateVendorServices={updateVendorServices}
                    addVendorToApartment={addVendorToApartment}
                    onDrop={handleDropOnVendor}
                    organizationView
                />
            </div>
        ))
    }, [
        sortedVendors,
        draggedWorkorder,
        serviceList,
        apartmentVendorMap,
        apartmentList,
    ])

    return (
        <div className={classes.jobBoardContainer} ref={scrollContainerRef}>
            {sortedVendors.length === 0 && !isLoading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.h6,
                            color: theme.palette.text.secondary,
                        }}
                    >
                        Please select vendors to display the job board and then
                        press the apply button to refresh the job board.
                    </span>
                </div>
            ) : (
                <div className={classes.tableContainer}>
                    <div className={classes.headerRow}>
                        <div
                            className={`${classes.dateColumn} ${classes.dateHeaderCell}`}
                        >
                            Date
                        </div>
                        {vendorHeaders}
                    </div>
                    {dateChunks.map((chunk, index) => (
                        <React.Fragment key={`chunk-${index}`}>
                            {chunk.map(renderDateRow)}
                        </React.Fragment>
                    ))}
                </div>
            )}
        </div>
    )
}
