import React, { useEffect } from 'react'
import { Typography } from '@material-ui/core'

import { ChatMessageContent, ShowProjectPlanResponse } from '../../../types'
import { Container } from '../../../../../../components/Container'
import { LoriController } from '../../../../../../hooks/useLori'

interface Props {
    message: ChatMessageContent
    loriController: LoriController
}

export const ProjectPlanCard: React.FC<Props> = ({
    message,
    loriController,
}) => {
    if (!message.function_call_response) return null
    const showProjectPlanResponse: ShowProjectPlanResponse = message.function_call_response as ShowProjectPlanResponse

    // navigate to the url if this is the initial message
    useEffect(() => {
        loriController.getProjectPlanList()
    }, [])

    return (
        <Container
            style={{
                flexDirection: 'column',
            }}
        >
            <Container>
                <Typography variant="body1" color="textSecondary">
                    {showProjectPlanResponse.message}
                </Typography>
            </Container>
        </Container>
    )
}
