import React from 'react'
import { Tooltip, IconButton, Theme } from '@material-ui/core'

import { Container } from './Container'
import { WorkSpaceUser } from '../models'
import { useHistory, useLocation } from 'react-router-dom'
import { isAccountManager, VENDOR } from '../helpers'
import { Routes } from '../navigation/config/routes'
import { navigationConfig } from '../navigation/config/navigationItems'

import VpnKeyIcon from '@material-ui/icons/VpnKey'

import { ReactComponent as EZTurnIcon } from '../assets/icons/ez-turn.svg'
import { ReactComponent as EZFolioLogo } from '../assets/icons/ez-folio.svg'
import { ReactComponent as EZManageLogo } from '../assets/icons/ez-manage.svg'

interface AppSelectorProps {
    workspaceUser?: WorkSpaceUser
    theme: Theme
}

export const AppSelector = ({ workspaceUser, theme }: AppSelectorProps) => {
    const history = useHistory()
    const location = useLocation()

    const isEZTurnSelected = [
        ...navigationConfig.apartment.mainItems,
        ...navigationConfig.apartment.footerItems,
        ...navigationConfig.vendor.mainItems,
        ...navigationConfig.vendor.footerItems,
        ...navigationConfig.shared.mainItems,
        ...navigationConfig.shared.footerItems,
    ].some((item) => location.pathname.startsWith(item.link))

    const isEZFolioSelected = [
        ...navigationConfig.organization.mainItems,
        ...navigationConfig.organization.footerItems,
    ].some((item) => location.pathname.startsWith(item.link))

    const isAdminSelected = [
        ...navigationConfig.admin.mainItems,
        ...navigationConfig.admin.footerItems,
    ].some((item) => location.pathname.startsWith(item.link))

    const isEZManageSelected = [
        ...navigationConfig.manage.mainItems,
        ...navigationConfig.manage.footerItems,
    ].some((item) => location.pathname.startsWith(item.link))

    const showAdminButton = isAccountManager(workspaceUser)

    return (
        <Container>
            <Tooltip title="EZTurn" style={{ marginLeft: theme.spacing(1) }}>
                <IconButton
                    size="small"
                    onClick={() => {
                        if (
                            workspaceUser?.active_workspace.company_type ===
                            VENDOR
                        ) {
                            history.push(Routes.vendor.ezwork)
                        } else {
                            history.push(Routes.apartment.schedule.root)
                        }
                    }}
                    style={{
                        borderRadius: 0,
                        width: 48,
                        height: 48,
                        borderBottom: isEZTurnSelected
                            ? `4px solid white`
                            : 'none',
                    }}
                >
                    <EZTurnIcon style={{ width: 42, height: 42 }} />
                </IconButton>
            </Tooltip>

            <Tooltip title="EZFolio" style={{ marginLeft: theme.spacing(1) }}>
                <IconButton
                    size="small"
                    style={{
                        borderRadius: 0,
                        width: 48,
                        height: 48,
                        borderBottom: isEZFolioSelected
                            ? `4px solid white`
                            : 'none',
                    }}
                    onClick={() => {
                        history.push(Routes.organization.jobBoard)
                    }}
                >
                    <EZFolioLogo style={{ width: 42, height: 42 }} />
                </IconButton>
            </Tooltip>
            <Tooltip title="EZManage" style={{ marginLeft: theme.spacing(1) }}>
                <IconButton
                    size="small"
                    style={{
                        borderRadius: 0,
                        width: 48,
                        height: 48,
                        borderBottom: isEZManageSelected
                            ? `4px solid white`
                            : 'none',
                    }}
                    onClick={() => {
                        history.push(Routes.manage.lease.audit)
                    }}
                >
                    <EZManageLogo style={{ width: 42, height: 42 }} />
                </IconButton>
            </Tooltip>

            {showAdminButton && (
                <Tooltip title="Admin" style={{ marginLeft: theme.spacing(1) }}>
                    <IconButton
                        size="small"
                        style={{
                            borderRadius: 0,
                            width: 48,
                            height: 48,
                            borderBottom: isAdminSelected
                                ? `4px solid white`
                                : 'none',
                        }}
                        onClick={() => {
                            history.push(Routes.admin.organization.list)
                        }}
                    >
                        <VpnKeyIcon
                            style={{ width: 42, height: 42 }}
                            htmlColor="white"
                        />
                    </IconButton>
                </Tooltip>
            )}
        </Container>
    )
}
