import React, { useState } from 'react'
import { Theme, Button, Typography } from '@material-ui/core'

import { CustomerSupportModal } from '../../../../../../components/CustomerSupport/CustomerSupportModal'
import { Conversation } from '../../../types'
import { Container } from '../../../../../../components/Container'
import PhoneIcon from '@material-ui/icons/Phone'

interface Props {
    theme: Theme
    conversation: Conversation | null
}

export const ContactSupportButton: React.FC<Props> = ({
    theme,
    conversation,
}) => {
    const [openCustomerSupportModal, setOpenCustomerSupportModal] = useState(
        false,
    )
    return (
        <Container
            style={{
                flexDirection: 'column',
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(2),
            }}
        >
            <Container>
                <Typography variant="body1" color="textSecondary">
                    I apologize for not being able to help adequately. If you
                    would like to contact support, please give us a call or
                    submit a ticket.
                </Typography>
            </Container>
            <Container
                style={{
                    flexDirection: 'row',
                }}
            >
                <Container
                    style={{
                        marginTop: theme.spacing(1),
                        alignItems: 'center',
                    }}
                >
                    <PhoneIcon />
                    <Typography
                        style={{
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        866-752-6257
                    </Typography>
                </Container>
                <Container
                    style={{
                        marginLeft: theme.spacing(1),
                        borderLeft: `2px solid ${theme.palette.divider}`,
                        borderRadius: theme.shape.borderRadius,
                    }}
                ></Container>
                <Container
                    style={{
                        marginLeft: theme.spacing(1),
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setOpenCustomerSupportModal(true)
                        }}
                    >
                        Submit Ticket
                    </Button>
                </Container>
            </Container>

            <CustomerSupportModal
                open={openCustomerSupportModal}
                onClose={() => {
                    setOpenCustomerSupportModal(false)
                }}
                conversation={conversation}
            />
        </Container>
    )
}
