import React, { useState } from 'react'
import {
    Card,
    CardContent,
    Typography,
    Theme,
    makeStyles,
    IconButton,
    Tooltip,
} from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import { useHistory } from 'react-router-dom'
import { ChatMessageContent, DeadlineResponse } from '../../../types'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        borderLeft: '4px solid',
        borderBottom: '4px solid',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1),
    },
    header: {
        display: 'flex',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    deadlineName: {
        fontWeight: 700,
        fontSize: '1.1rem',
    },
    description: {
        color: theme.palette.text.secondary,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '200px',
        marginLeft: theme.spacing(1),
    },
    icons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
}))

interface Props {
    theme: Theme
    message: ChatMessageContent
}

export const DeadlineCard: React.FC<Props> = ({ theme, message }) => {
    if (!message.function_call_response) return null
    const deadlineResponse: DeadlineResponse = message.function_call_response as DeadlineResponse

    const classes = useStyles(theme)
    const history = useHistory()

    const navigateToCalendarDate = async (date: string) => {
        try {
            const dateStr = new Date(date).toISOString().split('T')[0]
            history.push(`/schedule?date=${dateStr}`)
        } catch (error) {
            console.log('Error refreshing deadlines:', error)
        }
    }

    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                <div className={classes.header}>
                    <Typography className={classes.deadlineName}>
                        {deadlineResponse.deadlineName}
                    </Typography>
                    <Tooltip title={deadlineResponse.deadlineDescription}>
                        <Typography className={classes.description}>
                            {deadlineResponse.deadlineDescription}
                        </Typography>
                    </Tooltip>
                </div>
                <div className={classes.icons}>
                    <Tooltip title={`Go to ${deadlineResponse.deadlineName}`}>
                        <IconButton
                            style={{
                                position: 'absolute',
                                top: theme.spacing(1),
                                right: theme.spacing(1),
                            }}
                            onClick={() => {
                                if (
                                    deadlineResponse &&
                                    deadlineResponse.deadlineDate
                                ) {
                                    navigateToCalendarDate(
                                        deadlineResponse.deadlineDate,
                                    )
                                }
                            }}
                            size="small"
                        >
                            <ExitToAppIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <div>
                    <Typography>
                        {deadlineResponse.deadlineDate.split('T')[0]}
                    </Typography>
                </div>
            </CardContent>
        </Card>
    )
}
