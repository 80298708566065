import React, { useMemo, useRef, useCallback } from 'react'

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    makeStyles,
    Paper,
    Theme,
    Tooltip,
    Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {
    BaseWorkorder,
    Company,
    ListVendor,
    ModelMap,
    User,
    WorkorderStatus,
} from '../../../models'
import {
    DeleteWorkorderParams,
    TransitionWorkorderParams,
    WorkorderResponse,
} from '../../../store'
import { JobCard } from './JobCard'
import { Container } from '../../../components'
import { JbDrawerId } from '../types'
import { ReportProblem } from '@material-ui/icons'

interface Props {
    dateKey: string
    vendor?: ListVendor
    user?: User
    workorders: WorkorderResponse[]
    theme: Theme
    draggedWorkorder: WorkorderResponse | null
    workspaceUser?: User
    isLastColumn: boolean
    isMultiselectMode: boolean
    selectedWorkorders: ModelMap<WorkorderResponse>
    organizationView?: boolean
    apartmentMap?: ModelMap<Company>
    apartmentVendorMap?: Record<number, number[]>
    expandedAccordions?: Set<string>
    handleSelectWorkorder: (workorder: WorkorderResponse) => void
    transitionWorkorder: (
        workorder: BaseWorkorder,
        status: WorkorderStatus,
        params?: TransitionWorkorderParams,
    ) => Promise<void>
    openDetailDrawer: (
        workorder: WorkorderResponse,
        drawerId: JbDrawerId,
    ) => void
    onDragStart: (e: React.DragEvent, workorderId: string) => void
    onDrop: (
        workorderId: string,
        newVendorId: number,
        newDateKey: string,
        isUser?: boolean,
    ) => void
    onDragOver: (e: React.DragEvent, columnElement: HTMLElement | null) => void
    navigateToScheduleDetail: (scheduleId: number) => void
    deleteWorkorder: (
        workorder: WorkorderResponse,
        params?: DeleteWorkorderParams,
    ) => Promise<void>
    onClickServiceRequest?: (workorder: WorkorderResponse) => void
    setExpandedAccordions?: React.Dispatch<React.SetStateAction<Set<string>>>
}

const useStyles = makeStyles((theme) => ({
    cell: {
        border: `1px solid ${theme.palette.divider}`,
        height: '100%',
        minWidth: 310,
        maxWidth: 310,
        borderRadius: 0,
        justifyContent: 'center',
        minHeight: 50,
    },
    dropZone: {
        height: '100%',
        border: '2px dashed transparent',
        transition: 'border-color 0.2s ease',
        '&.drag-over': {
            borderColor: theme.palette.primary.main,
        },
    },
    dragItem: {
        margin: theme.spacing(1, 0),
        cursor: 'move',
    },
    dragDisabled: {
        cursor: 'not-allowed',
    },
    invalidDropTarget: {
        opacity: 0.5,
        pointerEvents: 'none',
    },
    accordionDetails: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
    },
    accordionSummary: {
        backgroundColor: theme.palette.background.default,
        borderBottom: `1px solid ${theme.palette.divider}`,
        '& .MuiTypography-root': {
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: theme.typography.pxToRem(16),
        },
    },
    validDropTarget: {
        borderRight: `2px solid ${theme.palette.primary.dark}`,
        borderLeft: `2px solid ${theme.palette.primary.dark}`,
    },
}))

export const VendorCell = React.memo(
    (props: Props) => {
        const {
            dateKey,
            vendor,
            workorders,
            theme,
            user,
            workspaceUser,
            draggedWorkorder,
            isLastColumn,
            isMultiselectMode,
            selectedWorkorders,
            organizationView,
            apartmentMap,
            apartmentVendorMap,
            expandedAccordions,
            handleSelectWorkorder,
            transitionWorkorder,
            openDetailDrawer,
            onDragStart,
            onDrop,
            onDragOver,
            navigateToScheduleDetail,
            deleteWorkorder,
            setExpandedAccordions,
            onClickServiceRequest,
        } = props

        const classes = useStyles()

        const columnRef = useRef<HTMLDivElement>(null)

        const isValidDropTarget = useMemo(() => {
            let isValid = true
            if (vendor) {
                isValid = draggedWorkorder
                    ? vendor.services.some(
                          (service) =>
                              service.id === draggedWorkorder.service_id,
                      )
                    : true
                if (
                    draggedWorkorder &&
                    organizationView &&
                    apartmentVendorMap
                ) {
                    isValid =
                        isValid &&
                        apartmentVendorMap[
                            draggedWorkorder?.apartment_id ?? 0
                        ]?.includes(vendor.id)
                }
            }
            return isValid
        }, [organizationView, apartmentVendorMap, draggedWorkorder, vendor])

        const handleDragOver = (e: React.DragEvent) => {
            e.preventDefault()
            if (isValidDropTarget) {
                e.currentTarget.classList.add('drag-over')
                onDragOver(e, columnRef.current)
            }
        }

        const handleDragLeave = (e: React.DragEvent) => {
            e.currentTarget.classList.remove('drag-over')
        }

        const handleDrop = (e: React.DragEvent) => {
            e.preventDefault()
            e.currentTarget.classList.remove('drag-over')
            if (isValidDropTarget) {
                const workorderId = e.dataTransfer.getData('text/plain')
                if (vendor) {
                    onDrop(workorderId, vendor.id, dateKey)
                } else if (user) {
                    const isUser = true
                    onDrop(workorderId, user.id, dateKey, isUser)
                }
            }
        }

        // Group workorders by apartment_id and count workorders with specific priority
        let workordersByProperty: Record<
            number,
            { workorders: WorkorderResponse[]; priorityCount: number }
        > = {}
        if (organizationView) {
            workordersByProperty = workorders.reduce((acc, workorder) => {
                const propertyId = workorder.apartment_id
                if (!acc[propertyId]) {
                    acc[propertyId] = { workorders: [], priorityCount: 0 }
                }
                acc[propertyId].workorders.push(workorder)
                if (workorder.priority) {
                    acc[propertyId].priorityCount += 1
                }
                return acc
            }, {} as Record<number, { workorders: WorkorderResponse[]; priorityCount: number }>)
        }

        const renderWorkorder = useCallback(
            (workorder: WorkorderResponse) => (
                <div
                    key={`WORKORDER_CARD_${workorder.id}`}
                    className={`${classes.dragItem} ${
                        workorder.price_locked ? classes.dragDisabled : ''
                    }`}
                    draggable={!workorder.price_locked}
                    onDragStart={(e) =>
                        !workorder.price_locked &&
                        onDragStart(e, workorder.id.toString())
                    }
                >
                    <JobCard
                        workorder={workorder}
                        theme={theme}
                        user={workspaceUser}
                        transitionWorkorder={transitionWorkorder}
                        openMessageDrawer={() =>
                            openDetailDrawer(workorder, JbDrawerId.message)
                        }
                        openUnitNotes={() =>
                            openDetailDrawer(workorder, JbDrawerId.unitNotes)
                        }
                        openEditModal={() =>
                            openDetailDrawer(workorder, JbDrawerId.edit)
                        }
                        navigateToScheduleDetail={navigateToScheduleDetail}
                        deleteWorkorder={deleteWorkorder}
                        isSelected={
                            selectedWorkorders[workorder.id] !== undefined
                        }
                        onSelect={() => handleSelectWorkorder(workorder)}
                        isMultiselectMode={isMultiselectMode}
                        organizationView={organizationView}
                        onClickServiceRequest={onClickServiceRequest}
                    />
                </div>
            ),
            [
                theme,
                workspaceUser,
                transitionWorkorder,
                openDetailDrawer,
                navigateToScheduleDetail,
                deleteWorkorder,
                selectedWorkorders,
                handleSelectWorkorder,
                isMultiselectMode,
                organizationView,
            ],
        )

        return (
            <Paper
                className={`${classes.cell} ${
                    draggedWorkorder && isValidDropTarget
                        ? classes.validDropTarget
                        : ''
                }`}
                style={{ marginRight: isLastColumn ? 0 : theme.spacing(2) }}
                elevation={3}
            >
                <Container
                    className={`${classes.dropZone} ${
                        !isValidDropTarget ? classes.invalidDropTarget : ''
                    }`}
                    onDragOver={(e) => {
                        e.preventDefault()
                        e.currentTarget.classList.add('drag-over')
                        handleDragOver(e)
                    }}
                    onDragLeave={(e) => {
                        e.currentTarget.classList.remove('drag-over')
                        handleDragLeave(e)
                    }}
                    onDrop={(e) => handleDrop(e)}
                    style={{ flexDirection: 'column' }}
                >
                    {organizationView
                        ? Object.entries(workordersByProperty).map(
                              ([propertyId, { workorders, priorityCount }]) => {
                                  const accordionKey = `${vendor?.id}-${propertyId}`
                                  const isExpanded = expandedAccordions?.has(
                                      accordionKey,
                                  )
                                  return (
                                      <PropertyAccordion
                                          key={propertyId}
                                          propertyId={propertyId}
                                          workorders={workorders}
                                          priorityCount={priorityCount}
                                          apartmentMap={apartmentMap}
                                          theme={theme}
                                          classes={classes}
                                          onDragStart={onDragStart}
                                          transitionWorkorder={
                                              transitionWorkorder
                                          }
                                          openDetailDrawer={openDetailDrawer}
                                          navigateToScheduleDetail={
                                              navigateToScheduleDetail
                                          }
                                          deleteWorkorder={deleteWorkorder}
                                          selectedWorkorders={
                                              selectedWorkorders
                                          }
                                          handleSelectWorkorder={
                                              handleSelectWorkorder
                                          }
                                          isMultiselectMode={isMultiselectMode}
                                          organizationView={organizationView}
                                          workspaceUser={workspaceUser}
                                          {...(isExpanded !== undefined && {
                                              expanded: isExpanded,
                                          })}
                                          {...(setExpandedAccordions !==
                                              undefined && {
                                              onToggleExpand: () => {
                                                  setExpandedAccordions(
                                                      (prev) => {
                                                          const newSet = new Set(
                                                              prev,
                                                          )
                                                          if (
                                                              newSet.has(
                                                                  accordionKey,
                                                              )
                                                          ) {
                                                              newSet.delete(
                                                                  accordionKey,
                                                              )
                                                          } else {
                                                              newSet.add(
                                                                  accordionKey,
                                                              )
                                                          }
                                                          return newSet
                                                      },
                                                  )
                                              },
                                          })}
                                          renderWorkorder={renderWorkorder}
                                      />
                                  )
                              },
                          )
                        : workorders.map(renderWorkorder)}
                </Container>
            </Paper>
        )
    },
    (prevProps, nextProps) => {
        // Check if the workorders array has changed
        const workordersChanged =
            prevProps.workorders.length !== nextProps.workorders.length ||
            prevProps.workorders.some((prevWorkorder, index) => {
                const nextWorkorder = nextProps.workorders[index]
                return (
                    JSON.stringify(prevWorkorder) !==
                    JSON.stringify(nextWorkorder)
                )
            })

        return (
            prevProps.dateKey === nextProps.dateKey &&
            !workordersChanged &&
            prevProps.isMultiselectMode === nextProps.isMultiselectMode &&
            prevProps.draggedWorkorder?.id === nextProps.draggedWorkorder?.id &&
            prevProps.selectedWorkorders === nextProps.selectedWorkorders &&
            prevProps.expandedAccordions === nextProps.expandedAccordions
        )
    },
)

interface PropertyAccordionProps {
    propertyId: string
    workorders: WorkorderResponse[]
    priorityCount: number
    apartmentMap?: ModelMap<Company>
    theme: Theme
    classes: ReturnType<typeof useStyles>
    onDragStart: (e: React.DragEvent, workorderId: string) => void
    transitionWorkorder: (
        workorder: BaseWorkorder,
        status: WorkorderStatus,
    ) => Promise<void>
    openDetailDrawer: (
        workorder: WorkorderResponse,
        drawerId: JbDrawerId,
    ) => void
    navigateToScheduleDetail: (scheduleId: number) => void
    deleteWorkorder: (
        workorder: WorkorderResponse,
        params?: DeleteWorkorderParams,
    ) => Promise<void>
    selectedWorkorders: ModelMap<WorkorderResponse>
    handleSelectWorkorder: (workorder: WorkorderResponse) => void
    isMultiselectMode: boolean
    organizationView?: boolean
    workspaceUser?: User
    expanded?: boolean
    onToggleExpand?: () => void
    renderWorkorder: (workorder: WorkorderResponse) => JSX.Element
}

const PropertyAccordion: React.FC<PropertyAccordionProps> = ({
    propertyId,
    workorders,
    priorityCount,
    apartmentMap,
    theme,
    classes,
    onDragStart,
    transitionWorkorder,
    openDetailDrawer,
    navigateToScheduleDetail,
    deleteWorkorder,
    selectedWorkorders,
    handleSelectWorkorder,
    isMultiselectMode,
    organizationView,
    workspaceUser,
    expanded,
    onToggleExpand,
    renderWorkorder,
}) => {
    const propertyIdNumber = parseInt(propertyId)
    const propertyName =
        apartmentMap?.[propertyIdNumber]?.name || 'Unknown Property'
    const tooltipTitle = (
        <>
            {priorityCount} Priority Workorders
            <br />
            {workorders.length} Total Workorders
        </>
    )

    return (
        <Accordion
            {...(expanded !== undefined && { expanded })}
            {...(onToggleExpand !== undefined && { onChange: onToggleExpand })}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordionSummary}
                style={{ alignItems: 'center' }}
            >
                <Typography
                    style={{
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {propertyName}
                </Typography>
                <Container style={{ flex: 1 }} />

                <Typography>({workorders.length})</Typography>
                <Container style={{ width: 20 }}>
                    {priorityCount > 0 && (
                        <Tooltip title={tooltipTitle}>
                            <ReportProblem
                                color="secondary"
                                fontSize="small"
                                style={{
                                    alignSelf: 'center',
                                    justifySelf: 'center',
                                    marginLeft: theme.spacing(1),
                                }}
                            />
                        </Tooltip>
                    )}
                </Container>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                {workorders.map(renderWorkorder)}
            </AccordionDetails>
        </Accordion>
    )
}
