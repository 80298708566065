import React from 'react'
import { TreeData } from '../../types'
import { Container, Draggable, Droppable } from '../../../../../components'
import { Button } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { DragPreview } from './DragPreview'
import { DraggableItem } from '../../../../../components/DragDrop/types'

const useStyles = makeStyles((theme) => ({
    treeNode: {
        padding: theme.spacing(1),
        borderRadius: 4,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
    nodeContent: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    toggleButton: {
        minWidth: 'unset',
        padding: theme.spacing(0.5),
        '& .MuiButton-label': {
            fontSize: '0.8rem',
        },
    },
    nodeName: {
        fontSize: '0.9rem',
        fontWeight: 500,
        color: theme.palette.text.primary,
    },
    childrenContainer: {
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            left: -15,
            top: 0,
            bottom: 0,
            width: 1,
            backgroundColor: theme.palette.divider,
        },
    },
}))

interface TreeProps {
    treeData: TreeData
    depth: number
    openMap: Map<string, boolean>
    setOpenMap: React.Dispatch<React.SetStateAction<Map<string, boolean>>>
    theme: Theme
    handleDrop: (
        draggingItem: DraggableItem,
        droppingItem: DraggableItem,
    ) => void
    onContextMenu: (
        event: React.MouseEvent,
        folderId: string,
        itemType: 'unit' | 'folder',
    ) => void
}

export const Tree: React.FC<TreeProps> = ({
    treeData,
    depth,
    openMap,
    setOpenMap,
    theme,
    handleDrop,
    onContextMenu,
}: {
    treeData: TreeData
    depth: number
    openMap: Map<string, boolean>
    setOpenMap: React.Dispatch<React.SetStateAction<Map<string, boolean>>>
    theme: Theme
    handleDrop: (
        draggingItem: DraggableItem,
        droppingItem: DraggableItem,
    ) => void
    onContextMenu: (
        event: React.MouseEvent,
        folderId: string,
        itemType: 'unit' | 'folder',
    ) => void
}) => {
    const classes = useStyles()
    const isOpen = openMap.get(treeData.id ?? 'null') ?? treeData.id === null

    const handleToggle = () => {
        setOpenMap((prev) => {
            const newMap = new Map(prev)
            newMap.set(treeData.id ?? '', !isOpen)
            return newMap
        })
    }

    const acceptedTypes = []
    if (!treeData.isLeaf) {
        acceptedTypes.push('folder')
        if (treeData.id !== null) {
            acceptedTypes.push('unit')
        }
    }

    const selfDraggableItem: DraggableItem = {
        id: treeData.id ?? '-1',
        dragType: treeData.isLeaf ? 'unit' : 'folder',
        name: treeData.name ?? '',
        multiple: treeData.isLeaf,
    }

    return (
        <Container style={{ flexDirection: 'column', marginLeft: depth * 16 }}>
            <Droppable
                handleDrop={(e, items) => {
                    items.forEach((item) => {
                        handleDrop(item, selfDraggableItem)
                    })
                }}
                acceptedTypes={acceptedTypes}
                dragOverStyle={{
                    borderBottom: `2px solid ${theme.palette.primary.main}`,
                }}
            >
                <Draggable
                    onDragStart={(e) => {
                        console.log(
                            'onDragStart',
                            e.dataTransfer.getData('application/json'),
                        )
                        if (isOpen) {
                            handleToggle()
                        }
                    }}
                    style={{ flex: 1 }}
                    payload={selfDraggableItem}
                    DragPreview={DragPreview}
                >
                    <Container className={classes.treeNode}>
                        <div
                            className={classes.nodeContent}
                            onContextMenu={(e) => {
                                e.preventDefault()
                                console.log('onContextMenu', treeData.id)
                                onContextMenu(
                                    e,
                                    treeData.id ?? '',
                                    treeData.isLeaf ? 'unit' : 'folder',
                                )
                            }}
                        >
                            {!treeData.isLeaf && treeData.children.length > 0 && (
                                <Button
                                    className={classes.toggleButton}
                                    variant="text"
                                    size="small"
                                    onClick={handleToggle}
                                >
                                    {isOpen ? '▼' : '▶'}
                                </Button>
                            )}
                            <div className={classes.nodeName}>
                                {treeData.isLeaf ? '📄 ' : '📁 '}
                                {treeData.name}
                            </div>
                        </div>
                    </Container>
                </Draggable>
            </Droppable>

            {isOpen && (
                <Container
                    className={classes.childrenContainer}
                    style={{
                        flexDirection: 'column',
                        marginLeft: depth > 0 ? 24 : 16,
                    }}
                >
                    {treeData.children.map((child) => (
                        <Tree
                            key={child.id}
                            treeData={child}
                            depth={depth + 1}
                            openMap={openMap}
                            setOpenMap={setOpenMap}
                            theme={theme}
                            handleDrop={handleDrop}
                            onContextMenu={onContextMenu}
                        />
                    ))}
                </Container>
            )}
        </Container>
    )
}
