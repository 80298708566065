import { AxiosResponse } from 'axios'
import { Action } from 'redux'
import { JobsiteRequest } from '../..'
import {
    BaseWorkorder,
    ChangeOrder,
    Conflict,
    WorkorderStatus,
    WorkorderStatusFilters,
    WorkorderType,
} from '../../../models'

// Requests
export interface _GetWorkOrderListRequest {
    isForPortfolioSchedule?: boolean
    params?: {
        portfolio?: boolean
        schedule?: number | string
        units?: string // comma seperated id list
        unit_name?: string
        service?: number
        vendor?: number
        apartment?: number
        wo_status?: WorkorderStatusFilters
        folder?: number
        priority?: boolean
        upper_bound_date?: string
        lower_bound_date?: string
    }
}

export interface AssignUnitDict {
    unit_id: number
    service_areas: number[]
}

export interface WorkorderAreaDict {
    workorder_id: number
    service_areas: number[]
}

export interface CreateWorkOrderRequest {
    body: {
        title?: string
        priority?: boolean
        workorder_type?: WorkorderType
        start_date?: string
        end_date?: string
        schedule?: number
        service?: number
        vendor?: number
        units?: AssignUnitDict[]
        subscribe_to_bed_status_change?: boolean
        assign_to_type?: 'user' | 'vendor'
        assign_to_id?: number
        inv_ins_ids?: number[]
    }
}

export interface UpdateWorkOrderRequest {
    workorderId: number
    body: {
        subscribe_to_bed_status_change?: boolean
        service_areas?: number[]
        add_price?: number
        custom_price?: number | null
        start_date?: string
        end_date?: string
        priority?: boolean
        vendor?: number
        title?: string
        service?: number
        assign_to_type?: 'user' | 'vendor'
        assign_to_id?: number
        note?: string
        unit?: number
    }
    params?: {
        apartment_id?: number
    }
}

export interface BulkUpdateWorkOrderRequest {
    body: {
        workorders: number[]
        vendor?: number
        start_date?: string
        end_date?: string
        priority?: boolean
        add_price?: number
        custom_price?: number
        subscribe_to_bed_status_change?: boolean
        workorder_areas?: WorkorderAreaDict[]
        service?: number
    }
    params?: {
        apartment_id?: number
        organization?: number
    }
}

export interface BulkDeleteWorkOrderRequest {
    body: {
        workorders: number[]
    }
    params?: DeleteWorkorderParams
}

export interface TransitionWorkorderStatusRequest {
    workorderId: number
    body: {
        status: WorkorderStatus
    }
    params?: TransitionWorkorderParams
}

export interface TransitionWorkorderParams {
    apartment_id?: number
    all_jobsites?: boolean
}

export interface DeleteWorkorderParams {
    apartment_id?: number
    organization?: number
}

// Responses
export interface CreateWorkorderResponse {
    workorders: WorkorderResponse[]
    conflicts: Conflict[]
    created_count: number
}

export interface WorkorderResponse extends BaseWorkorder {
    start_date: string
    end_date: string
    priority: 1 | 0
}

// actions
export const GET_WORKORDER_LIST_REQUEST = 'GET_WORKORDER_LIST_REQUEST'
export const GET_WORKORDER_LIST_RESPONSE = 'GET_WORKORDER_LIST_RESPONSE'
export const CREATE_WORKORDER_REQUEST = 'CREATE_WORKORDER_REQUEST'
export const CREATE_WORKORDER_RESPONSE = 'CREATE_WORKORDER_RESPONSE'
export const UPDATE_WORKORDER_REQUEST = 'UPDATE_WORKORDER_REQUEST'
export const SET_WORKORDER_LIST = 'SET_WORKORDER_LIST'
export const SET_PORTFOLIO_WORKORDER_LIST = 'SET_PORTFOLIO_WORKORDER_LIST'
export const TRANSITION_WORKORDER_REQUEST = 'TRANSITION_WORKORDER_REQUEST'
export const UPDATE_WORKORDER = 'UPDATE_WORKORDER'
export const DELETE_WORKORDER = 'DELETE_WORKORDER'
export const UPDATE_BULK_WORKORDERS = 'UPDATE_BULK_WORKORDERS'
export const CLEAR_WOKRORDER_UNREAD_COUNT = 'CLEAR_WOKRORDER_UNREAD_COUNT'
export const UPDATE_BULK_WORKORDERS_REQUEST = 'UPDATE_BULK_WORKORDERS_REQUEST'
export const SET_WORKORDER_IS_LOADING = 'SET_WORKORDER_IS_LOADING'
export const BULK_DELETE_WORKORDER_REQUEST = 'BULK_DELETE_WORKORDER_REQUEST'
export const BULK_DELETE_WORKORDERS = 'BULK_DELETE_WORKORDERS'
export const GET_OR_CREATE_WORKORDER_CHANNEL = 'GET_OR_CREATE_WORKORDER_CHANNEL'
export const UPDATE_CHANGE_ORDER_STATUS = 'UPDATE_CHANGE_ORDER_STATUS'
export const ADD_NEW_WORKORDER = 'ADD_NEW_WORKORDER'
export const ADD_NEW_CHANGE_ORDER = 'ADD_NEW_CHANGE_ORDER'

export type WorkorderRequest =
    | typeof GET_WORKORDER_LIST_REQUEST
    | typeof CREATE_WORKORDER_REQUEST
    | typeof UPDATE_WORKORDER_REQUEST
    | typeof TRANSITION_WORKORDER_REQUEST
    | typeof UPDATE_BULK_WORKORDERS_REQUEST
    | typeof BULK_DELETE_WORKORDER_REQUEST
    | typeof GET_OR_CREATE_WORKORDER_CHANNEL

interface GetWorkorderListRequestAction extends Action {
    type: typeof GET_WORKORDER_LIST_REQUEST
}

interface GetWorkorderListResponseAction extends Action {
    type: typeof GET_WORKORDER_LIST_RESPONSE
    payload: BaseWorkorder[]
}

interface CreateWorkorderRequestAction extends Action {
    type: typeof CREATE_WORKORDER_REQUEST
}

interface CreateWorkorderResponseAction extends Action {
    type: typeof CREATE_WORKORDER_RESPONSE
    payload: CreateWorkorderResponse
}

interface UpdateWorkorderRequestAction extends Action {
    type: typeof UPDATE_WORKORDER_REQUEST
}

export interface UpdateWorkorderAction extends Action {
    type: typeof UPDATE_WORKORDER
    workorder: BaseWorkorder | string
    endLoading?: JobsiteRequest | WorkorderRequest
    workorderId: number
}

interface SetWorkOrderListAction extends Action {
    type: typeof SET_WORKORDER_LIST
    workorderList: BaseWorkorder[]
}

export interface TransitionWorkorderRequestAction extends Action {
    type: typeof TRANSITION_WORKORDER_REQUEST
}

export interface ClearWorkorderUnreadCountAction extends Action {
    type: typeof CLEAR_WOKRORDER_UNREAD_COUNT
    woId: number
}

interface UpdateBulkWorkordersAction extends Action {
    type: typeof UPDATE_BULK_WORKORDERS
    workorders: BaseWorkorder[]
}

export interface DeleteWorkorderAction extends Action {
    type: typeof DELETE_WORKORDER
    workorderId: number
}

export interface BulkDeleteWorkorderAction extends Action {
    type: typeof BULK_DELETE_WORKORDERS
    workorderIds: number[]
}

export interface SetWorkorderIsLoadingAction extends Action {
    type: typeof SET_WORKORDER_IS_LOADING
    isLoading: boolean
    request: WorkorderRequest
}

export interface UpdateChangeOrderStatus extends Action {
    type: typeof UPDATE_CHANGE_ORDER_STATUS
    updatedWorkorder: BaseWorkorder
    newWorkOrder?: BaseWorkorder
}

export interface AddNewChangeOrder extends Action {
    type: typeof ADD_NEW_CHANGE_ORDER
    workorder: BaseWorkorder
    changeOrder: ChangeOrder
}

export interface AddWorkorderToState extends Action {
    type: typeof ADD_NEW_WORKORDER
    workorder: BaseWorkorder
}

export interface SetPortfolioWorkorderList extends Action {
    type: typeof SET_PORTFOLIO_WORKORDER_LIST
    workorderList: BaseWorkorder[]
}

export type WorkorderActionTypes =
    | GetWorkorderListRequestAction
    | GetWorkorderListResponseAction
    | SetWorkOrderListAction
    | CreateWorkorderRequestAction
    | CreateWorkorderResponseAction
    | UpdateWorkorderRequestAction
    | TransitionWorkorderRequestAction
    | UpdateWorkorderAction
    | ClearWorkorderUnreadCountAction
    | UpdateBulkWorkordersAction
    | DeleteWorkorderAction
    | SetWorkorderIsLoadingAction
    | BulkDeleteWorkorderAction
    | UpdateChangeOrderStatus
    | AddWorkorderToState
    | SetPortfolioWorkorderList
    | AddNewChangeOrder

// Thunks
export type GetWorkorderListActionThunk = Promise<
    AxiosResponse<BaseWorkorder[]>
>

export type CreateWorkorderActionThunk = Promise<
    AxiosResponse<CreateWorkorderResponse>
>

export type WorkorderDetailActionThunk = Promise<AxiosResponse<BaseWorkorder>>

// reducers
export interface WorkorderState {
    workorderList: BaseWorkorder[]
    portfolioWorkorderList: BaseWorkorder[]
    isLoading: {
        [GET_WORKORDER_LIST_REQUEST]: boolean
        [CREATE_WORKORDER_REQUEST]: boolean
        [UPDATE_WORKORDER_REQUEST]: boolean
        [UPDATE_BULK_WORKORDERS_REQUEST]: boolean
        [BULK_DELETE_WORKORDER_REQUEST]: boolean
        [GET_OR_CREATE_WORKORDER_CHANNEL]: boolean
    }
}
