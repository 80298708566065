import { toast } from 'react-toastify'
import { axiosInstance } from '../helpers'

export const useJobBoard = () => {
    const getMostActiveVendors = async (request: MostActiveVendorsRequest) => {
        try {
            const res = await axiosInstance.get(
                'company/vendor/get_most_active_vendors/',
                { params: request.params },
            )
            const mostActiveVendors: number[] = res.data
            return mostActiveVendors
        } catch (e) {
            toast.error('Error getting most active vendors')
            return Promise.reject(e)
        }
    }

    return {
        getMostActiveVendors,
    }
}

export interface MostActiveVendorsRequest {
    params: {
        organization_id: number
        upper_bound_date: string
        lower_bound_date: string
    }
}
