import React, { useEffect, useMemo, useState } from 'react'

import moment from 'moment'
import { toast } from 'react-toastify'
import { eachDayOfInterval, format } from 'date-fns'

import {
    CircularProgress,
    Modal,
    Paper,
    Slide,
    useTheme,
} from '@material-ui/core'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import {
    Container,
    CreateWorkorderForm,
    FilterChips,
    MessageForm,
    SideDrawer,
    SideDrawerContainer,
    WorkorderEditForm,
    WorkorderFilterForm,
} from '../../../components'
import {
    _useFinderSelection,
    useAppDispatch,
    useAptConfig,
    useCompany,
    useRootInfrastructure,
    useSchedule,
    useService,
    useUser,
} from '../../../hooks'
import { GetWorkOrderListRequest, workorderContext } from '../../../contexts'
import {
    CreateWorkOrderRequest,
    FinderLocationSelection,
    FinderSelectionMode,
    GetVendorListRequest,
    setWorkSpaceUser,
    WorkorderResponse,
} from '../../../store'
import {
    ListVendor,
    activeStatuses,
    ModelMap,
    convertListToMap,
} from '../../../models'
import { BulkEditWorkorderForm } from '../../../components/WorkorderLib/BulkEditWorkorderForm'
import { JobBoardSelectionMode } from '../JobBoardComponents/JobBoardSelectionMode'
import { closedJbFormState, JbDrawerId, JbFormState, THIS_WEEK } from '../types'
import { OrganizationJobBoardHeader } from './OrganizationJobBoardHeader'
import VendorAndPropertySelect from './VendorAndPropertySelect'
import { OrganizationJobBoard } from './OrganizationJobBoard'
import { APARTMENT } from '../../../helpers'
import { useJobBoard } from '../../../hooks/useJobBoard'

export const OrganizationJobBoardHome = () => {
    const {
        vendorList,
        apartmentVendorMap,
        getVendorList,
        getApartmentVendorMap,
        addVendorToApartment,
    } = useCompany()

    const {
        userList,
        actions,
        organization,
        rootUser,
        workspaceUser,
    } = useUser()

    const { getMostActiveVendors } = useJobBoard()

    const dispatch = useAppDispatch()

    const {
        workorderList,
        filteredWorkorderList,
        workorderFilterState,
        loadingState,
        getWorkorderList,
        updateWorkorderState,
        setWorkorderFilterState,
        getChipData,
        updateWorkorder,
        createWorkorders,
        bulkUpdateWorkorders,
        bulkDeleteWorkorders,
    } = workorderContext()

    const { serviceList } = useService({ getServiceList: true, cleanUp: true })

    const { scheduleList, getScheduleList } = useSchedule()

    const { tree } = useRootInfrastructure(true)

    const theme = useTheme()

    const [isLoading, setIsLoading] = useState(true)
    const [dateFilter, setDateFilter] = useState(THIS_WEEK)
    const [startDate, setStartDate] = useState<MaterialUiPickersDate>(
        moment().day(1).toDate(),
    )
    const [endDate, setEndDate] = useState<MaterialUiPickersDate>(
        moment().day(7).toDate(),
    )

    const [formState, setFormState] = useState<JbFormState>(closedJbFormState)

    const [selectedVendors, setSelectedVendors] = useState<number[]>([])
    const [selectedProperty, setSelectedProperty] = useState<number>(-1)

    const [isMultiselectMode, setIsMultiselectMode] = useState(false)
    const [selectedWorkorders, setSelectedWorkorders] = useState<
        ModelMap<WorkorderResponse>
    >({})

    const [loadingApartmentData, setLoadingApartmentData] = useState(false)

    const finderSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.RootSelection,
        selectionMode: FinderSelectionMode.Recursive,
    })

    const {
        getAreaConfigMap,
        areaConfigList,
        getUnitConfigMap,
        unitConfigList,
        areaStatusConfigList,
        refreshConfigs,
    } = useAptConfig({
        unitConfigList: true,
        areaConfigList: true,
        areaStatusConfigList: true,
        cleanUp: true,
    })

    const dateRange = useMemo(
        () =>
            eachDayOfInterval({
                start: new Date(startDate?.toISOString() ?? ''),
                end: new Date(endDate?.toISOString() ?? ''),
            }),
        [startDate, endDate],
    )

    const refreshData = (
        startDate: Date | MaterialUiPickersDate,
        endDate: Date | MaterialUiPickersDate,
        initialVendors?: number[],
    ) => {
        startDate?.setHours(0, 0, 0)
        endDate?.setHours(23, 59, 59)

        const workorderRequestBody: GetWorkOrderListRequest = {
            params: {
                upper_bound_date: endDate?.toISOString(),
                lower_bound_date: startDate?.toISOString(),
                behind_date_range_union: true,
                vendor_list: initialVendors
                    ? initialVendors.join(',')
                    : selectedVendors.join(','),
            },
        }

        if (selectedProperty !== -1) {
            workorderRequestBody.params = {
                ...workorderRequestBody.params,
                apartment_id: selectedProperty,
            }
        } else if (organization !== null) {
            workorderRequestBody.params = {
                ...workorderRequestBody.params,
                organization: organization,
            }
        }

        getWorkorderList(workorderRequestBody, true)
    }

    useEffect(() => {
        setIsLoading(true)
        setSelectedVendors([])
        setSelectedProperty(-1)
        const vendorRequestBody: GetVendorListRequest = {
            params: { organization: organization ?? -1 },
        }

        const vendorPromise = getVendorList(vendorRequestBody)

        const apartmentVendorMapPromise = getApartmentVendorMap(
            organization ?? undefined,
        )

        const schedulePromise = getScheduleList({ params: { active: true } })

        const mostActiveVendorsPromise = getMostActiveVendors({
            params: {
                organization_id: organization ?? -1,
                upper_bound_date:
                    endDate?.toISOString() ??
                    moment().day(7).toDate().toISOString(),
                lower_bound_date:
                    startDate?.toISOString() ??
                    moment().day(1).toDate().toISOString(),
            },
        }).then((vendors) => {
            setSelectedVendors(vendors)
            refreshData(startDate, endDate, vendors)
        })

        Promise.allSettled([
            vendorPromise,
            apartmentVendorMapPromise,
            schedulePromise,
            mostActiveVendorsPromise,
        ]).finally(() => setIsLoading(false))
    }, [organization])

    const areaConfigMap = useMemo(() => {
        return getAreaConfigMap()
    }, [areaConfigList])

    const unitConfigMap = useMemo(() => {
        return getUnitConfigMap()
    }, [unitConfigList])

    const apartmentList = useMemo(() => {
        return (
            rootUser?.workspaces
                ?.filter(
                    (w) =>
                        w.active_workspace.organization === organization &&
                        w.active_workspace.company_type === APARTMENT,
                )
                .map((w) => w.active_workspace) ?? []
        )
    }, [rootUser?.workspaces, organization])

    const sortVendors = (
        vendors: ListVendor[],
        filteredWorkorderList: WorkorderResponse[],
        currentDate: string,
    ) => {
        const today = new Date()
        today.setHours(0, 0, 0, 0) // Set to start of day for accurate comparison

        return vendors.sort((vendorA, vendorB) => {
            const countWorkorders = (vendor: ListVendor) => {
                return filteredWorkorderList.filter((wo) => {
                    const woDate = new Date(wo.start_date)
                    woDate.setHours(0, 0, 0, 0) // Set to start of day for accurate comparison
                    const isCurrentDate = wo.start_date?.startsWith(currentDate)
                    const isOldActive =
                        woDate < today && activeStatuses.includes(wo.status)
                    return (
                        wo.vendor_id === vendor.id &&
                        (isCurrentDate || isOldActive)
                    )
                }).length
            }

            const aWorkorders = countWorkorders(vendorA)
            const bWorkorders = countWorkorders(vendorB)

            // Sort by number of workorders (descending)
            if (bWorkorders !== aWorkorders) {
                return bWorkorders - aWorkorders
            }

            // If workorder count is the same, sort by in-house status
            if (vendorA.in_house !== vendorB.in_house) {
                return vendorA.in_house ? -1 : 1 // In-house vendors come first
            }

            // If both criteria are the same, sort alphabetically by name
            return vendorA.name.localeCompare(vendorB.name)
        })
    }

    const filteredVendorList = useMemo(() => {
        return vendorList.filter((vendor) =>
            selectedVendors.includes(vendor.id),
        )
    }, [vendorList, selectedVendors])

    const sortedVendors = useMemo(() => {
        let currentDate = format(new Date(), 'yyyy-MM-dd')
        if (dateFilter !== THIS_WEEK) {
            currentDate = format(
                new Date(startDate?.toISOString() ?? ''),
                'yyyy-MM-dd',
            )
        }
        return sortVendors(
            filteredVendorList,
            filteredWorkorderList,
            currentDate,
        )
    }, [filteredVendorList, dateFilter, startDate, isLoading])

    const workorderMap = useMemo(() => {
        console.log('WORKORDER MAP')
        const map = new Map<string, WorkorderResponse[]>()
        const today = new Date()
        today.setHours(0, 0, 0, 0)
        const todayString = format(today, 'yyyy-MM-dd')
        filteredWorkorderList?.forEach((wo) => {
            const woStartDate = new Date(wo.start_date)
            if (wo.vendor_id || wo.user_id) {
                let dateKey = format(woStartDate, 'yyyy-MM-dd')

                if (activeStatuses.includes(wo.status) && woStartDate < today) {
                    dateKey = todayString
                }

                if (!map.has(dateKey)) {
                    map.set(dateKey, [])
                }

                map.get(dateKey)!.push(wo)
            }
        })
        return map
    }, [filteredWorkorderList])

    const apartmentMap = useMemo(() => {
        return convertListToMap(apartmentList)
    }, [apartmentList])

    const openDetailDrawer = (
        workorder: WorkorderResponse,
        drawerId: JbDrawerId,
    ) => {
        setFormState({
            ...closedJbFormState,
            detailFormOpen: {
                workorder: workorder,
                drawerId: drawerId,
            },
        })
    }

    const openFilterDrawer = () => {
        setFormState({
            ...closedJbFormState,
            filterFormOpen: true,
        })
    }

    const handleCreateWorkorders = (request: CreateWorkOrderRequest) => {
        createWorkorders(request)
            .then(() => {
                setFormState({
                    ...formState,
                    createWorkorderForm: false,
                })
                finderSelection.setFinderSelection()
            })
            .catch((e) => {
                let errorMessage = <span>Error</span>
                if (e.response?.data?.message !== undefined) {
                    errorMessage = (
                        <Container
                            style={{
                                flexDirection: 'column',
                            }}
                        >
                            <span
                                style={{
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Error
                            </span>
                            <span>- {e.response?.data?.message}</span>
                        </Container>
                    )
                }
                toast.error(errorMessage, {
                    autoClose: 3000,
                })
            })
    }

    const getApartmentData = (id: number) => {
        const activeWorkspaceId = workspaceUser?.active_workspace.id
        if (id !== -1 && activeWorkspaceId !== id) {
            const newWorkspaceUser = rootUser?.workspaces.find(
                (wu) => wu.active_workspace.id === id,
            )
            Promise.resolve(dispatch(setWorkSpaceUser(newWorkspaceUser))).then(
                () => {
                    setLoadingApartmentData(true)
                    Promise.allSettled([
                        refreshConfigs(),
                        getScheduleList({ params: { active: true } }),
                        actions.getUserList({ params: { my_team: true } }),
                    ]).finally(() => {
                        setLoadingApartmentData(false)
                    })
                },
            )
        }
    }

    const drawerContainerOpen =
        (formState.detailFormOpen.workorder !== null &&
            formState.detailFormOpen.drawerId !== null &&
            formState.detailFormOpen.drawerId !== JbDrawerId.edit) ||
        formState.filterFormOpen

    return (
        <SideDrawerContainer open={drawerContainerOpen} width={DRAWER_W}>
            <Container
                style={{
                    height: 'calc(100vh - 100px)',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    flex: 1,
                }}
            >
                <OrganizationJobBoardHeader
                    formState={formState}
                    dateFilter={dateFilter}
                    startDate={startDate}
                    endDate={endDate}
                    setDateFilter={setDateFilter}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    refreshData={refreshData}
                    openFilterDrawer={openFilterDrawer}
                    setFormState={setFormState}
                />

                <Container style={{ marginTop: theme.spacing(1) }}>
                    <Container
                        style={{ width: 275, marginRight: theme.spacing(1) }}
                    >
                        <JobBoardSelectionMode
                            isMultiselectMode={isMultiselectMode}
                            selectedWorkorders={selectedWorkorders}
                            organization={organization}
                            setIsMultiselectMode={setIsMultiselectMode}
                            setSelectedWorkorders={setSelectedWorkorders}
                            bulkDeleteWorkorders={bulkDeleteWorkorders}
                            openBulkEditModal={() => {
                                setFormState({
                                    ...formState,
                                    bulkEditModal: true,
                                })
                            }}
                        />
                    </Container>

                    <VendorAndPropertySelect
                        vendorList={vendorList}
                        selectedVendors={selectedVendors}
                        propertyList={apartmentList}
                        selectedProperty={selectedProperty}
                        refreshWorkorders={() => {
                            refreshData(startDate, endDate)
                        }}
                        setSelectedVendors={setSelectedVendors}
                        setSelectedProperty={setSelectedProperty}
                    />
                </Container>

                <FilterChips theme={theme} chips={getChipData()} />

                {isLoading || loadingState.getWorkorderList ? (
                    <Container
                        style={{
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CircularProgress size={100} />
                    </Container>
                ) : (
                    <OrganizationJobBoard
                        filteredWorkorderList={filteredWorkorderList}
                        workorderList={workorderList ?? []}
                        vendorList={vendorList}
                        workorderMap={workorderMap}
                        sortedVendors={sortedVendors}
                        serviceList={serviceList}
                        dateRange={dateRange}
                        scheduleList={[]}
                        isMultiselectMode={isMultiselectMode}
                        selectedWorkorders={selectedWorkorders}
                        apartmentMap={apartmentMap}
                        organization={organization}
                        apartmentVendorMap={apartmentVendorMap}
                        apartmentList={apartmentList}
                        isLoading={isLoading && loadingState.getWorkorderList}
                        setSelectedWorkorders={setSelectedWorkorders}
                        openDetailDrawer={openDetailDrawer}
                        addVendorToApartment={addVendorToApartment}
                    />
                )}

                {formState.detailFormOpen.drawerId === JbDrawerId.message && (
                    <SideDrawer
                        open={
                            formState.detailFormOpen.workorder !== null &&
                            formState.detailFormOpen.drawerId ===
                                JbDrawerId.message
                        }
                        width={DRAWER_W}
                        handleClose={() => setFormState(closedJbFormState)}
                        title={'Workorder Messages'}
                    >
                        <MessageForm
                            theme={theme}
                            channelId={
                                formState.detailFormOpen.workorder?.channel ??
                                -1
                            }
                            secondaryChannelId={
                                formState.detailFormOpen.workorder
                                    ?.inv_ins_channel
                            }
                            afterCreateMessage={() => {
                                if (formState.detailFormOpen.workorder) {
                                    updateWorkorderState({
                                        ...formState.detailFormOpen.workorder,
                                        message_count:
                                            (formState.detailFormOpen.workorder
                                                .message_count ?? 0) + 1,
                                    })
                                }
                            }}
                            secondaryTitle="From Inventory Inspection"
                        />
                    </SideDrawer>
                )}

                {formState.detailFormOpen.drawerId === JbDrawerId.unitNotes && (
                    <SideDrawer
                        open={
                            formState.detailFormOpen.workorder !== null &&
                            formState.detailFormOpen.drawerId ===
                                JbDrawerId.unitNotes
                        }
                        width={DRAWER_W}
                        handleClose={() => setFormState(closedJbFormState)}
                        title={`${formState.detailFormOpen.workorder?.unit_name} Unit Notes`}
                    >
                        <MessageForm
                            theme={theme}
                            channelId={
                                formState.detailFormOpen.workorder
                                    ?.unit_channel_id ?? -1
                            }
                            afterCreateMessage={() => {
                                if (formState.detailFormOpen.workorder) {
                                    updateWorkorderState({
                                        ...formState.detailFormOpen.workorder,
                                        unit_notes_msg_count:
                                            (formState.detailFormOpen.workorder
                                                .unit_notes_msg_count ?? 0) + 1,
                                    })
                                }
                            }}
                        />
                    </SideDrawer>
                )}

                <SideDrawer
                    open={formState.filterFormOpen}
                    width={DRAWER_W}
                    handleClose={() => setFormState(closedJbFormState)}
                >
                    <WorkorderFilterForm
                        theme={theme}
                        serviceList={serviceList}
                        unitConfigList={[]}
                        workorderFilterState={workorderFilterState}
                        vendorList={vendorList}
                        userList={userList}
                        disabledSections={[
                            'timeline',
                            'vendors',
                            'users',
                            'unitTypes',
                        ]}
                        setWorkorderFilterState={setWorkorderFilterState}
                    />
                </SideDrawer>

                <Modal
                    open={formState.createWorkorderForm}
                    onClose={() => {
                        setFormState(closedJbFormState)
                        finderSelection.setFinderSelection()
                    }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Slide direction="up" in={formState.createWorkorderForm}>
                        <Paper style={{}}>
                            <CreateWorkorderForm
                                vendorList={vendorList}
                                serviceList={serviceList}
                                theme={theme}
                                root={tree.root}
                                finderSelection={finderSelection}
                                areaConfigMap={areaConfigMap}
                                areaStatusConfigs={areaStatusConfigList ?? []}
                                unitConfigMap={unitConfigMap}
                                areaStatusTagMap={{}}
                                unitWorkorderMap={{}}
                                userList={userList}
                                scheduleList={scheduleList}
                                apartmentList={apartmentList}
                                apartmentDataLoading={loadingApartmentData}
                                apartmentVendorMap={apartmentVendorMap}
                                addVendorToApartment={addVendorToApartment}
                                getApartmentData={getApartmentData}
                                onClickCreate={handleCreateWorkorders}
                            />
                        </Paper>
                    </Slide>
                </Modal>

                {formState.detailFormOpen.workorder && (
                    <Modal
                        open={
                            formState.detailFormOpen.drawerId ===
                            JbDrawerId.edit
                        }
                        onClose={() =>
                            setFormState({
                                ...formState,
                                detailFormOpen:
                                    closedJbFormState.detailFormOpen,
                            })
                        }
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Slide
                            direction="up"
                            in={
                                formState.detailFormOpen.drawerId ===
                                JbDrawerId.edit
                            }
                        >
                            <Paper style={{}}>
                                <WorkorderEditForm
                                    theme={theme}
                                    serviceList={serviceList}
                                    userList={userList}
                                    vendorList={vendorList}
                                    workorder={
                                        formState.detailFormOpen.workorder
                                    }
                                    handleClose={() =>
                                        setFormState({
                                            ...formState,
                                            detailFormOpen:
                                                closedJbFormState.detailFormOpen,
                                        })
                                    }
                                    handleSubmit={(request) => {
                                        if (
                                            formState.detailFormOpen.workorder
                                                ?.apartment_id
                                        ) {
                                            request.params = {
                                                apartment_id:
                                                    formState.detailFormOpen
                                                        .workorder.apartment_id,
                                            }
                                        }
                                        return updateWorkorder(request)
                                    }}
                                    loading={loadingState.updateWorkorder}
                                    height={800}
                                    width={1000}
                                    organizationView
                                    apartmentVendorMap={apartmentVendorMap}
                                    addVendorToApartment={addVendorToApartment}
                                />
                            </Paper>
                        </Slide>
                    </Modal>
                )}

                <Modal
                    open={formState.bulkEditModal}
                    onClose={() => {
                        setFormState(closedJbFormState)
                    }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Slide direction="up" in={formState.bulkEditModal}>
                        <Paper>
                            <Container
                                style={{
                                    borderBottom: `1px solid ${theme.palette.divider}`,
                                }}
                            >
                                <span
                                    style={{
                                        ...theme.typography.h6,
                                        padding: theme.spacing(2),
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                    }}
                                >
                                    Bulk Edit Workorders
                                </span>
                            </Container>
                            <BulkEditWorkorderForm
                                open={formState.bulkEditModal}
                                selectedWorkorders={selectedWorkorders}
                                vendorList={vendorList}
                                serviceList={serviceList}
                                loading={loadingState.updateWorkorder}
                                organization={organization}
                                apartmentVendorMap={apartmentVendorMap}
                                handleClose={() => {
                                    setFormState(closedJbFormState)
                                }}
                                bulkUpdateWorkorders={bulkUpdateWorkorders}
                            />
                        </Paper>
                    </Slide>
                </Modal>
            </Container>
        </SideDrawerContainer>
    )
}

const DRAWER_W = 420
