import React, { useState } from 'react'

import { Container } from '../../components/Container'
import { Tab, Tabs, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Additions } from './Additions'
import { useKPI } from '../../hooks/useKPI'
import { Inspections } from './Inspections'
import { Damages } from './Damages'
import { Invoices } from './Invoices'
import { Schedules } from './Schedules'
import { ChangeOrders } from './ChangeOrders'
import { Users } from './Users'
import { LeaseAudits } from './LeaseAudits'

export const KPIHome = () => {
    const theme = useTheme()

    const ADDITIONS = 0
    const DAMAGES = 1
    const INVOICES = 2
    const SCHEDULES = 3
    const INSPECTIONS = 4
    const CHANGE_ORDERS = 5
    const USERS = 6
    const LEASEAUDITS = 7

    const [selectedTab, setSelectedTab] = useState<number>(ADDITIONS)
    const KPIController = useKPI()

    return (
        <Container
            style={{
                flexDirection: 'column',
            }}
        >
            {/* Tabs */}
            <Container>
                <Tabs
                    value={selectedTab}
                    onChange={(_, newValue) => setSelectedTab(newValue)}
                >
                    <Tab label="Additions" />
                    <Tab label="Damages" />
                    <Tab label="Invoices" />
                    <Tab label="Schedules" />
                    <Tab label="Inspections" />
                    <Tab label="Change Orders" />
                    <Tab label="Users" />
                    <Tab label="Lease Audits" />
                </Tabs>
            </Container>

            {/* Body */}
            <Container
                style={{
                    flex: 1,
                    marginTop: theme.spacing(2),
                }}
            >
                {selectedTab === ADDITIONS && (
                    <Additions KPIController={KPIController} />
                )}
                {selectedTab === INSPECTIONS && (
                    <Inspections KPIController={KPIController} />
                )}
                {selectedTab === DAMAGES && (
                    <Damages KPIController={KPIController} />
                )}
                {selectedTab === INVOICES && (
                    <Invoices KPIController={KPIController} />
                )}
                {selectedTab === SCHEDULES && (
                    <Schedules KPIController={KPIController} />
                )}
                {selectedTab === CHANGE_ORDERS && (
                    <ChangeOrders KPIController={KPIController} />
                )}
                {selectedTab === USERS && (
                    <Users KPIController={KPIController} />
                )}
                {selectedTab === LEASEAUDITS && (
                    <LeaseAudits KPIController={KPIController} />
                )}
            </Container>
        </Container>
    )
}
