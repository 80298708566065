import React, { useState } from 'react'
import {
    Modal,
    Slide,
    Paper,
    Typography,
    useTheme,
    IconButton,
    TextField,
    makeStyles,
    ListItemText,
    FormControl,
    InputLabel,
    MenuItem,
    Button,
} from '@material-ui/core'
import { Container } from '../../components/Container'
import CloseIcon from '@material-ui/icons/Close'
import { useUser } from '../../hooks/useUser'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Dropzone from 'react-dropzone'
import { DropFiles } from '../DropFiles/DropFiles'
import DeleteForever from '@material-ui/icons/DeleteForever'
import {
    SupportTicketSubCategory,
    SupportTicketCategory,
    SupportTicket,
    SupportTicketStatus,
} from '../../models'
import { useSupportTicket } from '../../hooks/useSupportTicket'
import { Conversation } from '../../navigation/layouts/Lori/types'
const useStyles = makeStyles((theme) => ({
    modalContainer: {
        position: 'absolute',
        right: '20vw',
        top: '10vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
    mainContainer: {
        width: '70vw',
        minWidth: '800px',
        maxWidth: '1200px',
        height: '80vh',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4),
        flexDirection: 'column',
        overflowY: 'scroll',
        margin: '0 auto',
    },
    header: {
        flexDirection: 'row',
    },
    title: {
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    },
    titleText: {
        ...theme.typography.h6,
        fontWeight: 'bold',
    },
    closeButton: {
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    formBody: {
        maxWidth: '800px',
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        flexDirection: 'column',
    },
    formTitle: {
        fontWeight: theme.typography.fontWeightBold,
    },
    formSpacer: {
        marginTop: theme.spacing(2),
    },
    selectorForm: {
        flexDirection: 'column',
        width: 300,
        marginRight: theme.spacing(2),
    },
    selectors: {
        flexDirection: 'row',
        margin: theme.spacing(2),
    },
    issues: {
        flexDirection: 'column',
        margin: theme.spacing(2),
    },
    imageUpload: {
        flexDirection: 'column',
        margin: theme.spacing(2),
    },
    submitButton: {
        flex: 1,
        marginTop: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const normalizeCategory = (category: string): string => {
    return category
        .split('_')
        .map(
            (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
        )
        .join(' ')
}

interface Props {
    open: boolean
    onClose: () => void
    conversation: Conversation | null
}

export const CustomerSupportModal = (props: Props) => {
    const { open, onClose, conversation } = props

    const { workspaceUser } = useUser()
    if (!workspaceUser) {
        return null
    }
    const classes = useStyles()
    const theme = useTheme()
    const { createSupportTicket } = useSupportTicket()

    const [images, setImages] = useState<File[]>([])
    const [issue, setIssue] = useState<string>('')
    const [category, setCategory] = useState<SupportTicketCategory | null>(null)
    const [
        subCategory,
        setSubCategory,
    ] = useState<SupportTicketSubCategory | null>(null)

    const handleSubmit = () => {
        const newFormData = new FormData()
        newFormData.append('conversation', conversation?.id.toString() ?? '0')
        newFormData.append('issue_description', issue)
        newFormData.append('category', category ?? '')
        newFormData.append('sub_category', subCategory ?? '')
        const numImages = images.length
        for (let i = 0; i < numImages; i++) {
            newFormData.append(`attachment_${i}`, images[i])
        }
        newFormData.append('num_images', numImages.toString())
        createSupportTicket(newFormData)
        onClose()
    }

    const problemCategories = Object.values(SupportTicketCategory)
    const problemSubCategories = Object.values(SupportTicketSubCategory)

    return (
        <Modal
            open={open}
            onClose={onClose}
            disableEnforceFocus
            disableAutoFocus
            style={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper>
                    <Container className={classes.modalContainer}>
                        <Container className={classes.mainContainer}>
                            {/* header */}
                            <Container className={classes.header}>
                                <Container className={classes.title}>
                                    <Typography className={classes.titleText}>
                                        EZOS Customer Support Ticket Form
                                    </Typography>
                                </Container>
                                <Container className={classes.closeButton}>
                                    <IconButton onClick={onClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Container>
                            </Container>

                            {/* body */}
                            <Container className={classes.formBody}>
                                {/* selectors */}
                                <Container className={classes.selectors}>
                                    {/* problem category */}
                                    <Container className={classes.selectorForm}>
                                        <Container>
                                            <Typography
                                                className={classes.formTitle}
                                            >
                                                Issue Category{' '}
                                                <span style={{ color: 'red' }}>
                                                    *
                                                </span>
                                            </Typography>
                                        </Container>
                                        <Container
                                            className={classes.selectorForm}
                                        >
                                            <FormControl variant="outlined">
                                                <Select
                                                    id={`category-select`}
                                                    value={category || ''}
                                                    onChange={(
                                                        e: SelectChangeEvent<string>,
                                                    ) => {
                                                        setCategory(
                                                            e.target
                                                                .value as SupportTicketCategory,
                                                        )
                                                    }}
                                                    style={{
                                                        paddingLeft: theme.spacing(
                                                            1,
                                                        ),
                                                    }}
                                                >
                                                    {problemCategories.map(
                                                        (category) => (
                                                            <MenuItem
                                                                key={`CATEGORY_SELECT__${category}`}
                                                                value={category}
                                                            >
                                                                <ListItemText
                                                                    primary={normalizeCategory(
                                                                        category,
                                                                    )}
                                                                />
                                                            </MenuItem>
                                                        ),
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Container>
                                    </Container>
                                    {/* problem sub category */}
                                    <Container className={classes.selectorForm}>
                                        <Container>
                                            <Typography
                                                className={classes.formTitle}
                                            >
                                                Issue Sub Category{' '}
                                                <span style={{ color: 'red' }}>
                                                    *
                                                </span>
                                            </Typography>
                                        </Container>
                                        <Container
                                            className={classes.selectorForm}
                                        >
                                            <FormControl variant="outlined">
                                                <Select
                                                    id={`subcategory-select`}
                                                    value={subCategory || ''}
                                                    onChange={(
                                                        e: SelectChangeEvent<string>,
                                                    ) => {
                                                        setSubCategory(
                                                            e.target
                                                                .value as SupportTicketSubCategory,
                                                        )
                                                    }}
                                                    style={{
                                                        paddingLeft: theme.spacing(
                                                            1,
                                                        ),
                                                    }}
                                                >
                                                    {problemSubCategories.map(
                                                        (category) => (
                                                            <MenuItem
                                                                key={`CATEGORY_SELECT__${category}`}
                                                                value={category}
                                                            >
                                                                <ListItemText
                                                                    primary={normalizeCategory(
                                                                        category,
                                                                    )}
                                                                />
                                                            </MenuItem>
                                                        ),
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Container>
                                    </Container>
                                </Container>

                                {/* issue */}
                                <Container className={classes.issues}>
                                    <Container>
                                        <Typography
                                            className={classes.formTitle}
                                        >
                                            Issue{' '}
                                            <span style={{ color: 'red' }}>
                                                *
                                            </span>
                                        </Typography>
                                    </Container>
                                    <Container>
                                        <TextField
                                            value={issue}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>,
                                            ) => {
                                                setIssue(e.target.value)
                                            }}
                                            variant="outlined"
                                            multiline
                                            fullWidth
                                            rows={4}
                                            helperText="Please describe, in as much detail as possible, the issue you are facing"
                                        />
                                    </Container>
                                </Container>

                                {/* Image Upload */}
                                <Container className={classes.imageUpload}>
                                    <Container>
                                        <Typography
                                            className={classes.formTitle}
                                        >
                                            Image Upload{' '}
                                        </Typography>
                                    </Container>
                                    <Container
                                        style={{
                                            marginTop: theme.spacing(2),
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Container
                                            style={{
                                                width: '50%',
                                            }}
                                        >
                                            <DropFiles
                                                onDrop={(acceptedFiles) => {
                                                    const currentFiles = images
                                                    if (
                                                        currentFiles.length >= 3
                                                    ) {
                                                        // Could add a toast/alert here to inform user
                                                        return
                                                    }

                                                    const remainingSlots =
                                                        3 - currentFiles.length
                                                    const newFiles: File[] = acceptedFiles
                                                        .slice(
                                                            0,
                                                            remainingSlots,
                                                        )
                                                        .map((file) => file)
                                                    setImages([
                                                        ...images,
                                                        ...newFiles,
                                                    ])
                                                }}
                                                fileTypes={{
                                                    'image/png': [],
                                                    'image/jpeg': [],
                                                    'image/jpg': [],
                                                }}
                                                displayString={`Drag and drop images here, or click to select an image (${
                                                    3 - images.length
                                                } remaining)`}
                                            />
                                        </Container>
                                        <Container
                                            style={{
                                                width: '50%',
                                            }}
                                        >
                                            {images.length > 0 && (
                                                <Container
                                                    style={{
                                                        flexDirection: 'row',
                                                        marginLeft: theme.spacing(
                                                            2,
                                                        ),
                                                    }}
                                                >
                                                    {images.map((image) => (
                                                        <Container
                                                            key={image.name}
                                                            style={{
                                                                marginRight: theme.spacing(
                                                                    2,
                                                                ),
                                                                flexDirection:
                                                                    'column',
                                                                border: `1px solid ${theme.palette.grey[400]}`,
                                                                borderRadius:
                                                                    theme.shape
                                                                        .borderRadius,
                                                                padding: theme.spacing(
                                                                    1,
                                                                ),
                                                                width: 100,
                                                            }}
                                                        >
                                                            <Container
                                                                style={{
                                                                    flexDirection:
                                                                        'row',
                                                                }}
                                                            >
                                                                <Container
                                                                    style={{
                                                                        justifyContent:
                                                                            'center',
                                                                        flex: 1,
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={URL.createObjectURL(
                                                                            image,
                                                                        )}
                                                                        alt="uploaded"
                                                                        key={
                                                                            image.name
                                                                        }
                                                                        style={{
                                                                            width: 50,
                                                                            height: 50,
                                                                            objectFit:
                                                                                'cover',
                                                                        }}
                                                                    />
                                                                </Container>
                                                                <Container
                                                                    style={{
                                                                        alignItems:
                                                                            'flex-start',
                                                                        justifyContent:
                                                                            'flex-end',
                                                                    }}
                                                                >
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            setImages(
                                                                                images.filter(
                                                                                    (
                                                                                        img,
                                                                                    ) =>
                                                                                        img.name !==
                                                                                        image.name,
                                                                                ),
                                                                            )
                                                                        }}
                                                                    >
                                                                        <DeleteForever color="secondary" />
                                                                    </IconButton>
                                                                </Container>
                                                            </Container>
                                                            <Typography
                                                                style={{
                                                                    ...theme
                                                                        .typography
                                                                        .body2,
                                                                    wordBreak:
                                                                        'break-word',
                                                                    width:
                                                                        '100%',
                                                                }}
                                                            >
                                                                {image.name}
                                                            </Typography>
                                                        </Container>
                                                    ))}
                                                </Container>
                                            )}
                                        </Container>
                                    </Container>
                                </Container>

                                {/* submit button */}
                                <Container className={classes.submitButton}>
                                    <Button
                                        onClick={handleSubmit}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Submit
                                    </Button>
                                </Container>
                            </Container>
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
