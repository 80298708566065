import React from 'react'

import clsx from 'clsx'

import { IconButton, makeStyles, Tooltip, useTheme } from '@material-ui/core'
import HistoryIcon from '@material-ui/icons/History'
import NotificationsIcon from '@material-ui/icons/Notifications'
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk'

import { LoriIcon } from './Icons/LoriIcon'
import { Container } from '../../../components'

const useStyles = makeStyles((theme) => ({
    highlighted: {
        backgroundColor: '#ffffff',
        transition: 'background-color 0.3s ease, transform 0.3s ease',
        transform: 'scale(1.05)',
        '&:hover': {
            backgroundColor: '#ffffff',
        },
        '& svg': {
            color: theme.palette.darkBlue.main,
        },
    },
    iconButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        flexDirection: 'column',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.white.main}`,
        marginBottom: theme.spacing(1),
    },
}))

interface Props {
    selectedChannel: LoriChannel
    setSelectedChannel: (channel: LoriChannel) => void
}

export const ChannelSelector = (props: Props) => {
    const { selectedChannel, setSelectedChannel } = props
    const theme = useTheme()
    const classes = useStyles()

    return (
        <Container
            className={classes.container}
            style={{ paddingBottom: theme.spacing(1) }}
        >
            <Tooltip title="Lori">
                <IconButton
                    onClick={() => {
                        if (selectedChannel === 'lori') {
                            setSelectedChannel('none')
                        } else {
                            setSelectedChannel('lori')
                        }
                    }}
                    size="small"
                    className={clsx(classes.iconButton, {
                        [classes.highlighted]: selectedChannel === 'lori',
                    })}
                >
                    <LoriIcon
                        width={24}
                        height={24}
                        color={
                            selectedChannel === 'lori'
                                ? theme.palette.darkBlue.main
                                : theme.palette.primary.contrastText
                        }
                    />
                </IconButton>
            </Tooltip>

            <Tooltip title="History">
                <IconButton
                    onClick={() => {
                        if (selectedChannel === 'history') {
                            setSelectedChannel('none')
                        } else {
                            setSelectedChannel('history')
                        }
                    }}
                    size="small"
                    className={clsx(classes.iconButton, {
                        [classes.highlighted]: selectedChannel === 'history',
                    })}
                >
                    <HistoryIcon
                        style={{ fontSize: 30 }}
                        htmlColor={theme.palette.primary.contrastText}
                    />
                </IconButton>
            </Tooltip>

            <Tooltip title="Walkthrough">
                <IconButton
                    onClick={() => {
                        if (selectedChannel === 'walkthrough') {
                            setSelectedChannel('none')
                        } else {
                            setSelectedChannel('walkthrough')
                        }
                    }}
                    size="small"
                    className={clsx(classes.iconButton, {
                        [classes.highlighted]:
                            selectedChannel === 'walkthrough',
                    })}
                >
                    <DirectionsWalkIcon
                        style={{ fontSize: 30 }}
                        htmlColor={theme.palette.primary.contrastText}
                    />
                </IconButton>
            </Tooltip>

            {/* <Tooltip title="Notifications">
                <IconButton
                    onClick={() => {
                        if (selectedChannel === 'notifications') {
                            setSelectedChannel('none')
                        } else {
                            setSelectedChannel('notifications')
                        }
                    }}
                    size="small"
                    className={clsx(classes.iconButton, {
                        [classes.highlighted]:
                            selectedChannel === 'notifications',
                    })}
                >
                    <NotificationsIcon
                        style={{ fontSize: 30 }}
                        htmlColor={theme.palette.primary.contrastText}
                    />
                </IconButton>
            </Tooltip> */}
        </Container>
    )
}
