export const formatNumber = (num: number | undefined): string => {
    if (!num) return ''
    return num.toLocaleString('en-US')
}

export const padNumberForAlignment = (numbers: (number | undefined)[], currentNum: number | undefined): string => {
    if (!currentNum) return ''
    
    // Format all numbers with thousands separator
    const formattedNumbers = numbers.map(n => n ? n.toLocaleString('en-US') : '')
    const maxLength = Math.max(...formattedNumbers.map(n => n.length))
    
    // Format current number and pad with spaces
    const formattedCurrent = currentNum.toLocaleString('en-US')
    const padding = '  '.repeat(maxLength - formattedCurrent.length)
    return padding + formattedCurrent
}
