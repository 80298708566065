import React, { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import MenuOpenIcon from '@material-ui/icons/MenuOpen'

import { useLocation } from 'react-router-dom'
import TopNavBar, { ProductView } from '../TopBar'
import { AdminPanel } from '../../../components/AdminPanel/AdminPanel'
import { useAppDispatch, useUser } from '../../../hooks'
import { isAccountManager } from '../../../helpers/stringHelpers'
import { Lori } from '../Lori'
import { AGENT_DRAWER_WIDTH, AGENT_HEADER_WIDTH } from '../../constants'
import SideBar from './sidebar'
import { APARTMENT } from '../../../helpers'
import { useSelector } from 'react-redux'
import { RootState, setLoriChannel } from '../../../store'

interface AuthLayoutProps {
    children: React.ReactNode
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
    const location = useLocation()

    const { rootUser } = useUser()

    const isRegistrationPage =
        location.pathname === '/vendor-register' ||
        location.pathname === '/skillcat-register'

    const isFullWidthPage =
        location.pathname === '/ez-chat-final' || isRegistrationPage

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                display: 'flex',
                width: '100%',
            },
            drawer: {
                flexShrink: 0,
                whiteSpace: 'nowrap',
                backgroundColor: '#2f2e41',
                color: '#737378',
                zIndex: 9999,
            },
            drawerOpen: {
                width: AGENT_DRAWER_WIDTH,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                backgroundColor: '#2f2e41',
                color: '#737378',
            },
            drawerClose: {
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                overflowX: 'hidden',
                width: `${AGENT_DRAWER_WIDTH} !important`,
                [theme.breakpoints.up('sm')]: {
                    width: `${AGENT_DRAWER_WIDTH} !important`,
                },
                backgroundColor: '#2f2e41',
                color: '#737378',
            },
            toolbar: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: theme.spacing(0, 1),
                minHeight: '56px !important',
                ...theme.mixins.toolbar,
            },
            contentDrawerOpen: {
                flexGrow: 1,
                width: `calc(100vw - ${AGENT_DRAWER_WIDTH}px - ${SIDE_BAR_WIDTH}px)`,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            contentDrawerClose: {
                flexGrow: 1,
                width: `calc(100vw - ${SIDE_BAR_WIDTH}px)`,
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            },
        }),
    )
    const classes = useStyles()

    const { workspaceUser } = useUser()

    const [adminDrawerOpen, setAdminDrawerOpen] = useState(false)

    const selectedChannel = useSelector(
        (state: RootState) => state.user.selectedLoriChannel,
    )

    const dispatch = useAppDispatch()

    const handleLoriDrawerToggle = () => {
        dispatch(setLoriChannel('none'))
    }

    const setSelectedChannel = (channel: LoriChannel) => {
        dispatch(setLoriChannel(channel))
    }

    return (
        <div className={classes.root}>
            {!isRegistrationPage && (
                <>
                    <TopNavBar
                        loriOpen={
                            selectedChannel !== 'none' &&
                            workspaceUser?.active_workspace.company_type ===
                                APARTMENT
                        }
                        loriDrawerWidth={SIDE_BAR_WIDTH}
                        productView={ProductView.EZTURN}
                        open={true}
                    />
                    <SideBar
                        open
                        drawerWidth={SIDE_BAR_WIDTH}
                        selectedChannel={selectedChannel}
                        setSelectedChannel={setSelectedChannel}
                    />
                    <Lori
                        open={
                            selectedChannel !== 'none' &&
                            workspaceUser?.active_workspace.company_type ===
                                APARTMENT
                        }
                        drawerWidth={AGENT_DRAWER_WIDTH}
                        onToggleDrawer={handleLoriDrawerToggle}
                        selectedChannel={selectedChannel}
                        setSelectedChannel={setSelectedChannel}
                    />
                </>
            )}

            <main
                className={
                    selectedChannel !== 'none' &&
                    workspaceUser?.active_workspace.company_type === APARTMENT
                        ? classes.contentDrawerOpen
                        : classes.contentDrawerClose
                }
                style={{
                    padding: isFullWidthPage ? 0 : '24px',
                }}
            >
                <div
                    className={classes.toolbar}
                    style={{
                        minHeight: isRegistrationPage ? 0 : '56px',
                    }}
                />
                {children}
            </main>

            {isAccountManager(rootUser) && (
                <div>
                    <div
                        style={{
                            height: 32,
                            width: 32,
                            position: 'fixed',
                            bottom: 2,
                            right: 2,
                            backgroundColor: '#2F2E41',
                            borderRadius: 32,
                            zIndex: 1000000000000,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={() => setAdminDrawerOpen((open) => !open)}
                    >
                        <MenuOpenIcon htmlColor="#fff" />
                    </div>

                    <AdminPanel
                        open={adminDrawerOpen}
                        onClose={() => setAdminDrawerOpen(false)}
                    />
                </div>
            )}
        </div>
    )
}

const SIDE_BAR_WIDTH = 60
