import React, { useEffect, useState } from 'react'
import {
    Checkbox,
    IconButton,
    Table,
    TableCell,
    TableHead,
    TableContainer,
    TableRow,
    Typography,
    TableBody,
    ListItemText,
    MenuItem,
    FormControl,
    InputLabel,
    Tooltip,
    Popover,
    Button,
    TablePagination,
    TableSortLabel,
    CircularProgress,
} from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import { Container } from '../../Container'
import { AddVideo } from './AddVideo'
import { VideoDetailsModal } from './VideoDetailsModal'
import { HelpVideo } from '../../../navigation/layouts/Lori/types'
import AddIcon from '@material-ui/icons/Add'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { LoriController } from '../../../hooks/useLori'
import Select, { SelectChangeEvent } from '@mui/material/Select'

type Order = 'asc' | 'desc'
type OrderBy =
    | 'category'
    | 'title'
    | 'duration'
    | 'uploaded_by'
    | 'added_at'
    | 'modified_date'

const formatDuration = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
}

interface Props {
    loriController: LoriController
}

export const VideoLibraryTab = ({ loriController }: Props) => {
    const theme = useTheme()

    useEffect(() => {
        loriController.getVideoLibrary()
        loriController.getCategories()
    }, [])

    // Add Video States
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [selectedVideo, setSelectedVideo] = useState<HelpVideo | null>(null)
    const [url, setUrl] = useState<string | undefined>(undefined)
    const [category, setCategory] = useState<string | undefined>(undefined)

    // Modal and Popover States
    const [VideoDetailsModalOpen, setVideoDetailsModalOpen] = useState<boolean>(
        false,
    )
    const [
        removeVideoPopoverOpen,
        setRemoveVideoPopoverOpen,
    ] = useState<boolean>(false)

    const [
        removeAnchorEl,
        setRemoveAnchorEl,
    ] = useState<HTMLButtonElement | null>(null)

    // Table States
    const [order, setOrder] = useState<Order>('desc')
    const [orderBy, setOrderBy] = useState<OrderBy>('modified_date')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setUrl(undefined)
        setCategory(undefined)
    }

    const [selectedCategories, setSelectedCategories] = useState<string[]>([])

    const handleChange = (
        event: SelectChangeEvent<typeof selectedCategories>,
    ) => {
        const {
            target: { value },
        } = event
        setSelectedCategories(
            typeof value === 'string' ? value.split(',') : value,
        )
    }

    const filteredVideoLibrary = loriController.videoLibrary.filter((video) => {
        // If no categories are selected, show all videos
        if (selectedCategories.length === 0) return true
        // Show videos that match any of the selected categories
        return selectedCategories.includes(video.category)
    })

    // Table Sorting
    const handleRequestSort = (property: OrderBy) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const compareValues = (a: any, b: any, orderBy: OrderBy) => {
        if (orderBy === 'duration') {
            return order === 'asc'
                ? a[orderBy] - b[orderBy]
                : b[orderBy] - a[orderBy]
        }
        if (order === 'asc') {
            return a[orderBy].localeCompare(b[orderBy])
        }
        return b[orderBy].localeCompare(a[orderBy])
    }

    // Table Pagination
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    return (
        <Container
            style={{
                flex: 1,
                padding: theme.spacing(2),
                flexDirection: 'column',
            }}
        >
            {/* Header */}
            <Container
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Container>
                    <FormControl
                        variant="outlined"
                        style={{
                            width: 300,
                            margin: theme.spacing(2),
                        }}
                    >
                        <InputLabel id={`category-label`}>
                            Filter by Category
                        </InputLabel>
                        <Select
                            labelId={`category-label`}
                            id={`category-select`}
                            value={selectedCategories}
                            onChange={(e: SelectChangeEvent<string[]>) => {
                                handleChange(e)
                            }}
                            label="Category"
                            style={{ paddingLeft: theme.spacing(1) }}
                            multiple
                            renderValue={() => ''}
                        >
                            {loriController.categories.map((category) => (
                                <MenuItem
                                    key={`CATEGORY_SELECT__${category}`}
                                    value={category}
                                >
                                    <Checkbox
                                        checked={selectedCategories.includes(
                                            category,
                                        )}
                                    />
                                    <ListItemText primary={category} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Container>
                <Tooltip title="Add Video">
                    <IconButton onClick={handleClick}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
                <AddVideo
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    setUrl={setUrl}
                    setCategory={setCategory}
                    url={url}
                    category={category}
                    loriController={loriController}
                />
            </Container>
            {/* Table */}

            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100%',
                    maxHeight: 'calc(100vh - 325px)',
                    overflowY: 'auto',
                }}
            >
                {loriController.loadingState.videoLibrary ? (
                    <Container
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',

                            padding: theme.spacing(2),
                        }}
                    >
                        <CircularProgress />
                        <Typography>Loading Video Library...</Typography>
                    </Container>
                ) : (
                    <TableContainer style={{ maxHeight: '100%' }}>
                        <Table
                            stickyHeader
                            style={{
                                width: '100%',
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    {[
                                        {
                                            id: 'category' as OrderBy,
                                            label: 'Category',
                                        },
                                        {
                                            id: 'title' as OrderBy,
                                            label: 'Title',
                                        },
                                        {
                                            id: 'duration' as OrderBy,
                                            label: 'Duration',
                                        },
                                        {
                                            id: 'uploaded_by' as OrderBy,
                                            label: 'Uploaded By',
                                        },
                                        {
                                            id: 'added_at' as OrderBy,
                                            label: 'Added Date',
                                        },
                                        {
                                            id: 'modified_date' as OrderBy,
                                            label: 'Modified Date',
                                        },
                                    ].map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            style={{
                                                ...theme.typography.body1,
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightBold,
                                            }}
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={
                                                    orderBy === headCell.id
                                                        ? order
                                                        : 'asc'
                                                }
                                                onClick={() =>
                                                    handleRequestSort(
                                                        headCell.id,
                                                    )
                                                }
                                            >
                                                {headCell.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                    <TableCell
                                        style={{
                                            ...theme.typography.body1,
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                        }}
                                    >
                                        Details
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            ...theme.typography.body1,
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                        }}
                                    >
                                        Remove
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[...filteredVideoLibrary]
                                    .sort((a, b) =>
                                        compareValues(a, b, orderBy),
                                    )
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage,
                                    )
                                    .map((video) => (
                                        <TableRow key={video.id}>
                                            <TableCell>
                                                {video.category}
                                            </TableCell>
                                            <TableCell>{video.title}</TableCell>
                                            <TableCell>
                                                {formatDuration(video.duration)}
                                            </TableCell>
                                            <TableCell>
                                                {video.uploaded_by}
                                            </TableCell>
                                            <TableCell>
                                                {video.added_at}
                                            </TableCell>
                                            <TableCell>
                                                {video.modified_date}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => {
                                                        setVideoDetailsModalOpen(
                                                            true,
                                                        )
                                                        setSelectedVideo(video)
                                                    }}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={(event) => {
                                                        setRemoveAnchorEl(
                                                            event.currentTarget,
                                                        )
                                                        setRemoveVideoPopoverOpen(
                                                            true,
                                                        )
                                                        setSelectedVideo(video)
                                                    }}
                                                >
                                                    <DeleteForeverIcon color="error" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Container>
            {/* Footer */}
            <Container
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    maxHeight: '100px',
                }}
            >
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredVideoLibrary.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Container>
            <VideoDetailsModal
                open={VideoDetailsModalOpen}
                onClose={() => setVideoDetailsModalOpen(false)}
                video={selectedVideo}
                setSelectedVideo={setSelectedVideo}
            />
            <RemoveVideoPopover
                open={removeVideoPopoverOpen}
                onClose={() => {
                    setRemoveVideoPopoverOpen(false)
                    setRemoveAnchorEl(null)
                }}
                video={selectedVideo}
                anchorEl={removeAnchorEl}
                theme={theme}
                removeVideo={loriController.removeVideo}
            />
        </Container>
    )
}

const RemoveVideoPopover = ({
    open,
    onClose,
    video,
    anchorEl,
    theme,
    removeVideo,
}: {
    theme: Theme
    open: boolean
    onClose: () => void
    video: HelpVideo | null
    anchorEl: HTMLElement | null
    removeVideo: (id: number) => Promise<void>
}) => {
    return (
        <Popover
            open={open}
            onClose={onClose}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Container
                style={{
                    padding: theme.spacing(2),
                    minWidth: 250,
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="h6">Are you sure?</Typography>
                <Typography style={{ marginTop: 8, marginBottom: 16 }}>
                    Remove {video?.title}
                </Typography>
                <Container style={{ justifyContent: 'flex-end', gap: 8 }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            if (video?.id) {
                                removeVideo(video.id)
                                onClose()
                            }
                        }}
                    >
                        Yes
                    </Button>
                    <Button variant="outlined" onClick={onClose}>
                        Cancel
                    </Button>
                </Container>
            </Container>
        </Popover>
    )
}
