import React from 'react'
import { Container } from '../../../../../../components/Container'
import {
    makeStyles,
    Theme,
    Typography,
    Accordion,
    AccordionSummary,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Task } from '../../../types'
import { LoriController } from '../../../../../../hooks/useLori'

import {
    AGENT_DRAWER_WIDTH,
    LORI_CHANNEL_HEADER_HEIGHT,
    WALKTHROUGH_PANEL_HEIGHT,
} from '../../../../../constants'
import { TaskGroup } from './TaskGroup'

const useStyles = makeStyles((theme: Theme) => ({
    WalkThroughPanel: {
        position: 'absolute',
        padding: theme.spacing(1),
        top: LORI_CHANNEL_HEADER_HEIGHT,
        left: 0,
        zIndex: theme.zIndex.drawer + 3,
        width: AGENT_DRAWER_WIDTH,
        height: WALKTHROUGH_PANEL_HEIGHT,
        backgroundColor: theme.palette.background.paper,
        borderTop: `2px solid ${theme.palette.divider}`,
        transform: 'translateY(-100%)',
        transition: 'transform 0.9s cubic-bezier(0.4, 0, 0.2, 1)',
        willChange: 'transform',
        visibility: 'hidden',
        flexDirection: 'column',
        maxHeight: WALKTHROUGH_PANEL_HEIGHT - 60,
    },
    Open: {
        transform: 'translateY(0)',
        visibility: 'visible',
    },
    checklist: {
        width: '100%',
        overflowY: 'scroll',
        maxHeight: WALKTHROUGH_PANEL_HEIGHT - 70,
    },
    listItem: {
        alignItems: 'center',
        marginLeft: theme.spacing(1.5),
        marginTop: 0,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    menuItem: {
        height: 36,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '& span': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
    accordionSummary: {
        minHeight: '32px !important',
        height: '32px',
        '& .MuiAccordionSummary-content': {
            margin: '0 !important',
        },
        padding: '0 12px',
    },
}))

interface Props {
    LoriController: LoriController
    theme: Theme
    setSelectedChannel: (channel: LoriChannel) => void
}

export const ProjectPlan = ({
    LoriController,
    theme,
    setSelectedChannel,
}: Props) => {
    const projectPlan = LoriController.projectPlan
    if (!projectPlan || !projectPlan.tasks) return null

    console.log('projectPlan', projectPlan)

    const classes = useStyles(theme)

    const [checked, setChecked] = React.useState<number[]>(
        projectPlan.tasks
            .filter((task) => task.completed)
            .map((task) => task.id),
    )
    const [expanded, setExpanded] = React.useState<string | false>('sixty')

    const formatDeadline = (startDate: string, daysPrior: number): string => {
        const deadline = new Date(startDate)
        deadline.setDate(deadline.getDate() - daysPrior)
        return deadline.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
        })
    }

    const handleToggle = (taskId: number) => () => {
        const currentIndex = checked.indexOf(taskId)

        const newChecked = [...checked]

        if (currentIndex === -1) {
            newChecked.push(taskId)
            LoriController.updateTask(taskId, true)
        } else {
            newChecked.splice(currentIndex, 1)
            LoriController.updateTask(taskId, false)
        }

        setChecked(newChecked)
    }

    const handleTaskClick = (taskName: string) => {
        LoriController.handleSendMessage(`How do I ${taskName}`)
        setSelectedChannel('lori')
    }

    const groupedTasks: Record<'sixty' | 'thirty' | 'fourteen', Task[]> = {
        sixty: projectPlan.tasks.filter((t) => t.complete_days_prior === 60),
        thirty: projectPlan.tasks.filter((t) => t.complete_days_prior === 30),
        fourteen: projectPlan.tasks.filter((t) => t.complete_days_prior === 14),
    }

    const getCompletedTasksCount = (tasks: Task[]) => {
        return tasks.filter((task) => checked.includes(task.id)).length
    }

    const handleAccordionChange = (panel: string) => (
        event: React.ChangeEvent<{}>,
        isExpanded: boolean,
    ) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <Container
            style={{
                flexDirection: 'column',
                width: AGENT_DRAWER_WIDTH,
                height: '100%',
            }}
        >
            <Container
                style={{
                    display: 'flex',
                    paddingTop: theme.spacing(1),
                    paddingLeft: theme.spacing(1),
                    flexDirection: 'column',
                }}
            >
                <Typography
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                        alignSelf: 'center',
                    }}
                >
                    {projectPlan.project.name} Project Plan
                </Typography>
            </Container>
            {/* Task List */}
            <Container
                style={{
                    flexDirection: 'column',
                }}
            >
                <Accordion
                    expanded={expanded === 'sixty'}
                    onChange={handleAccordionChange('sixty')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        className={classes.accordionSummary}
                    >
                        <Typography
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {getCompletedTasksCount(groupedTasks.sixty)}/
                            {groupedTasks.sixty.length} - Due{' '}
                            {formatDeadline(projectPlan.project.start_date, 60)}
                        </Typography>
                    </AccordionSummary>
                    {groupedTasks.sixty.length > 0 && (
                        <TaskGroup
                            tasks={groupedTasks.sixty}
                            checked={checked}
                            handleToggle={handleToggle}
                            handleTaskClick={handleTaskClick}
                            theme={theme}
                        />
                    )}
                </Accordion>
                <Accordion
                    expanded={expanded === 'thirty'}
                    onChange={handleAccordionChange('thirty')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        className={classes.accordionSummary}
                    >
                        <Typography
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {getCompletedTasksCount(groupedTasks.thirty)}/
                            {groupedTasks.thirty.length} - Due{' '}
                            {formatDeadline(projectPlan.project.start_date, 30)}
                        </Typography>
                    </AccordionSummary>
                    {groupedTasks.thirty.length > 0 && (
                        <TaskGroup
                            tasks={groupedTasks.thirty}
                            checked={checked}
                            handleToggle={handleToggle}
                            handleTaskClick={handleTaskClick}
                            theme={theme}
                        />
                    )}
                </Accordion>
                <Accordion
                    expanded={expanded === 'fourteen'}
                    onChange={handleAccordionChange('fourteen')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        className={classes.accordionSummary}
                    >
                        <Typography
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {getCompletedTasksCount(groupedTasks.fourteen)}/
                            {groupedTasks.fourteen.length} - Due{' '}
                            {formatDeadline(projectPlan.project.start_date, 14)}
                        </Typography>
                    </AccordionSummary>
                    {groupedTasks.fourteen.length > 0 && (
                        <TaskGroup
                            tasks={groupedTasks.fourteen}
                            checked={checked}
                            handleToggle={handleToggle}
                            handleTaskClick={handleTaskClick}
                            theme={theme}
                        />
                    )}
                </Accordion>
            </Container>
        </Container>
    )
}
