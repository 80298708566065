import React, { useEffect, useRef, useState } from 'react'
import { Container } from '../../../../../components/Container'
import {
    CircularProgress,
    IconButton,
    TextField,
    Theme,
    Tooltip,
} from '@material-ui/core'
import StarOutlineIcon from '@material-ui/icons/StarOutline'
import StarIcon from '@material-ui/icons/Star'
import AddIcon from '@material-ui/icons/Add'
import SendIcon from '@material-ui/icons/Send'
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk'

import { TypingIndicator } from './TypingIndicator'
import { useUser } from '../../../../../hooks/useUser'
import {
    AGENT_DRAWER_WIDTH,
    LORI_CHANNEL_CONTENT_HEIGHT,
    LORI_CHANNEL_HEADER_HEIGHT,
    WALKTHROUGH_PANEL_HEIGHT,
} from '../../../../constants'
import { LoriController } from '../../../../../hooks/useLori'
import { LoriText } from '../../Icons/LoriText'
import { ChatMessage } from './ChatMessage'

interface Props {
    theme: Theme
    LoriController: LoriController
}

export const LoriContent = ({ theme, LoriController }: Props) => {
    const [userInput, setUserInput] = useState('')
    const { workspaceUser } = useUser()
    const userAvatar = workspaceUser?.profile_picture || null

    // Scroll to bottom of chat history
    const messageContainerRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop =
                messageContainerRef.current.scrollHeight
        }
    }, [LoriController.activeConversation?.chat_messages])

    const handleSendMessage = (
        message: string,
        displayMessage: boolean = true,
    ) => {
        if (message.trim()) {
            LoriController.socket?.send(JSON.stringify({ message: message }))
            if (displayMessage && LoriController.activeConversation) {
                LoriController.setActiveConversation((prevConversation) => {
                    if (!prevConversation) return null
                    return {
                        ...prevConversation,
                        chat_messages: [
                            ...prevConversation.chat_messages,
                            {
                                id: null,
                                role: 'user',
                                content_type: 'text',
                                function_call: null,
                                message_items: [
                                    {
                                        key: 'message',
                                        value: message,
                                    },
                                ],
                            },
                        ],
                    }
                })
                setUserInput('')
                LoriController.setThinking(true)
            }
        }
    }

    return (
        <Container
            style={{
                flexDirection: 'column',
                width: AGENT_DRAWER_WIDTH,
            }}
        >
            {/* Header */}
            <Container
                style={{
                    height: LORI_CHANNEL_HEADER_HEIGHT,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    alignItems: 'center',
                    padding: theme.spacing(1),
                    flexDirection: 'row',
                    zIndex: theme.zIndex.drawer + 5,
                    position: 'relative',
                    backgroundColor: theme.palette.background.paper,
                }}
            >
                {/* title container */}
                <Container
                    style={{
                        flex: 1,
                    }}
                >
                    <LoriText width={24} height={24} color="#000" />
                </Container>
                {/* Pin Conversation */}
                <Tooltip title="Pin Conversation">
                    <IconButton
                        style={{
                            borderRadius: '50%',
                            border: `1px solid ${theme.palette.grey[300]}`,
                            padding: '6px',
                            marginLeft: theme.spacing(1),
                            backgroundColor: theme.palette.background.paper,
                            transition: 'background-color 0.3s ease',
                        }}
                        onClick={() => {
                            LoriController.setActiveConversation(
                                (prevConversation) => {
                                    if (!prevConversation) return null
                                    return {
                                        ...prevConversation,
                                        pinned: !prevConversation.pinned,
                                    }
                                },
                            )
                        }}
                        onMouseOver={(e) => {
                            e.currentTarget.style.backgroundColor =
                                theme.palette.grey[400]
                        }}
                        onMouseOut={(e) => {
                            e.currentTarget.style.backgroundColor =
                                theme.palette.background.paper
                        }}
                    >
                        {LoriController.activeConversation?.pinned ? (
                            <StarIcon htmlColor="#f2d70c" />
                        ) : (
                            <StarOutlineIcon />
                        )}
                    </IconButton>
                </Tooltip>

                {/* New Conversation */}
                <Tooltip title="New Conversation">
                    <IconButton
                        style={{
                            borderRadius: '50%',
                            border: `1px solid ${theme.palette.grey[300]}`,
                            padding: '6px',
                            marginLeft: theme.spacing(1),
                            backgroundColor: theme.palette.background.paper,
                            transition: 'background-color 0.3s ease',
                        }}
                        onClick={() => {
                            LoriController.createConversation()
                        }}
                        onMouseOver={(e) => {
                            e.currentTarget.style.backgroundColor =
                                theme.palette.grey[400]
                        }}
                        onMouseOut={(e) => {
                            e.currentTarget.style.backgroundColor =
                                theme.palette.background.paper
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </Container>

            {/* Chat History */}

            <Container
                ref={messageContainerRef}
                style={{
                    flex: 1,
                    padding: theme.spacing(1),
                    top: 0,
                    maxHeight: LORI_CHANNEL_CONTENT_HEIGHT,
                    paddingBottom: theme.spacing(1),
                    flexDirection: 'column',
                    overflowY: 'auto',
                    position: 'relative',
                }}
            >
                {LoriController.activeConversation?.chat_messages ? (
                    LoriController.activeConversation?.chat_messages.map(
                        (message, index) => {
                            return (
                                <ChatMessage
                                    key={index}
                                    message={message}
                                    theme={theme}
                                    loriController={LoriController}
                                />
                            )
                        },
                    )
                ) : (
                    <CircularProgress />
                )}
                {LoriController.thinking && <TypingIndicator theme={theme} />}
            </Container>

            {/* Input */}
            <Container
                style={{
                    borderTop: `1px solid ${theme.palette.divider}`,
                    padding: theme.spacing(1),
                    alignItems: 'center',
                    bottom: 0,
                    position: 'absolute',
                    width: '100%',
                }}
            >
                <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Ask about a notification"
                    style={{
                        marginRight: theme.spacing(1),
                    }}
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault()
                            handleSendMessage(userInput)
                        }
                    }}
                />

                <IconButton
                    onClick={() => {
                        handleSendMessage(userInput)
                        setUserInput('')
                    }}
                >
                    <SendIcon htmlColor={theme.palette.text.secondary} />
                </IconButton>
            </Container>
        </Container>
    )
}
