import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'

import { Container } from '../../components'
import { useParams } from 'react-router-dom'
import { CircularProgress, Tab, Tabs, useTheme } from '@material-ui/core'

import { useLeaseAudit } from '../../hooks/documentAudit'
import { AuditPage } from './AuditPage/AuditPage'
import { AnalysisPage } from './AnalysisPage'
import { isAccountManager } from '../../helpers'
import { useUser } from '../../hooks'
import { WaitingForApprovalPage, StatusPage } from './RequestFlow'
import { AdminPage } from './AdminFlow/AdminPage'

export const LeaseAuditRoot = () => {
    const { id } = useParams<{ id?: string }>()

    const [tab, setTab] = useState<number>(0)

    const { workspaceUser } = useUser()

    const theme = useTheme()

    const leaseAuditController = useLeaseAudit()
    const superUser = isAccountManager(workspaceUser)

    useEffect(() => {
        if (id) {
            const numId = Number(id)
            leaseAuditController.fetchLeaseAudit(numId)
        }
    }, [id])

    const tabs = [
        <Tab key={0} label="Audit" />,
        <Tab key={1} label="Analysis" />,
    ]

    const carouselItems = [
        <AuditPage
            key={0}
            theme={theme}
            isActive={tab === 0}
            height={bodyH}
            leaseAuditController={leaseAuditController}
        />,
        <AnalysisPage
            key={1}
            theme={theme}
            height={bodyH}
            leaseAuditController={leaseAuditController}
            isActive={tab === 1}
        />,
    ]

    const leaseAudit = leaseAuditController.leaseAudit

    if (leaseAudit === null || leaseAudit === undefined) {
        console.log('leaseAudit is null or undefined', leaseAudit)
        return <CircularProgress />
    }

    const status = leaseAudit.status

    if (superUser) {
        return (
            <AdminPage
                leaseAuditController={leaseAuditController}
                theme={theme}
            />
        )
    }

    if (status === 'WAITING_FOR_APPROVAL') {
        return (
            <WaitingForApprovalPage
                leaseAuditController={leaseAuditController}
                theme={theme}
            />
        )
    }

    if (status === 'COMPLETED') {
        return (
            <Container
                style={{
                    flex: 1,
                    height: 'calc(100vh - 104px)',
                    flexDirection: 'column',
                }}
            >
                {/* Header */}
                <Container
                    style={{
                        height: headerH,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Tabs value={tab} onChange={(e, v) => setTab(v)}>
                        {tabs}
                    </Tabs>

                    <div style={{ flex: 1 }} />
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.text.primary,
                        }}
                    >
                        {leaseAuditController.leaseAudit?.name}
                    </span>
                </Container>

                {/* Body */}
                <Carousel
                    swipeable={false}
                    showThumbs={false}
                    showArrows={false}
                    showIndicators={false}
                    showStatus={false}
                    selectedItem={tab}
                >
                    {carouselItems}
                </Carousel>

                {/* Footer */}
            </Container>
        )
    }

    let primaryText = ''
    let secondaryText = ''

    if (status === 'REQUESTED') {
        primaryText = 'We have received your request'
        secondaryText =
            'We will notify you when we have generated a quote for your lease audit.'
    }

    if (status === 'SETUP') {
        primaryText = 'We have received your request'
        secondaryText =
            'We will notify you when we have generated a quote for your lease audit.'
    }

    if (status === 'RUNNING_PIPELINE' || status === 'APPROVED') {
        primaryText = 'Processing'
        secondaryText =
            'We are processing your lease audit. This will take a few minutes.'
    }

    if (status === 'REJECTED') {
        primaryText = 'Rejected'
        secondaryText =
            'You rejected the quote for your lease audit.  Please submit a new request if you would like to proceed.'
    }

    return (
        <StatusPage
            leaseAuditController={leaseAuditController}
            theme={theme}
            primaryText={primaryText}
            secondaryText={secondaryText}
        />
    )
}

const containerH = window.innerHeight - 104

const headerH = 60
const bodyH = containerH - headerH
