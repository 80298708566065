import React, { useEffect } from 'react'
import { Theme, Typography, makeStyles } from '@material-ui/core'
import { Container } from '../../../../../components/Container'
import { LoriController } from '../../../../../hooks/useLori'
import { AGENT_DRAWER_WIDTH } from '../../../../constants'
import { ProjectPlan } from './ProjectPlan/ProjectPlan'

const useStyles = makeStyles((theme: Theme) => ({
    listItem: {
        alignItems: 'center',
        marginLeft: theme.spacing(1.5),
        marginTop: 0,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))

interface Props {
    LoriController: LoriController
    theme: Theme
    setSelectedChannel: (channel: LoriChannel) => void
}

export const WalkThroughChannelContent = ({
    LoriController,
    theme,
    setSelectedChannel,
}: Props) => {
    const classes = useStyles(theme)
    return (
        <Container
            style={{
                flexDirection: 'column',
                width: AGENT_DRAWER_WIDTH,
                height: '100%',
            }}
        >
            {/* project plan */}
            {LoriController.projectPlan ? (
                <ProjectPlan
                    LoriController={LoriController}
                    theme={theme}
                    setSelectedChannel={setSelectedChannel}
                />
            ) : (
                <Container
                    style={{
                        marginLeft: theme.spacing(2),
                        marginTop: theme.spacing(2),
                    }}
                >
                    <Typography variant="h6">
                        You do not have a project plan yet. Ask Lori to create
                        one for you.
                    </Typography>
                </Container>
            )}
        </Container>
    )
}
