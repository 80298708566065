import React from 'react'

interface LoriIconProps {
    width?: number | string
    height?: number | string
    color?: string
}

export const LoriIcon = (props: LoriIconProps) => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || '24'}
            height={props.height || '24'}
            viewBox="0 0 275 275"
            preserveAspectRatio="xMidYMid meet"
        >
            <g
                transform="translate(-100.000000,400.000000) scale(0.100000,-0.100000)"
                fill={props.color || '#000000'}
                stroke="none"
            >
                <path
                    d="M1714 3863 c-11 -17 -96 -166 -377 -653 -385 -668 -417 -727 -449
-820 -32 -92 -33 -100 -33 -260 0 -164 0 -166 35 -265 105 -296 321 -500 613
-578 81 -22 88 -22 937 -22 l854 0 178 310 c666 1160 652 1134 678 1298 16 95
8 266 -16 357 -86 334 -373 592 -712 640 -46 7 -193 10 -367 8 l-289 -3 -43
-73 c-198 -336 -340 -594 -330 -597 7 -3 230 -6 497 -7 l485 -3 34 -25 c43
-32 81 -108 81 -160 0 -49 -5 -58 -178 -359 -466 -810 -563 -977 -621 -1067
l-49 -77 -104 209 -104 209 -385 5 c-426 6 -415 4 -477 74 -37 42 -56 104 -47
156 6 38 20 63 290 530 188 327 323 560 509 885 76 132 147 255 158 273 l20
32 -389 0 c-359 0 -389 -1 -399 -17z"
                />
                <path
                    d="M2068 2651 c-29 -26 -33 -35 -33 -81 0 -46 4 -55 33 -81 47 -43 102
-41 148 5 37 37 45 82 22 131 -27 60 -117 73 -170 26z"
                />
                <path
                    d="M2423 2660 c-94 -57 -44 -200 70 -200 70 0 120 75 97 145 -23 71
-100 96 -167 55z"
                />
                <path
                    d="M2758 2651 c-29 -26 -33 -35 -33 -81 0 -46 4 -55 33 -81 47 -43 102
-41 148 5 37 37 45 82 22 131 -27 60 -117 73 -170 26z"
                />
            </g>
        </svg>
    )
}
