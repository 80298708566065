import React, { useEffect } from 'react'
import { Typography } from '@material-ui/core'

import { ChatMessageContent, NavigationResponse } from '../../../types'
import { Container } from '../../../../../../components/Container'
import { LoriController } from '../../../../../../hooks/useLori'
import { useHistory } from 'react-router-dom'

interface Props {
    message: ChatMessageContent
    loriController: LoriController
}

export const NavigateCard: React.FC<Props> = ({ message, loriController }) => {
    if (!message.function_call_response) return null
    const navigationResponse: NavigationResponse = message.function_call_response as NavigationResponse
    const history = useHistory()

    // navigate to the url if this is the initial message
    useEffect(() => {
        if (navigationResponse.initial) {
            // Small delay to ensure message is shown before navigation
            const timer = setTimeout(() => {
                history.push(navigationResponse.url)
            }, 750)

            return () => clearTimeout(timer)
        }
    }, [])

    return (
        <Container
            style={{
                flexDirection: 'column',
            }}
        >
            <Container>
                <Typography variant="body1" color="textSecondary">
                    {navigationResponse.message}
                </Typography>
            </Container>
        </Container>
    )
}
