import { useState, useEffect } from 'react'
import { axiosInstance } from '../helpers'
import { toast } from 'react-toastify'
import { string } from 'yargs'
import FileDownload from 'js-file-download'

export const useAnalyticData = () => {
    const [loading, setLoading] = useState(false)
    const [propertyGradeList, setPropertyGradeList] = useState<
        PropertyGradeResponseData[] | null
    >(null)

    const [chartData, setChartData] = useState<ChartData | null>(null)

    const [inspectionTypeGrades, setInspectionTypeGrades] = useState<
        InspectionTypeGrades[] | null
    >(null)

    const [inspectionDetailGrades, setInspectionDetailGrades] = useState<
        InspectionDetailGrades[] | null
    >(null)

    const getPropertyGradeList = (params?: {
        start_date?: Date
        end_date?: Date
        organization?: number
    }) => {
        setLoading(true)
        axiosInstance
            .get('inspection/property-grade/', {
                params: params,
            })
            .then((res) => {
                setPropertyGradeList(res.data)
            })
            .catch((reason) => {
                toast.error('Sorry the network request failed.')
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const getChartData = (
        apartmentId: number,
        timeline?: { startDate?: Date; endDate?: Date },
    ) => {
        axiosInstance
            .get('inspection/trend-chart-grade/', {
                params: {
                    apartment_id: apartmentId,
                    end_date: timeline?.endDate,
                    start_date: timeline?.startDate,
                },
            })
            .then((res) => {
                setChartData(res.data)
            })
            .catch((reason) => {
                toast.error('Sorry the network request failed.')
            })
    }
    const getInspectionTypeGrades = (
        apartmentId: number,
        timeline?: { startDate?: Date; endDate?: Date },
    ) => {
        axiosInstance
            .get('inspection/inspection-type-grade/', {
                params: {
                    apartment_id: apartmentId,
                    end_date: timeline?.endDate,
                    start_date: timeline?.startDate,
                },
            })
            .then((res) => {
                setInspectionTypeGrades(res.data)
            })
            .catch((reason) => {
                toast.error('Sorry the network request failed.')
            })
    }
    const getInspectionDetailGrades = (
        apartmentId: number,
        inspectionTypeId: number,
        timeline?: { startDate?: Date; endDate?: Date },
    ) => {
        axiosInstance
            .get('inspection/inspection-detail-grade/', {
                params: {
                    apartment_id: apartmentId,
                    end_date: timeline?.endDate,
                    start_date: timeline?.startDate,
                    inspection_type_id: inspectionTypeId,
                },
            })
            .then((res) => {
                setInspectionDetailGrades(res.data)
            })
            .catch((reason) => {
                toast.error('Sorry the network request failed.')
            })
    }
    const getPropertyInspectionDetailReport = (
        request: InspectionDetailReportRequest,
    ) => {
        axiosInstance
            .get('inspection/inspection-detail-report/', {
                params: request.params,
                responseType: 'blob',
            })
            .then((response) => {
                let filename = `property_inspection_detail_report.xlsx`
                if (request.params.inspection_type_name) {
                    filename = `${request.params.inspection_type_name}_inspection_detail_report.xlsx`
                }
                FileDownload(new Blob([response.data]), filename)
            })
            .catch((e) => {
                toast.error(e.response.data.message)
            })
    }

    return {
        loading,
        propertyGradeList,
        setPropertyGradeList,
        getPropertyGradeList,
        chartData,
        getChartData,
        inspectionTypeGrades,
        setInspectionTypeGrades,
        getInspectionTypeGrades,
        inspectionDetailGrades,
        setInspectionDetailGrades,
        getInspectionDetailGrades,
        getPropertyInspectionDetailReport,
    }
}

export interface PropertyGradeResponseData {
    id: number
    apartment_id: number
    name: string
    building_score: number
    inspector_score: number
    bips: number
    trend_value: number
    last_unit_completed_date: string | null
}

export interface Coordinate {
    x: string
    y: number
}

export interface ChartData {
    id: number
    bips: Coordinate[]
    trendline: Coordinate[]
    average: Coordinate[]
}
export interface InspectionTypeGrades {
    id: number
    apartment_id: number
    inspection_type_name: string
    inspection_type_id: number
    flags: number
    potential_flags: number
    units_inspected: number
    units_requiring_inspection: number
    building_score: number
    inspector_score: number
    type_score: number
    type_weight: number
    last_unit_completed_date: string | null
}

export interface InspectionDetailGrades {
    id: number
    name: string
    end_date: string
    flags: number
    potential_flags: number
    units_inspected: number
    units_requiring_inspection: number
}

export interface InspectionDetailReportRequest {
    params: {
        apartment_id: number
        inspection_type_id?: number
        inspection_type_name?: string
        end_date?: Date
        start_date?: Date
    }
}
