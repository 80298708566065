import React, { useEffect } from 'react'
import {
    Theme,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
} from '@material-ui/core'
import { Container } from '../../../../components/Container'
import HistoryIcon from '@material-ui/icons/History'
import StarIcon from '@material-ui/icons/Star'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
import { AGENT_DRAWER_WIDTH } from '../../../constants'
import { useUser } from '../../../../hooks/useUser'
import { LoriController } from '../../../../hooks/useLori'
import { Conversation } from '../types'

// Utility function to calculate time difference
const getTimeDifference = (createdDate: string): string => {
    const now = new Date()
    const created = new Date(createdDate)
    const diffInMs = now.getTime() - created.getTime()
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60))

    if (diffInMinutes < 5) {
        return 'Just now'
    }

    if (diffInMinutes < 60) {
        return `${Math.floor(diffInMinutes / 5) * 5} minutes ago`
    }

    const diffInHours = Math.floor(diffInMinutes / 60)
    if (diffInHours < 24) {
        return `${diffInHours} hours ago`
    }

    const diffInDays = Math.floor(diffInHours / 24)
    return `${diffInDays} days ago`
}

// Add this useStyles hook
const useStyles = makeStyles((theme: Theme) => ({
    listItem: {
        '&:hover': {
            backgroundColor: theme.palette.grey[300], // Adjust this value to control the darkness
        },
    },
}))

interface Props {
    theme: Theme
    LoriController: LoriController
    setSelectedChannel: (channel: LoriChannel) => void
}

export const HistoryChannelContent = ({
    theme,
    LoriController,
    setSelectedChannel,
}: Props) => {
    const classes = useStyles()
    const { workspaceUser } = useUser()

    useEffect(() => {
        LoriController.getConversationHistory(
            workspaceUser?.active_workspace.id,
        )
    }, [])

    const conversationHistory: Conversation[] = LoriController.conversationHistory.sort(
        (a, b) => {
            return (
                new Date(b.created_date).getTime() -
                new Date(a.created_date).getTime()
            )
        },
    )

    const handleClick = (item: Conversation) => {
        LoriController.setActiveConversation(item)
        setSelectedChannel('lori')
    }

    return (
        <Container
            style={{
                flexDirection: 'column',
                width: AGENT_DRAWER_WIDTH,
                height: '100%',
            }}
        >
            {/* Piunned Conversations*/}
            <Container
                style={{
                    flexDirection: 'column',
                    overflowY: 'auto',
                }}
            >
                {/* Piunned Conversations Header */}
                <Container
                    style={{
                        height: 50,
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        alignItems: 'center',
                        padding: theme.spacing(1),
                    }}
                >
                    <StarIcon fontSize="large" htmlColor="#f2d70c" />

                    <Typography
                        variant="h6"
                        style={{
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.text.primary,
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        Pinned
                    </Typography>

                    <div style={{ flex: 1 }} />
                </Container>

                {/* Piunned Conversations */}
                <Container
                    style={{
                        flexDirection: 'column',
                        overflowY: 'auto',
                    }}
                >
                    {LoriController.pinnedConversations.length > 0 ? (
                        <List>
                            {LoriController.pinnedConversations.map((item) => (
                                <ListItem
                                    key={item.id}
                                    button
                                    className={classes.listItem}
                                    onClick={() => handleClick(item)}
                                >
                                    <ListItemIcon>
                                        <ChatBubbleOutlineIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={item.description}
                                        secondary={getTimeDifference(
                                            item.created_date,
                                        )}
                                        primaryTypographyProps={{
                                            style: {
                                                color:
                                                    theme.palette.text.primary,
                                            },
                                        }}
                                        secondaryTypographyProps={{
                                            style: {
                                                color:
                                                    theme.palette.text
                                                        .secondary,
                                            },
                                        }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <Typography
                            style={{
                                marginLeft: theme.spacing(1),
                            }}
                        >
                            No pinned conversations
                        </Typography>
                    )}
                </Container>
            </Container>

            {/* Historical Conversations */}
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    overflowY: 'auto',
                    marginTop: theme.spacing(1),
                }}
            >
                {/* History Header */}
                <Container
                    style={{
                        height: 50,
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        alignItems: 'center',
                        padding: theme.spacing(1),
                    }}
                >
                    <HistoryIcon
                        fontSize="large"
                        htmlColor={theme.palette.text.secondary}
                    />

                    <Typography
                        variant="h6"
                        style={{
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.text.primary,
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        Chat History
                    </Typography>

                    <div style={{ flex: 1 }} />
                </Container>

                {/* Chat History */}
                <Container
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                        overflowY: 'auto', // Add scrolling for overflow
                    }}
                >
                    <List>
                        {conversationHistory.map((item) => (
                            <ListItem
                                key={item.id}
                                button
                                className={classes.listItem}
                                onClick={() => handleClick(item)}
                            >
                                <ListItemIcon>
                                    <ChatBubbleOutlineIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={item.description}
                                    secondary={getTimeDifference(
                                        item.created_date,
                                    )}
                                    primaryTypographyProps={{
                                        style: {
                                            color: theme.palette.text.primary,
                                        },
                                    }}
                                    secondaryTypographyProps={{
                                        style: {
                                            color: theme.palette.text.secondary,
                                        },
                                    }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Container>
            </Container>
        </Container>
    )
}
