import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    CircularProgress,
    IconButton,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import {
    ChevronLeft,
    ChevronRight,
    FormatPaint,
    LocationOn,
    MonetizationOn,
} from '@material-ui/icons'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import {
    addWorkorderToAnalytics,
    BaseWorkorder,
    createWorkorderAnalyticDict,
    getTotalPriceExpense,
    PortfolioSchedule,
    Service,
    WorkorderStatus,
} from '../../../../models'
import {
    Container,
    EllipsisSpan,
    MessageForm,
    StyledProgressBar,
} from '../../../../components'
import { calculateDaysRemaining, toMMDDYYYY } from '../../../../helpers'
import {
    getVendorList,
    RootState,
    setPortfolioVendorAnalytics,
} from '../../../../store'
import { PortfolioScheduleAnalytics } from '../../../_PortfolioAnalytics/PortfolioScheduleAnalytics'
import { useStyles } from '../../../../styles'
import { usePopover } from '../../../../hooks/usePopover'
import { SchedulePreTurnChecklist } from '../../../Scheduler_v2/Header/SchedulePreTurnChecklist'
import { BudgetModal } from '../../../Scheduler_v2/Modals/BudgetModal'
import { PortfolioVendorAnalytics } from '../../../_PortfolioAnalytics/PortfolioVendorAnalytics'

interface Props {
    scheduleDetail: PortfolioSchedule | null
    scheduleLoading: boolean
    workorderList: BaseWorkorder[]
    workorderLoading: boolean
    serviceList: Service[]
    navigateToScheduleDetail: (id: number) => void
}

export const ProjectDetail = (props: Props) => {
    const {
        scheduleDetail,
        scheduleLoading,
        workorderList,
        workorderLoading,
        serviceList,
        navigateToScheduleDetail,
    } = props

    const [analyticDict, setAnalyticDict] = useState(
        createWorkorderAnalyticDict(),
    )

    const [openProjectNotes, setOpenProjectNotes] = useState(true)
    const [budgetModalOpen, setBudgetModalOpen] = useState(false)
    const [vendorModalOpen, setVendorModalOpen] = useState(false)

    const dispatch = useDispatch()

    const scheduleBudgetItemList = useSelector(
        (state: RootState) =>
            state.workorder.schedule.portfolioScheduleBudgetItems,
    )

    const theme = useTheme()
    const classes = useStyles()

    const popoverState = usePopover()

    const textStyle: React.CSSProperties = {
        ...theme.typography.body1,
        marginRight: theme.spacing(2),
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    }

    useEffect(() => {
        if (!scheduleLoading && !workorderLoading && scheduleDetail) {
            const mutableAnalyticDict = createWorkorderAnalyticDict()
            workorderList.forEach((wo) => {
                addWorkorderToAnalytics(mutableAnalyticDict, wo)
                const woPrice = getTotalPriceExpense(wo)

                if (wo.service_id) {
                    if (
                        !(wo.service_id in mutableAnalyticDict.serviceBudgets)
                    ) {
                        mutableAnalyticDict.serviceBudgets[wo.service_id] = {
                            serviceName: wo.service_name ?? '',
                            totalBudget: 0,
                            invoicedBudget: 0,
                        }
                    }

                    mutableAnalyticDict.forecastedBudget += woPrice
                    mutableAnalyticDict.serviceForecastedBudget += woPrice
                    mutableAnalyticDict.serviceBudgets[
                        wo.service_id
                    ].totalBudget += woPrice
                    if (wo.status === WorkorderStatus.INVOICED) {
                        mutableAnalyticDict.serviceBudgets[
                            wo.service_id
                        ].invoicedBudget += woPrice
                        mutableAnalyticDict.invoicedBudget += woPrice
                    }
                }
            })

            // for each schedule budget item, add its cost to forecasted cost
            scheduleBudgetItemList?.forEach((item) => {
                mutableAnalyticDict.forecastedBudget += item.cost
                mutableAnalyticDict.lineItemForecastedBudget += item.cost
            })
            mutableAnalyticDict.unitCount = scheduleDetail.unit_count ?? 0
            setAnalyticDict(mutableAnalyticDict)
        }
    }, [scheduleDetail, workorderList, scheduleLoading, workorderLoading])

    if (scheduleLoading || workorderLoading || !scheduleDetail) {
        return (
            <Container
                style={{
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <CircularProgress size={100} />
            </Container>
        )
    }

    const daysRemaining = calculateDaysRemaining(scheduleDetail.end_date)

    let completePercent = 0
    let approvedPercent = 0
    let invoicedPercent = 0

    if (scheduleDetail.assigned_count > 0) {
        completePercent =
            (scheduleDetail.complete_count / scheduleDetail.assigned_count) *
            100
        approvedPercent =
            (scheduleDetail.approved_count / scheduleDetail.assigned_count) *
            100
        invoicedPercent =
            (scheduleDetail.invoiced_count / scheduleDetail.assigned_count) *
            100
    }

    return (
        <Container
            style={{
                flex: 1,
            }}
        >
            <Container
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                    maxHeight: 'calc(100vh - 200px)',
                    minHeight: 'calc(100vh - 200px)',
                }}
            >
                <Container
                    style={{
                        padding: theme.spacing(2),
                        borderBottom: `1px solid ${theme.palette.divider}`,
                    }}
                >
                    {/* Project Header Section */}
                    <Container style={{ flexDirection: 'column' }}>
                        <Container style={{ alignItems: 'center' }}>
                            <Tooltip title={`Go to ${scheduleDetail.name}`}>
                                <IconButton
                                    size="small"
                                    style={{ marginRight: theme.spacing(1) }}
                                    onClick={() => {
                                        navigateToScheduleDetail(
                                            scheduleDetail.id,
                                        )
                                    }}
                                >
                                    <ExitToAppIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <span
                                style={{
                                    ...theme.typography.h6,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {scheduleDetail.name}
                            </span>
                        </Container>
                        <Container>
                            <EllipsisSpan style={textStyle}>
                                {toMMDDYYYY(
                                    new Date(scheduleDetail.start_date),
                                )}{' '}
                                -{' '}
                                {toMMDDYYYY(new Date(scheduleDetail.end_date))}
                            </EllipsisSpan>
                            <EllipsisSpan style={{ ...textStyle }}>
                                {String(daysRemaining)} days left
                            </EllipsisSpan>
                        </Container>
                    </Container>
                    <Container
                        style={{
                            flexDirection: 'row',
                            marginLeft: theme.spacing(4),
                        }}
                    >
                        <Tooltip title="Budget Analytics">
                            <IconButton
                                onClick={() => {
                                    setBudgetModalOpen(true)
                                }}
                            >
                                <MonetizationOn fontSize="default" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Vendor Analytics">
                            <IconButton
                                onClick={() => {
                                    setVendorModalOpen(true)
                                    dispatch(
                                        getVendorList({
                                            params: {
                                                portfolio_apartment:
                                                    scheduleDetail.apartment.id,
                                            },
                                        }),
                                    )
                                }}
                                style={{ marginLeft: theme.spacing(1) }}
                            >
                                <FormatPaint fontSize="default" />
                            </IconButton>
                        </Tooltip>
                    </Container>
                    <Container style={{ flex: 1 }} />
                    <Container style={{ flexDirection: 'column' }}>
                        {/* Apartment Details Section */}
                        <Container>
                            <span
                                style={{
                                    ...theme.typography.h6,
                                    fontWeight: theme.typography.fontWeightBold,
                                    marginLeft: theme.spacing(0.5),
                                }}
                            >
                                {scheduleDetail.apartment.name}
                            </span>
                        </Container>
                        <Container style={{ alignItems: 'center' }}>
                            <LocationOn />
                            <span style={{ ...theme.typography.body1 }}>
                                {scheduleDetail.apartment.address},{' '}
                                {scheduleDetail.apartment.city},{' '}
                                {scheduleDetail.apartment.state}{' '}
                                {scheduleDetail.apartment.zip_code}
                            </span>
                        </Container>
                    </Container>
                </Container>
                <Container
                    style={{
                        flexDirection: 'column',
                        padding: theme.spacing(2),
                        borderBottom: `1px solid ${theme.palette.divider}`,
                    }}
                >
                    {/* Project Analytics Section */}
                    <span
                        style={{
                            ...theme.typography.h6,
                            color: theme.palette.grey[900],
                            marginBottom: theme.spacing(1),
                        }}
                    >
                        Project Analytics
                    </span>
                    <Container style={{ flexDirection: 'column' }}>
                        {scheduleDetail.pre_turn_denominator > 0 && (
                            <Container style={{ flexDirection: 'row' }}>
                                <EllipsisSpan style={textStyle}>
                                    Checklist:{' '}
                                </EllipsisSpan>
                                <EllipsisSpan
                                    style={{
                                        ...textStyle,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                    }}
                                    onClick={(e) => {
                                        popoverState.handleOpen(e)
                                    }}
                                    className={classes.hoverGrey200}
                                >
                                    {`${scheduleDetail.pre_turn_numerator}`}/
                                    {`${scheduleDetail.pre_turn_denominator}`}
                                </EllipsisSpan>
                            </Container>
                        )}
                        <Container style={{ alignItems: 'center' }}>
                            <EllipsisSpan
                                style={{ ...textStyle, width: SPAN_WIDTH }}
                            >
                                Complete: {completePercent.toFixed(2)}%
                            </EllipsisSpan>
                            <StyledProgressBar
                                value={completePercent}
                                variant="determinate"
                                barColor={theme.palette.info.main}
                            />
                        </Container>
                        <Container style={{ alignItems: 'center' }}>
                            <EllipsisSpan
                                style={{ ...textStyle, width: SPAN_WIDTH }}
                            >
                                Approved: {approvedPercent.toFixed(2)}%
                            </EllipsisSpan>
                            <StyledProgressBar
                                value={approvedPercent}
                                variant="determinate"
                                barColor={theme.palette.secondary.main}
                            />
                        </Container>
                        <Container style={{ alignItems: 'center' }}>
                            <EllipsisSpan
                                style={{ ...textStyle, width: SPAN_WIDTH }}
                            >
                                Invoiced: {invoicedPercent.toFixed(2)}%
                            </EllipsisSpan>
                            <StyledProgressBar
                                value={invoicedPercent}
                                variant="determinate"
                                barColor={theme.palette.primary.main}
                            />
                        </Container>
                    </Container>
                    <Container style={{ marginTop: theme.spacing(1) }}>
                        <PortfolioScheduleAnalytics
                            schedule={scheduleDetail}
                            analyticDict={analyticDict}
                        />
                    </Container>
                </Container>
                <Container
                    style={{
                        flexDirection: 'column',
                        padding: theme.spacing(2),
                    }}
                >
                    {/* Inspection Analytics Section */}
                    <span
                        style={{
                            ...theme.typography.h6,
                            color: theme.palette.grey[900],
                            marginBottom: theme.spacing(1),
                        }}
                    >
                        Inspection Analytics
                    </span>
                    {scheduleDetail.inspection ? (
                        <Container style={{ flexDirection: 'column' }}>
                            <Container>
                                <Container style={{ flexDirection: 'column' }}>
                                    <span
                                        style={{
                                            ...theme.typography.h6,
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                        }}
                                    >
                                        {scheduleDetail.inspection_name}
                                    </span>
                                    <EllipsisSpan style={textStyle}>
                                        {toMMDDYYYY(
                                            new Date(
                                                scheduleDetail.inspection_start_date ??
                                                    '',
                                            ),
                                        )}{' '}
                                        -{' '}
                                        {toMMDDYYYY(
                                            new Date(
                                                scheduleDetail.inspection_end_date ??
                                                    '',
                                            ),
                                        )}
                                    </EllipsisSpan>
                                </Container>
                            </Container>
                            <Container
                                style={{ flex: 1, flexDirection: 'column' }}
                            >
                                <Container style={{ alignItems: 'center' }}>
                                    <EllipsisSpan
                                        style={{
                                            ...textStyle,
                                            width: SPAN_WIDTH,
                                        }}
                                    >
                                        Assigned:{' '}
                                        {(
                                            scheduleDetail.inspection_assigned *
                                            100
                                        ).toFixed(2)}
                                        %
                                    </EllipsisSpan>
                                    <StyledProgressBar
                                        value={
                                            scheduleDetail.inspection_assigned *
                                            100
                                        }
                                        variant="determinate"
                                        barColor={theme.palette.info.main}
                                    />
                                </Container>
                                <Container style={{ alignItems: 'center' }}>
                                    <EllipsisSpan
                                        style={{
                                            ...textStyle,
                                            width: SPAN_WIDTH,
                                        }}
                                    >
                                        Complete:{' '}
                                        {(
                                            scheduleDetail.inspection_complete *
                                            100
                                        ).toFixed(2)}
                                        %
                                    </EllipsisSpan>
                                    <StyledProgressBar
                                        value={
                                            scheduleDetail.inspection_complete *
                                            100
                                        }
                                        variant="determinate"
                                        barColor={theme.palette.secondary.main}
                                    />
                                </Container>
                                <Container style={{ alignItems: 'center' }}>
                                    <EllipsisSpan
                                        style={{
                                            ...textStyle,
                                            width: SPAN_WIDTH,
                                        }}
                                    >
                                        Handled:{' '}
                                        {(
                                            scheduleDetail.inspection_handled *
                                            100
                                        ).toFixed(2)}
                                        %
                                    </EllipsisSpan>
                                    <StyledProgressBar
                                        value={
                                            scheduleDetail.inspection_handled *
                                            100
                                        }
                                        variant="determinate"
                                        barColor={theme.palette.primary.main}
                                    />
                                </Container>
                            </Container>
                        </Container>
                    ) : (
                        <span
                            style={{
                                ...theme.typography.body1,
                                marginRight: theme.spacing(2),
                            }}
                        >
                            No Inspection
                        </span>
                    )}
                </Container>
            </Container>

            <Container
                style={{
                    width: openProjectNotes ? 400 : 50,
                    minWidth: openProjectNotes ? 200 : 50,
                    borderLeft: `1px solid ${theme.palette.divider}`,
                    flexDirection: 'column',
                    height: 'calc(100vh - 200px)',
                }}
            >
                {openProjectNotes ? (
                    <>
                        <Container
                            style={{
                                padding: theme.spacing(1),
                                alignItems: 'center',
                            }}
                        >
                            <IconButton
                                onClick={() => setOpenProjectNotes(false)}
                                size="small"
                            >
                                <ChevronRight />
                            </IconButton>

                            <span
                                style={{
                                    ...theme.typography.h5,
                                    fontWeight: theme.typography.fontWeightBold,
                                    marginLeft: theme.spacing(1),
                                }}
                            >
                                {scheduleDetail.name} - Project Notes
                            </span>
                        </Container>

                        <MessageForm
                            theme={theme}
                            channelId={scheduleDetail.channel ?? undefined}
                        />
                    </>
                ) : (
                    <Container
                        style={{
                            padding: theme.spacing(1),
                        }}
                    >
                        <Tooltip title="Open Project Notes">
                            <IconButton
                                onClick={() => setOpenProjectNotes(true)}
                                size="small"
                            >
                                <ChevronLeft />
                            </IconButton>
                        </Tooltip>
                    </Container>
                )}
            </Container>

            <SchedulePreTurnChecklist
                open={popoverState.isOpen}
                schedule={scheduleDetail}
                onClose={popoverState.handleClose}
                anchorEl={popoverState.anchorEl}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                portfolio
            />

            <BudgetModal
                open={budgetModalOpen}
                onClose={() => setBudgetModalOpen(false)}
                workorders={workorderList}
                serviceList={serviceList}
                scheduleDetail={scheduleDetail}
                portfolio
            />

            <PortfolioVendorAnalytics
                open={vendorModalOpen}
                onClose={() => {
                    setVendorModalOpen(false)
                    dispatch(setPortfolioVendorAnalytics(undefined))
                }}
                schedule={scheduleDetail}
            />
        </Container>
    )
}

const SPAN_WIDTH = '150px'
