import React from 'react'
import { Theme } from '@material-ui/core'
import { Container } from '../../../components/Container'
import { NotificationsChannelContent } from './Channels/NotificationsChannelContent'
import { HistoryChannelContent } from './Channels/HistoryChannelContent'
import { AGENT_DRAWER_WIDTH, TOP_BAR_HEIGHT } from '../../constants'
import { LoriContent } from './Channels/LoriChannelContent/LoriContent'
import { useLori } from '../../../hooks/useLori'
import { WalkThroughChannelContent } from './Channels/WalkThroughChannelContent/WalkThroughChannelContent'

interface Props {
    theme: Theme
    isOpen: boolean
    selectedChannel: LoriChannel
    setSelectedChannel: (channel: LoriChannel) => void
}

export const ChatPanel = ({
    theme,
    isOpen,
    selectedChannel,
    setSelectedChannel,
}: Props) => {
    const LoriController = useLori()

    if (!isOpen) {
        return null
    }

    return (
        <Container
            style={{
                position: 'fixed',
                height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
                width: AGENT_DRAWER_WIDTH,
                borderRadius: theme.shape.borderRadius,
                boxShadow: theme.shadows[5],
                border: `1px solid ${theme.palette.divider}`,
                backgroundColor: theme.palette.background.paper,
                overflow: 'hidden',
            }}
        >
            {/* Selected Channel Content */}
            {selectedChannel === 'lori' && (
                <LoriContent theme={theme} LoriController={LoriController} />
            )}

            {selectedChannel === 'history' && (
                <HistoryChannelContent
                    theme={theme}
                    LoriController={LoriController}
                    setSelectedChannel={setSelectedChannel}
                />
            )}

            {selectedChannel === 'walkthrough' && (
                <WalkThroughChannelContent
                    LoriController={LoriController}
                    theme={theme}
                    setSelectedChannel={setSelectedChannel}
                />
            )}

            {/* TODO: Build notifications channel */}
            {selectedChannel === 'notifications' && (
                <NotificationsChannelContent theme={theme} />
            )}
        </Container>
    )
}
