import React, { useState } from 'react'
import { Pagination } from '@material-ui/lab'
import {
    Button,
    Modal,
    Paper,
    Slide,
    TableSortLabel,
    useTheme,
} from '@material-ui/core'
import { Container } from '../../../components'
import {
    InspectionDetailGrades,
    InspectionDetailReportRequest,
    InspectionTypeGrades,
} from '../../../hooks/useAnalyticData'
import { useDateRange, usePagination } from '../../../hooks'
import { Range } from 'react-date-range'
import { DateFilter } from '../../../components/DateFilterButtons'
import { InspectionTypeGradeRow } from './InspectionTypeGradeRow'
import { InspectionDetailGradeTable } from './InspectionDetailGradeTable'
import { GetApp } from '@material-ui/icons'
import { toast } from 'react-toastify'

interface Props {
    timeline: Range
    dateFilter: DateFilter
    inspectionTypeGrades: InspectionTypeGrades[] | null
    setInspectionTypeGrades: (
        inspectionTypeGrades: InspectionTypeGrades[],
    ) => void
    inspectionDetailGrades: InspectionDetailGrades[] | null
    setInspectionDetailGrades: (
        inspectionDetailGrades: InspectionDetailGrades[],
    ) => void
    getInspectionDetailGrades: (
        apartmentId: number,
        inspection_type_id: number,
        timeline?: { startDate?: Date; endDate?: Date },
    ) => void
    getPropertyInspectionDetailReport: (
        request: InspectionDetailReportRequest,
    ) => void
    getTimeline: () =>
        | Range
        | {
              startDate: Date
              endDate: Date
          }
        | null
}

export const InspectionTypeGradeTable = (props: Props) => {
    const {
        timeline,
        dateFilter,
        inspectionTypeGrades,
        setInspectionTypeGrades,
        inspectionDetailGrades,
        setInspectionDetailGrades,
        getInspectionDetailGrades,
        getPropertyInspectionDetailReport,
        getTimeline,
    } = props

    const [
        selectedInspectionType,
        setSelectedInspectionType,
    ] = useState<InspectionTypeGrades | null>(null)

    const { page, setPage, pageData, numPages, start, end } = usePagination(
        inspectionTypeGrades ?? [],
    )

    const [orderBy, setOrderBy] = useState('')

    const { dateRange } = useDateRange('')

    const [openInspectionDetailModal, setOpenInspectionDetailModal] = useState(
        false,
    )

    const theme = useTheme()
    const headCellStyle: React.CSSProperties = {
        ...theme.typography.body1,
        fontWeight: 600,
        color: theme.palette.darkGreen.main,
        whiteSpace: 'pre-line',
        margin: theme.spacing(0, 2, 0, 1),
        justifyContent: 'right',
        flex: 1,
    }

    return (
        <Container direction="column" style={{ marginTop: theme.spacing(3) }}>
            {/* HEADERS */}
            <Container
                style={{
                    padding: theme.spacing(1, 1),
                    borderBottom: '1px solid',
                    borderColor: theme.palette.grey[400],
                }}
            >
                <Container
                    style={{
                        ...headCellStyle,
                    }}
                    onClick={() => {
                        if (inspectionTypeGrades) {
                            const newRows = [...inspectionTypeGrades]
                            if (orderBy !== TypeASC) {
                                newRows.sort((RowA, RowB) =>
                                    RowA.inspection_type_name.localeCompare(
                                        RowB.inspection_type_name,
                                    ),
                                )
                                setInspectionTypeGrades(newRows)
                                setOrderBy(TypeASC)
                            } else {
                                setInspectionTypeGrades(newRows.reverse())
                                setOrderBy(TypeDESC)
                            }
                        }
                    }}
                >
                    <TableSortLabel
                        active={orderBy === TypeASC || orderBy === TypeDESC}
                        direction={orderBy === TypeASC ? 'asc' : 'desc'}
                    >
                        Inspection <br /> Type
                    </TableSortLabel>
                </Container>
                <Container
                    style={{ ...headCellStyle }}
                    onClick={() => {
                        if (inspectionTypeGrades) {
                            const newRows = [...inspectionTypeGrades]
                            if (orderBy !== ScoreWeightASC) {
                                newRows.sort(
                                    (RowA, RowB) =>
                                        RowA.type_weight - RowB.type_weight,
                                )
                                setInspectionTypeGrades(newRows)
                                setOrderBy(ScoreWeightASC)
                            } else {
                                setInspectionTypeGrades(newRows.reverse())
                                setOrderBy(ScoreWeightDESC)
                            }
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            orderBy === ScoreWeightASC ||
                            orderBy === ScoreWeightDESC
                        }
                        direction={orderBy === ScoreWeightASC ? 'asc' : 'desc'}
                    >
                        Score <br /> Weighting
                    </TableSortLabel>
                </Container>
                <Container
                    style={{ ...headCellStyle }}
                    onClick={() => {
                        if (inspectionTypeGrades) {
                            const newRows = [...inspectionTypeGrades]
                            if (orderBy !== LastUnitCompletedDateASC) {
                                newRows.sort((RowA, RowB) => {
                                    if (
                                        !RowA.last_unit_completed_date &&
                                        !RowB.last_unit_completed_date
                                    )
                                        return 0
                                    if (!RowA.last_unit_completed_date)
                                        return -1
                                    if (!RowB.last_unit_completed_date) return 1

                                    const dateA = new Date(
                                        RowA.last_unit_completed_date,
                                    )
                                    const dateB = new Date(
                                        RowB.last_unit_completed_date,
                                    )

                                    return dateA.getTime() - dateB.getTime()
                                })
                                setInspectionTypeGrades(newRows)
                                setOrderBy(LastUnitCompletedDateASC)
                            } else {
                                setInspectionTypeGrades(newRows.reverse())
                                setOrderBy(LastUnitCompletedDateDESC)
                            }
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            orderBy === LastUnitCompletedDateASC ||
                            orderBy === LastUnitCompletedDateDESC
                        }
                        direction={
                            orderBy === LastUnitCompletedDateASC
                                ? 'asc'
                                : 'desc'
                        }
                    >
                        Last <br /> Unit <br /> Completed
                    </TableSortLabel>
                </Container>
                <Container
                    style={{ ...headCellStyle }}
                    onClick={() => {
                        if (inspectionTypeGrades) {
                            const newRows = [...inspectionTypeGrades]
                            if (orderBy !== FlagsASC) {
                                newRows.sort(
                                    (RowA, RowB) => RowA.flags - RowB.flags,
                                )
                                setInspectionTypeGrades(newRows)
                                setOrderBy(FlagsASC)
                            } else {
                                setInspectionTypeGrades(newRows.reverse())
                                setOrderBy(FlagsDESC)
                            }
                        }
                    }}
                >
                    <TableSortLabel
                        active={orderBy === FlagsASC || orderBy === FlagsDESC}
                        direction={orderBy === FlagsASC ? 'asc' : 'desc'}
                    >
                        Flags
                    </TableSortLabel>
                </Container>
                <Container
                    style={{ ...headCellStyle }}
                    onClick={() => {
                        if (inspectionTypeGrades) {
                            const newRows = [...inspectionTypeGrades]
                            if (orderBy !== PotentialFlagsASC) {
                                newRows.sort(
                                    (RowA, RowB) =>
                                        RowA.potential_flags -
                                        RowB.potential_flags,
                                )
                                setInspectionTypeGrades(newRows)
                                setOrderBy(PotentialFlagsASC)
                            } else {
                                setInspectionTypeGrades(newRows.reverse())
                                setOrderBy(PotentialFlagsDESC)
                            }
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            orderBy === PotentialFlagsASC ||
                            orderBy === PotentialFlagsDESC
                        }
                        direction={
                            orderBy === PotentialFlagsASC ? 'asc' : 'desc'
                        }
                    >
                        Potential <br /> Flags
                    </TableSortLabel>
                </Container>
                <Container
                    style={{ ...headCellStyle }}
                    onClick={() => {
                        if (inspectionTypeGrades) {
                            const newRows = [...inspectionTypeGrades]
                            if (orderBy !== UnitsInsASC) {
                                newRows.sort(
                                    (RowA, RowB) =>
                                        RowA.units_inspected -
                                        RowB.units_inspected,
                                )
                                setInspectionTypeGrades(newRows)
                                setOrderBy(UnitsInsASC)
                            } else {
                                setInspectionTypeGrades(newRows.reverse())
                                setOrderBy(UnitsInsDESC)
                            }
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            orderBy === UnitsInsASC || orderBy === UnitsInsDESC
                        }
                        direction={orderBy === UnitsInsASC ? 'asc' : 'desc'}
                    >
                        Units <br /> Inspected
                    </TableSortLabel>
                </Container>
                <Container
                    style={{ ...headCellStyle }}
                    onClick={() => {
                        if (inspectionTypeGrades) {
                            const newRows = [...inspectionTypeGrades]
                            if (orderBy !== UnitsReqInsASC) {
                                newRows.sort(
                                    (RowA, RowB) =>
                                        RowA.units_requiring_inspection -
                                        RowB.units_requiring_inspection,
                                )
                                setInspectionTypeGrades(newRows)
                                setOrderBy(UnitsReqInsASC)
                            } else {
                                setInspectionTypeGrades(newRows.reverse())
                                setOrderBy(UnitsReqInsDESC)
                            }
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            orderBy === UnitsReqInsASC ||
                            orderBy === UnitsReqInsDESC
                        }
                        direction={orderBy === UnitsReqInsASC ? 'asc' : 'desc'}
                    >
                        Units <br /> Requiring <br /> Inspection
                    </TableSortLabel>
                </Container>
                <Container
                    style={{ ...headCellStyle }}
                    onClick={() => {
                        if (inspectionTypeGrades) {
                            const newRows = [...inspectionTypeGrades]
                            if (orderBy !== BuildingScoreASC) {
                                newRows.sort(
                                    (RowA, RowB) =>
                                        RowA.building_score -
                                        RowB.building_score,
                                )
                                setInspectionTypeGrades(newRows)
                                setOrderBy(BuildingScoreASC)
                            } else {
                                setInspectionTypeGrades(newRows.reverse())
                                setOrderBy(BuidlingScoreDESC)
                            }
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            orderBy === BuildingScoreASC ||
                            orderBy === BuidlingScoreDESC
                        }
                        direction={
                            orderBy === BuildingScoreASC ? 'asc' : 'desc'
                        }
                    >
                        Building <br /> Score
                    </TableSortLabel>
                </Container>
                <Container
                    style={{ ...headCellStyle }}
                    onClick={() => {
                        if (inspectionTypeGrades) {
                            const newRows = [...inspectionTypeGrades]
                            if (orderBy !== InspectorScoreASC) {
                                newRows.sort(
                                    (RowA, RowB) =>
                                        RowA.inspector_score -
                                        RowB.inspector_score,
                                )
                                setInspectionTypeGrades(newRows)
                                setOrderBy(InspectorScoreASC)
                            } else {
                                setInspectionTypeGrades(newRows.reverse())
                                setOrderBy(InspectorScoreDESC)
                            }
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            orderBy === InspectorScoreASC ||
                            orderBy === InspectorScoreDESC
                        }
                        direction={
                            orderBy === InspectorScoreASC ? 'asc' : 'desc'
                        }
                    >
                        Inspector <br /> Score
                    </TableSortLabel>
                </Container>
                <Container
                    style={{
                        ...headCellStyle,
                        backgroundColor: theme.palette.grey[200],
                    }}
                    onClick={() => {
                        if (inspectionTypeGrades) {
                            const newRows = [...inspectionTypeGrades]
                            if (orderBy !== OverallScoreASC) {
                                newRows.sort(
                                    (RowA, RowB) =>
                                        RowA.type_score - RowB.type_score,
                                )
                                setInspectionTypeGrades(newRows)
                                setOrderBy(OverallScoreASC)
                            } else {
                                setInspectionTypeGrades(newRows.reverse())
                                setOrderBy(OverallScoreDESC)
                            }
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            orderBy === OverallScoreASC ||
                            orderBy === OverallScoreDESC
                        }
                        direction={orderBy === OverallScoreASC ? 'asc' : 'desc'}
                    >
                        Overall <br /> Score
                    </TableSortLabel>
                </Container>
            </Container>
            {/* body */}
            <Container
                style={{
                    overflowY: 'auto',
                    maxHeight: 'calc(100vh - 350px)',
                    minHeight: 'calc(100vh - 350px)',
                }}
                direction="column"
            >
                {pageData.map((inspectionTypeGrade, idx) => {
                    return (
                        <InspectionTypeGradeRow
                            dateFilter={dateFilter}
                            timeline={timeline}
                            inspectionTypeGrade={inspectionTypeGrade}
                            getInspectionDetailGrades={
                                getInspectionDetailGrades
                            }
                            setOpenInspectionDetailModal={
                                setOpenInspectionDetailModal
                            }
                            setSelectedInspectionType={
                                setSelectedInspectionType
                            }
                            selectedInspectionType={selectedInspectionType}
                            key={`INSPECTION_TYPE_GRADE_${inspectionTypeGrade.id}`}
                        />
                    )
                })}
            </Container>
            <Modal
                open={openInspectionDetailModal}
                onClose={() => {
                    setOpenInspectionDetailModal(false)
                    setSelectedInspectionType(null)
                }}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Slide direction="up" in={openInspectionDetailModal}>
                    <Paper
                        style={{
                            padding: theme.spacing(2),
                            minWidth: '900px',
                        }}
                    >
                        <Container>
                            <Container
                                style={{ ...theme.typography.h5, flex: 1 }}
                            >
                                Inspection Type:{' '}
                                {selectedInspectionType?.inspection_type_name}
                            </Container>
                            <Container style={{ alignSelf: 'flex-end' }}>
                                <Button
                                    onClick={() => {
                                        if (
                                            selectedInspectionType?.inspection_type_id
                                        ) {
                                            const dateRange = getTimeline()
                                            const request: InspectionDetailReportRequest = {
                                                params: {
                                                    apartment_id:
                                                        selectedInspectionType.apartment_id,
                                                    inspection_type_id:
                                                        selectedInspectionType.inspection_type_id,
                                                    inspection_type_name:
                                                        selectedInspectionType.inspection_type_name,
                                                },
                                            }
                                            if (dateRange) {
                                                request.params.end_date =
                                                    dateRange.endDate
                                                request.params.start_date =
                                                    dateRange.startDate
                                            }
                                            getPropertyInspectionDetailReport(
                                                request,
                                            )
                                        } else {
                                            toast.error(
                                                'No inspection type selected',
                                            )
                                        }
                                    }}
                                    style={{
                                        backgroundColor:
                                            theme.palette.primary.dark,
                                        color: 'white',
                                        textTransform: 'none',
                                        cursor: 'pointer',
                                        padding: theme.spacing(1),
                                        margin: theme.spacing(1, 2, 1, 0),
                                    }}
                                >
                                    Full Report <GetApp fontSize="small" />
                                </Button>
                            </Container>
                        </Container>
                        <Container>
                            <InspectionDetailGradeTable
                                timeline={dateRange}
                                dateFilter={dateFilter}
                                inspectionDetailGrades={inspectionDetailGrades}
                                setInspectionDetailGrades={
                                    setInspectionDetailGrades
                                }
                                setSelectedInspectionType={
                                    setSelectedInspectionType
                                }
                                selectedInspectionType={selectedInspectionType}
                            />
                        </Container>
                    </Paper>
                </Slide>
            </Modal>
            {/* footer */}
            <Container
                style={{
                    ...theme.typography.subtitle1,
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(1),
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Pagination
                    count={numPages}
                    page={page}
                    onChange={(_: unknown, newPage: number) => {
                        setPage(newPage)
                    }}
                />
                <span>
                    {start} - {end} of {inspectionTypeGrades?.length ?? 0}
                </span>
            </Container>
        </Container>
    )
}

const TypeASC = 'TypeASC'
const TypeDESC = 'TypeDESC'
const FlagsASC = 'FlagsASC'
const FlagsDESC = 'FlagsDESC'
const PotentialFlagsASC = 'PotentialFlagsASC'
const PotentialFlagsDESC = 'PotentialFlagsDESC'
const UnitsInsASC = 'UnitsInsASC'
const UnitsInsDESC = 'UnitInsDESC'
const UnitsReqInsASC = 'UnitsReqInsASC'
const UnitsReqInsDESC = 'UnitReqInsDESC'
const BuildingScoreASC = 'BuildingScoreASC'
const BuidlingScoreDESC = 'BuildingScoreDESC'
const InspectorScoreASC = 'InspectorScoreASC'
const InspectorScoreDESC = 'InspectorScoreDESC'
const OverallScoreASC = 'OverallScoreASC'
const OverallScoreDESC = 'OverallScoreDESC'
const ScoreWeightASC = 'ScoreWeightASC'
const ScoreWeightDESC = 'ScoreWeightDESC'
const LastUnitCompletedDateASC = 'LastUnitCompletedDateASC'
const LastUnitCompletedDateDESC = 'LastUnitCompletedDateDESC'
