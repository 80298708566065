import React, { useState, useEffect } from 'react'
import { Container } from '../../components/Container'
import {
    Checkbox,
    ListItemText,
    MenuItem,
    FormControl,
    Typography,
    useTheme,
    InputLabel,
    CircularProgress,
} from '@material-ui/core'
import Plot from 'react-plotly.js'
import { KPIController } from '../../hooks/useKPI'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { normalizeString } from '../../helpers/stringHelpers'
import {
    formatNumber,
    padNumberForAlignment,
} from '../../helpers/numberHelpers'

export const Users = ({ KPIController }: { KPIController: KPIController }) => {
    const theme = useTheme()
    const [plotWidth, setPlotWidth] = useState(0)
    const [plotHeight, setPlotHeight] = useState(0)
    const [selectedUser, setSelectedUser] = useState<
        'Workspace' | 'Tenant' | 'Vendor'
    >('Workspace')

    const [totalUsers, setTotalUsers] = useState(
        KPIController.users?.Workspace?.total_users || 0,
    )

    // set the plot size
    useEffect(() => {
        const updateDimensions = () => {
            // Get the container element and calculate dimensions
            const container = document.querySelector('.plot-container')
            if (container) {
                // Subtracting 20px from each dimension for padding
                setPlotWidth(container.clientWidth - 20)
                setPlotHeight(container.clientHeight - 20)
            }
        }
        // Initial calculation
        updateDimensions()

        // Add resize listener
        window.addEventListener('resize', updateDimensions)

        // Cleanup
        return () => window.removeEventListener('resize', updateDimensions)
    }, [])

    const handleChange = (e: SelectChangeEvent<string>) => {
        setSelectedUser(e.target.value as 'Workspace' | 'Tenant' | 'Vendor')
        setTotalUsers(
            KPIController.users?.[
                e.target.value as 'Workspace' | 'Tenant' | 'Vendor'
            ]?.total_users || 0,
        )
    }

    const calculateMonthlyGrowth = (monthData: {
        x: string[]
        y: number[]
    }) => {
        const growthText = new Array(monthData.x.length).fill('')

        monthData.y.forEach((value, index) => {
            // Skip the first month since we can't calculate growth for it
            if (index === 0) return

            const currentValue = value
            const prevValue = monthData.y[index - 1]

            // Calculate month-over-month growth percentage
            if (prevValue !== 0) {
                const growth = ((currentValue - prevValue) / prevValue) * 100
                growthText[index] = `${growth.toFixed(1)}%`
            }
        })

        return growthText
    }

    // Add this near the top of your component to prepare the numbers array
    const getAdditionsNumbers = () => {
        if (!KPIController.additions) return []
        return [
            selectedUser === 'Workspace'
                ? KPIController.additions.workspace.total_count
                : KPIController.additions.unit.total_count,
            selectedUser === 'Workspace'
                ? KPIController.additions.workspace.first_month_count
                : KPIController.additions.unit.first_month_count,
            selectedUser === 'Workspace'
                ? KPIController.additions.workspace.remaining_count
                : KPIController.additions.unit.remaining_count,
        ]
    }

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                height: 'calc(100vh - 200px)',
            }}
            className="plot-container"
        >
            {/* header */}
            <Container
                style={{
                    flexDirection: 'row',
                }}
            >
                {/* selector */}
                <Container>
                    <FormControl
                        variant="outlined"
                        style={{
                            width: 300,
                            margin: theme.spacing(2),
                        }}
                    >
                        <InputLabel id={`category-label`}>
                            Type of Users
                        </InputLabel>
                        <Select
                            labelId={`category-label`}
                            id={`category-select`}
                            value={selectedUser}
                            onChange={(e: SelectChangeEvent<string>) => {
                                handleChange(e)
                            }}
                            label="Type of Users"
                            style={{ paddingLeft: theme.spacing(1) }}
                            renderValue={() => selectedUser}
                        >
                            {Object.keys(KPIController.users || {}).map(
                                (category) => (
                                    <MenuItem
                                        key={`CATEGORY_SELECT__${category}`}
                                        value={normalizeString(category)}
                                    >
                                        <ListItemText
                                            primary={normalizeString(category)}
                                        />
                                    </MenuItem>
                                ),
                            )}
                        </Select>
                    </FormControl>
                </Container>
                {/* Summary Stats */}
                <Container
                    style={{
                        flexDirection: 'column',
                        marginLeft: theme.spacing(2),
                        justifyContent: 'center',
                    }}
                >
                    <Container
                        style={{
                            flexDirection: 'row',
                        }}
                    >
                        <Typography
                            style={{
                                ...theme.typography.body1,
                            }}
                        >
                            Total {selectedUser} Users:{' '}
                        </Typography>
                        <Typography
                            style={{
                                ...theme.typography.body1,
                                fontWeight: 'bold',
                            }}
                        >
                            {formatNumber(totalUsers)}
                        </Typography>
                    </Container>
                </Container>
            </Container>

            {/* plot */}
            <Container
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {KPIController.users ? (
                    selectedUser === 'Vendor' ? (
                        <Plot
                            data={[
                                {
                                    x: KPIController.users.Vendor.in_house.x,
                                    y: KPIController.users.Vendor.in_house.y,
                                    text: calculateMonthlyGrowth(
                                        KPIController.users.Vendor.in_house,
                                    ),
                                    textposition: 'outside' as const,
                                    textfont: {
                                        size: 18,
                                        color: 'black',
                                    },
                                    type: 'bar' as const,
                                    name: 'In House',
                                    hovertemplate: '$%{y:,.0f}<extra></extra>',
                                },
                                {
                                    x: KPIController.users.Vendor.contract.x,
                                    y: KPIController.users.Vendor.contract.y,
                                    text: calculateMonthlyGrowth(
                                        KPIController.users.Vendor.contract,
                                    ),
                                    textposition: 'outside' as const,
                                    textfont: {
                                        size: 18,
                                        color: 'black',
                                    },
                                    type: 'bar' as const,
                                    name: 'Contract',
                                    hovertemplate: '$%{y:,.0f}<extra></extra>',
                                },
                            ]}
                            layout={{
                                width: plotWidth,
                                height: plotHeight,
                                title: { text: 'Vendor Users By Type' },
                                margin: { t: 50, b: 50, l: 50, r: 50 },
                            }}
                            useResizeHandler={true}
                            style={{ width: '100%', height: '100%' }}
                        />
                    ) : (
                        <Plot
                            data={[
                                {
                                    type: 'bar',
                                    x: KPIController.users[selectedUser].x,
                                    y: KPIController.users[selectedUser].y,
                                    text:
                                        KPIController.users[
                                            selectedUser
                                        ]?.mom_changes?.map(
                                            (num) => `${num}%`,
                                        ) || [],
                                    textposition: 'outside',
                                    textfont: {
                                        size: 14,
                                    },
                                    marker: { color: '#6b71d1' },
                                    hovertemplate:
                                        '%{x|%B, %Y}<br>Count: %{y}<extra></extra>',
                                },
                            ]}
                            layout={{
                                width: plotWidth,
                                height: plotHeight,
                                title: {
                                    text: `Cumulative ${selectedUser} Users`,
                                },
                                margin: { t: 50, b: 50, l: 50, r: 50 },
                            }}
                            useResizeHandler={true}
                            style={{ width: '100%', height: '100%' }}
                        />
                    )
                ) : (
                    <Container
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress />
                    </Container>
                )}
            </Container>
        </Container>
    )
}
