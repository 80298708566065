import React, { useState } from 'react'
import { toast } from 'react-toastify'

import { makeStyles } from '@material-ui/core/styles'
import {
    Paper,
    Typography,
    IconButton,
    Theme,
    Tooltip,
    Badge,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@material-ui/core'
import { ChatBubbleOutline, DeleteForever, Edit } from '@material-ui/icons'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import {
    DeleteWorkorderParams,
    TransitionWorkorderParams,
    WorkorderResponse,
} from '../../../store'
import { Container, WorkorderTransitionButtons } from '../../../components'
import { WorkorderAreaCard } from '../../../components/WorkorderLib/WorkorderRow/WorkorderAreaCard'
import {
    BaseWorkorder,
    User,
    WorkorderStatus,
    WorkorderType,
} from '../../../models'
import { WorkorderInfoPopover } from '../../../components/WorkorderLib/WorkorderInfoPopover'
import { ReactComponent as UnitNotesIcon } from '../../../assets/icons/unitnotes.svg'
import { toMMDDYYYY, toUiTime } from '../../../helpers'

interface Props {
    workorder: WorkorderResponse
    theme: Theme
    user?: User
    isSelected: boolean
    organizationView?: boolean
    transitionWorkorder: (
        workorder: BaseWorkorder,
        status: WorkorderStatus,
        params?: TransitionWorkorderParams,
    ) => Promise<void>
    openMessageDrawer: () => void
    openUnitNotes: () => void
    openEditModal: () => void
    navigateToScheduleDetail: (scheduleId: number) => void
    deleteWorkorder: (
        workorder: WorkorderResponse,
        params?: DeleteWorkorderParams,
    ) => Promise<void>
}

const useStyles = makeStyles((theme) => ({
    card: {
        width: 300,
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
        maxHeight: 190,
        minHeight: 180,
        backgroundColor: (props: { isSelected: boolean }) =>
            props.isSelected
                ? theme.palette.primary.light
                : theme.palette.primary.contrastText,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        boxShadow: (props: { isSelected: boolean }) =>
            props.isSelected
                ? `0 0 10px ${theme.palette.primary.main}`
                : 'none',
    },
    iconContainer: {
        marginRight: theme.spacing(2),
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    icon: {
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: '50%',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.grey[100],
    },
    flexGrow: {
        flexGrow: 1,
    },
    badge: {
        marginRight: theme.spacing(1),
    },
    titleAndLocation: {
        width: '100%',
        marginBottom: theme.spacing(1),
    },
    truncate: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    title: {
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: 1.2,
        maxHeight: '2.4em', // Allows for two lines of text
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
    },
}))

export const DetailCard = (props: Props) => {
    const {
        workorder,
        theme,
        user,
        isSelected,
        organizationView,
        transitionWorkorder,
        openMessageDrawer,
        openUnitNotes,
        openEditModal,
        navigateToScheduleDetail,
        deleteWorkorder,
    } = props

    const [deleteOpen, setDeleteOpen] = useState(false)

    const classes = useStyles({ isSelected })

    let titleLabel = ''
    if (
        workorder.schedule_name !== null &&
        workorder.schedule_name !== undefined &&
        workorder.title !== ''
    ) {
        titleLabel = `${workorder.schedule_name} / ${workorder.title}`
    } else {
        titleLabel = workorder.schedule_name ?? workorder.title
    }

    let timeDisplay: JSX.Element | null = null
    if (
        workorder.type === WorkorderType.SERVICE_REQUEST &&
        toMMDDYYYY(new Date(workorder.start_date ?? '')) ===
            toMMDDYYYY(new Date(workorder?.end_date ?? ''))
    ) {
        const startTime = toUiTime(new Date(workorder.start_date ?? ''))
        const endTime = toUiTime(new Date(workorder.end_date ?? ''))

        timeDisplay = (
            <Container style={{ fontWeight: theme.typography.fontWeightBold }}>
                {startTime} - {endTime}
            </Container>
        )
    }

    return (
        <Paper className={classes.card}>
            <Container>
                <Container
                    className={classes.titleAndLocation}
                    style={{ flexDirection: 'column' }}
                >
                    {/* Title */}
                    <Container>
                        {workorder.schedule_id && (
                            <Tooltip title={`Go to ${workorder.schedule_name}`}>
                                <IconButton
                                    style={{
                                        marginRight: theme.spacing(1),
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        if (workorder.schedule_id) {
                                            navigateToScheduleDetail(
                                                workorder.schedule_id,
                                            )
                                        }
                                    }}
                                >
                                    <ExitToAppIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip title={titleLabel}>
                            <Typography
                                variant="subtitle1"
                                className={classes.title}
                            >
                                {titleLabel}
                            </Typography>
                        </Tooltip>
                    </Container>

                    <Container
                        style={{
                            flexDirection: 'column',
                        }}
                    >
                        <Typography
                            variant="body2"
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                            className={classes.truncate}
                        >
                            {workorder.unit_config_name ?? ''}
                        </Typography>

                        {timeDisplay}
                    </Container>

                    <Container style={{ flex: 1 }} />
                    <Container
                        style={{
                            alignItems: 'center',
                            marginTop: theme.spacing(1),
                        }}
                    >
                        <Container style={{ marginRight: theme.spacing(1) }}>
                            <Tooltip title="Unit Notes">
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        openUnitNotes()
                                    }}
                                    size="small"
                                >
                                    <Badge
                                        badgeContent={
                                            workorder.unit_notes_msg_count
                                        }
                                        color={
                                            (workorder.unread_unit_notes ?? 0) >
                                            0
                                                ? 'secondary'
                                                : 'primary'
                                        }
                                    >
                                        <UnitNotesIcon />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                        </Container>
                        <Container style={{ marginRight: theme.spacing(1) }}>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation()
                                    openMessageDrawer()
                                }}
                                size="small"
                            >
                                <Badge
                                    badgeContent={workorder.message_count}
                                    color={
                                        (workorder.unread_count ?? 0) > 0
                                            ? 'primary'
                                            : 'secondary'
                                    }
                                >
                                    <ChatBubbleOutline />
                                </Badge>
                            </IconButton>
                        </Container>
                        {!workorder.price_locked && (
                            <Container
                                style={{ marginRight: theme.spacing(1) }}
                            >
                                <Tooltip title="Edit">
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            openEditModal()
                                        }}
                                        size="small"
                                    >
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                            </Container>
                        )}

                        {!workorder.price_locked && (
                            <Container
                                style={{ marginRight: theme.spacing(1) }}
                            >
                                <Tooltip title="Delete">
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setDeleteOpen(true)
                                        }}
                                        size="small"
                                    >
                                        <DeleteForever />
                                    </IconButton>
                                </Tooltip>
                            </Container>
                        )}

                        {workorder.info && (
                            <Container
                                style={{ marginRight: theme.spacing(1) }}
                            >
                                <WorkorderInfoPopover
                                    workorder={workorder}
                                    theme={theme}
                                />
                            </Container>
                        )}
                    </Container>
                </Container>

                <Container style={{ flexDirection: 'column' }}>
                    <Container>
                        <WorkorderAreaCard
                            workorder={workorder}
                            theme={theme}
                        />
                    </Container>
                </Container>
            </Container>

            <Container
                style={{
                    marginTop: theme.spacing(1),
                    borderTop: `1px solid ${theme.palette.grey[300]}`,
                }}
            >
                <WorkorderTransitionButtons
                    workorder={workorder}
                    user={user}
                    style={{
                        margin: 0,
                        justifyContent: 'flex-start',
                        marginTop: theme.spacing(1),
                    }}
                    handleTransition={(e, workorder, status) => {
                        e.stopPropagation()

                        const params: TransitionWorkorderParams = {
                            apartment_id: workorder.apartment_id,
                        }

                        transitionWorkorder(
                            workorder,
                            status,
                            params,
                        ).then(() =>
                            toast.success(
                                'Workorder transitioned successfully',
                            ),
                        )
                    }}
                />
            </Container>

            <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
                <DialogTitle id="alert-dialog-title">
                    Delete Workorder
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this Workorder?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => setDeleteOpen(false)}
                    >
                        Cancel
                    </Button>
                    <div style={{ flex: 1 }} />
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            let params:
                                | DeleteWorkorderParams
                                | undefined = undefined
                            if (organizationView) {
                                params = {
                                    apartment_id: workorder.apartment_id,
                                }
                            }
                            deleteWorkorder(workorder, params).then(() => {
                                toast.success('Workorder deleted successfully')
                                setDeleteOpen(false)
                            })
                        }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    )
}
