import React from 'react'
import { Container } from '../../../../../../components/Container'
import { Tooltip, Typography, useTheme } from '@material-ui/core'
import { ChatMessageContent, HelpVideo, HowToResponse } from '../../../types'
import { LoriController } from '../../../../../../hooks/useLori'

interface HowToVideoProps {
    message: ChatMessageContent
    loriController: LoriController
}

export const HowToVideoCard = ({
    message,
    loriController,
}: HowToVideoProps) => {
    if (!message.function_call_response) return null
    const how_to_video_response: HowToResponse[] = message.function_call_response as HowToResponse[]

    const helpVideos = React.useMemo(() => {
        if (!how_to_video_response || !Array.isArray(how_to_video_response)) {
            return []
        }

        // if coming from db the id will be a string, need to convert to number
        const videoIds = how_to_video_response.map((response) =>
            typeof response.id === 'string'
                ? parseInt(response.id)
                : response.id,
        )

        return loriController.videoLibrary.filter((video: HelpVideo) =>
            videoIds.some((id) => id === video.id),
        )
    }, [how_to_video_response, loriController.videoLibrary])

    return (
        <Container
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Container
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#fff',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    borderRadius: 8,
                }}
            >
                {helpVideos?.map((video) => (
                    <VideoCard
                        key={`HELP_VIDEO_${video.id}`}
                        video={video}
                        LoriController={loriController}
                    />
                ))}
            </Container>
        </Container>
    )
}

interface VideoCardProps {
    video: HelpVideo
    LoriController: LoriController
}

const VideoCard = ({ video, LoriController }: VideoCardProps) => {
    const theme = useTheme()
    const formatDuration = (seconds: number): string => {
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = seconds % 60
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
    }
    const handleVideoClick = () => {
        window.open(video.video_url, '_blank')
        LoriController.addVideoView(video.id)
    }
    return (
        <Container
            style={{
                display: 'flex',
                flexDirection: 'column',
                border: `1px solid ${theme.palette.divider}`,
            }}
        >
            {/* Main video */}
            <Container
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: theme.spacing(2),
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    cursor: 'pointer',
                    // backgroundColor: 'blue',
                }}
                onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = '#f0f0f0'
                    e.currentTarget.style.transform = 'translateY(-1px)'
                    e.currentTarget.style.boxShadow =
                        '0 2px 4px rgba(0,0,0,0.05)'
                }}
                onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = '#fafafa'
                    e.currentTarget.style.transform = 'translateY(0)'
                    e.currentTarget.style.boxShadow = 'none'
                }}
                onClick={handleVideoClick}
            >
                {/* Thumbnail */}
                <div
                    style={{
                        width: 120,
                        minWidth: 120,
                        height: 67, // 16:9 aspect ratio
                        marginRight: theme.spacing(2),
                        position: 'relative',
                    }}
                >
                    <img
                        src={video.thumbnail}
                        alt={video.title}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: theme.shape.borderRadius,
                        }}
                    />
                    {/* Duration overlay */}
                    <Typography
                        variant="caption"
                        style={{
                            position: 'absolute',
                            bottom: 4,
                            right: 4,
                            backgroundColor: 'rgba(0, 0, 0, 0.75)',
                            color: 'white',
                            padding: '2px 4px',
                            borderRadius: 2,
                        }}
                    >
                        {formatDuration(video.duration)}
                    </Typography>
                </div>

                {/* Text content */}
                <Tooltip
                    title={
                        <div>
                            <Typography
                                style={{
                                    ...theme.typography.body2,
                                }}
                            >
                                Key Topics:
                            </Typography>
                            <ul style={{ margin: '4px 0', paddingLeft: 16 }}>
                                {video.key_topics.map((topic, index) => (
                                    <li
                                        style={{
                                            ...theme.typography.body2,
                                            marginBottom: theme.spacing(0.5),
                                        }}
                                        key={index}
                                    >
                                        {topic}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    }
                >
                    <div>
                        <Typography
                            variant="subtitle1"
                            style={{
                                fontWeight: 500,
                                marginBottom: theme.spacing(0.5),
                            }}
                        >
                            {video.title}
                        </Typography>
                    </div>
                </Tooltip>
            </Container>
        </Container>
    )
}
