import React, { useState } from 'react'
import {
    IconButton,
    TextField,
    Theme,
    Typography,
    Avatar,
} from '@material-ui/core'
import { Container } from '../../../../components/Container'

import NotificationsIcon from '@material-ui/icons/Notifications'

import SendIcon from '@material-ui/icons/Send'
import { useWsChannel } from '../../../../hooks/useWsChannel'
import { useUser } from '../../../../hooks/useUser'
import { useChatBot } from '../../../../hooks/useChatBot'

interface Props {
    theme: Theme
}

export const NotificationsChannelContent = ({ theme }: Props) => {
    const [userInput, setUserInput] = useState('')

    const { socket } = useWsChannel('notifications')

    const { workspaceUser } = useUser()
    const chatBotController = useChatBot()

    const handleSendMessage = () => {
        if (userInput.trim()) {
            socket?.send(JSON.stringify({ message: userInput }))
            setUserInput('')
        }
    }

    return (
        <Container
            style={{
                flexDirection: 'column',
                width: 550,
            }}
        >
            {/* Header */}
            <Container
                style={{
                    height: 50,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    alignItems: 'center',
                    padding: theme.spacing(1),
                }}
            >
                <NotificationsIcon
                    fontSize="large"
                    htmlColor={theme.palette.text.secondary}
                />

                <Typography
                    variant="h6"
                    style={{
                        fontWeight: theme.typography.fontWeightBold,
                        color: theme.palette.text.primary,
                        marginLeft: theme.spacing(1),
                    }}
                >
                    Notifications
                </Typography>

                <div style={{ flex: 1 }} />
            </Container>

            {/* Chat History */}
            {/* 
            <Container
                style={{
                    flex: 1,
                    padding: theme.spacing(1),
                    flexDirection: 'column',
                }}
            >
                {messages.map((message, index) => (
                    <ChatBotMessage
                        avatar="https://avatars.githubusercontent.com/u/1234567890?v=4"
                        key={index}
                        message={{message: message, isUser: false, sessionId: 0}}
                        isUser={false}
                        theme={theme}
                        userName={workspaceUser?.name || null}
                        chatBotController={chatBotController}
                    />
                ))}
            </Container> */}

            {/* Suggested Actions */}

            {/* Input */}
            <Container
                style={{
                    borderTop: `1px solid ${theme.palette.divider}`,
                    padding: theme.spacing(1),
                    alignItems: 'center',
                }}
            >
                <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Ask about a notification"
                    style={{
                        marginRight: theme.spacing(1),
                    }}
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault()
                            handleSendMessage()
                        }
                    }}
                />

                <IconButton
                    onClick={() => {
                        socket?.send(JSON.stringify({ message: userInput }))
                        setUserInput('')
                    }}
                >
                    <SendIcon htmlColor={theme.palette.text.secondary} />
                </IconButton>
            </Container>
        </Container>
    )
}
