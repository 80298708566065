import React, { useState } from 'react'

import {
    Badge,
    Card,
    CardContent,
    Collapse,
    IconButton,
    makeStyles,
    Theme,
    Tooltip,
    Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FormatPaintIcon from '@material-ui/icons/FormatPaint'
import { AssignmentIndOutlined, ReportProblem } from '@material-ui/icons'
import { AccessTime } from '@material-ui/icons'

import { Container } from '../../../components'
import {
    DeleteWorkorderParams,
    TransitionWorkorderParams,
    WorkorderResponse,
} from '../../../store'
import {
    activeStatuses,
    BaseWorkorder,
    getServiceImageDetails,
    User,
    WorkorderStatus,
    WorkorderType,
} from '../../../models'
import { ReactComponent as ActionItemIcon } from '../../../assets/icons/action-item.svg'
import { DetailCard } from './DetailCard'
import { WorkorderStatusOrb } from '../../../components/WorkorderLib/WorkorderStatusOrb/WorkorderStatusOrb'

interface Props {
    workorder: WorkorderResponse
    theme: Theme
    user?: User
    isSelected: boolean
    isMultiselectMode: boolean
    organizationView?: boolean
    transitionWorkorder: (
        workorder: BaseWorkorder,
        status: WorkorderStatus,
        params?: TransitionWorkorderParams,
    ) => Promise<void>
    openMessageDrawer: () => void
    openUnitNotes: () => void
    openEditModal: () => void
    navigateToScheduleDetail: (scheduleId: number) => void
    deleteWorkorder: (
        workorder: WorkorderResponse,
        params?: DeleteWorkorderParams,
    ) => Promise<void>
    onSelect: () => void
    onClickServiceRequest?: (workorder: WorkorderResponse) => void
}

const useStyles = makeStyles<
    Theme,
    { isSelected: boolean; priceLocked: boolean }
>((theme: Theme) => ({
    card: {
        border: `1px solid ${theme.palette.grey[300]}`,
        width: 300,
        borderColor: (props: { isSelected: boolean }) =>
            props.isSelected
                ? theme.palette.primary.main
                : theme.palette.grey[300],
        // Add a box shadow or background color change for selected state
        boxShadow: (props: { isSelected: boolean }) =>
            props.isSelected
                ? `0 0 10px ${theme.palette.primary.main}`
                : 'none',
        backgroundColor: (props: { isSelected: boolean }) =>
            props.isSelected
                ? theme.palette.primary.light
                : theme.palette.primary.contrastText,
        cursor: (props: { priceLocked: boolean }) =>
            props.priceLocked ? 'not-allowed' : 'pointer',
        opacity: (props: { priceLocked: boolean }) =>
            props.priceLocked ? 0.6 : 1,
    },
    content: {
        padding: theme.spacing(1),
        '&:last-child': {
            paddingBottom: theme.spacing(1),
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    contentClosed: {
        borderRadius: theme.shape.borderRadius,
    },
    contentOpen: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    iconContainer: {
        display: 'flex',
    },
    text: {
        fontWeight: theme.typography.fontWeightBold,
    },
    expandIcon: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandIconOpen: {
        transform: 'rotate(180deg)',
    },
    truncatedText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 130,
        minWidth: 130,
    },
}))

export const JobCard = React.memo(
    (props: Props) => {
        const {
            workorder,
            theme,
            user,
            isSelected,
            isMultiselectMode,
            organizationView,
            transitionWorkorder,
            openMessageDrawer,
            openUnitNotes,
            openEditModal,
            navigateToScheduleDetail,
            deleteWorkorder,
            onSelect,
            onClickServiceRequest,
        } = props

        const [expanded, setExpanded] = useState(false)

        const classes = useStyles({
            isSelected,
            priceLocked: workorder.price_locked,
        })

        const handleExpandClick = () => {
            setExpanded(!expanded)
        }

        const handleCardClick = () => {
            if (isMultiselectMode && !workorder.price_locked) {
                onSelect()
            }
        }

        const isBehind = () => {
            const today = new Date()
            today.setHours(0, 0, 0, 0)
            const startDate = new Date(workorder.start_date)
            return (
                startDate < today && activeStatuses.includes(workorder.status)
            )
        }

        const workorderIsBehind = isBehind()
        const behindIcon = workorderIsBehind ? (
            <Tooltip
                title={`Behind - Start Date: ${new Date(
                    workorder.start_date,
                ).toLocaleDateString()}`}
            >
                <AccessTime color="secondary" />
            </Tooltip>
        ) : null

        let workorderIcon = <FormatPaintIcon />
        let workorderTooltip = 'Service Order'
        if (workorder.type === WorkorderType.ACTION_ITEM) {
            workorderIcon = (
                <ActionItemIcon
                    style={{
                        height: 25,
                        width: 25,
                    }}
                />
            )
            workorderTooltip = 'Action Item'
        } else if (workorder.type === WorkorderType.SERVICE_REQUEST) {
            if (onClickServiceRequest) {
                workorderIcon = (
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation()

                            onClickServiceRequest(workorder)
                        }}
                        style={{
                            alignSelf: 'center',
                        }}
                    >
                        <Badge
                            badgeContent={
                                workorder.service_request?.message_count ?? 0
                            }
                            color="secondary"
                        >
                            <AssignmentIndOutlined />
                        </Badge>
                    </IconButton>
                )
            } else {
                workorderIcon = <AssignmentIndOutlined />
            }
            workorderTooltip = 'Service Request'
        }

        let borderColor = theme.palette.grey[300]
        if (workorder.service_color) {
            borderColor = workorder.service_color
        }

        const priorityIcon =
            (workorder as any).priority === 1 ? (
                <Tooltip title="Priority">
                    <ReportProblem color="secondary" fontSize="small" />
                </Tooltip>
            ) : null

        return (
            <Card
                className={classes.card}
                style={{ borderColor: borderColor }}
                onClick={handleCardClick}
            >
                <CardContent
                    className={`${classes.content} ${
                        expanded ? classes.contentOpen : classes.contentClosed
                    }`}
                >
                    <Container
                        className={classes.iconContainer}
                        style={{
                            flex: 1,
                            marginRight: theme.spacing(2),
                            flexDirection: 'column',
                        }}
                    >
                        <Container
                            style={{
                                maxWidth: 130,
                                minWidth: 130,
                            }}
                        >
                            <Container>
                                <img
                                    src={
                                        getServiceImageDetails(
                                            workorder.service_id ?? -1,
                                        ).icon
                                    }
                                    style={{
                                        maxHeight: 25,
                                        maxWidth: 27,
                                    }}
                                />
                            </Container>
                            <Tooltip title={workorder.service_name ?? ''}>
                                <Typography
                                    variant="body1"
                                    className={`${classes.text} ${classes.truncatedText}`}
                                    style={{ marginLeft: theme.spacing(1) }}
                                >
                                    {workorder.service_name}
                                </Typography>
                            </Tooltip>
                        </Container>
                        <Container
                            style={{
                                flexDirection: 'column',
                            }}
                        >
                            {organizationView && (
                                <Tooltip title={workorder.apartment_name ?? ''}>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            color: theme.palette.primary.dark,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: 145,
                                        }}
                                    >
                                        {workorder.apartment_name ?? ''}
                                    </Typography>
                                </Tooltip>
                            )}
                            <Container
                                style={{
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    style={{
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        marginRight: theme.spacing(1),
                                    }}
                                >
                                    {workorder.path}
                                    {workorder.folder_name}
                                </Typography>

                                <Typography
                                    variant="body2"
                                    style={{
                                        color: theme.palette.primary.dark,
                                    }}
                                >
                                    {workorder.unit_name ?? ''}
                                </Typography>
                            </Container>
                        </Container>
                    </Container>
                    <Container style={{ flexDirection: 'column' }}>
                        <Container
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <Container
                                style={{
                                    minWidth: ICON_CONTAINER_WIDTH,
                                    maxWidth: ICON_CONTAINER_WIDTH,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Tooltip title={workorderTooltip}>
                                    {workorderIcon}
                                </Tooltip>
                            </Container>
                            <Container
                                style={{
                                    minWidth: ICON_CONTAINER_WIDTH,
                                    maxWidth: ICON_CONTAINER_WIDTH,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <WorkorderStatusOrb
                                    workorder={workorder}
                                    theme={theme}
                                />
                            </Container>
                            <Container
                                style={{
                                    minWidth: ICON_CONTAINER_WIDTH,
                                    maxWidth: ICON_CONTAINER_WIDTH,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {behindIcon}
                            </Container>
                            <Container
                                style={{
                                    minWidth: ICON_CONTAINER_WIDTH,
                                    maxWidth: ICON_CONTAINER_WIDTH,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {priorityIcon}
                            </Container>
                        </Container>
                        <Container style={{ flexDirection: 'column' }}>
                            {workorder.user_friendly_schedule_id && (
                                <span
                                    style={{
                                        ...theme.typography.body2,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                    }}
                                >
                                    {workorder.user_friendly_schedule_id}
                                </span>
                            )}
                        </Container>
                    </Container>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation()
                            handleExpandClick()
                        }}
                        aria-expanded={expanded}
                        aria-label="show more"
                        className={`${classes.expandIcon} ${
                            expanded ? classes.expandIconOpen : ''
                        }`}
                        size="small"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardContent>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <DetailCard
                        workorder={workorder}
                        theme={theme}
                        user={user}
                        isSelected={isSelected}
                        organizationView={organizationView}
                        transitionWorkorder={transitionWorkorder}
                        openMessageDrawer={openMessageDrawer}
                        openUnitNotes={openUnitNotes}
                        openEditModal={openEditModal}
                        navigateToScheduleDetail={navigateToScheduleDetail}
                        deleteWorkorder={deleteWorkorder}
                    />
                </Collapse>
            </Card>
        )
    },
    (prevProps, nextProps) => {
        return (
            JSON.stringify(prevProps.workorder) ===
                JSON.stringify(nextProps.workorder) &&
            prevProps.isSelected === nextProps.isSelected &&
            prevProps.isMultiselectMode === nextProps.isMultiselectMode
        )
    },
)

const ICON_CONTAINER_WIDTH = 25
