import React from 'react'

import clsx from 'clsx'
import {
    createStyles,
    makeStyles,
    useTheme,
    Theme,
} from '@material-ui/core/styles'
import { Drawer, Typography, IconButton } from '@material-ui/core'
import {
    ChevronLeft,
    ChevronRight,
    KeyboardArrowDown,
    MenuOpen,
} from '@material-ui/icons'
import Logo from '../../../../src/assets/ezos-logo.png'

import { useUser } from '../../../hooks/useUser'
import { ChatPanel } from './CollapsiblePanel'
import { Container } from '../../../components/Container'
import {
    AGENT_DRAWER_WIDTH,
    AGENT_HEADER_WIDTH,
    TOP_BAR_HEIGHT,
} from '../../constants'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hide: {
            display: 'none',
        },
        drawer: {
            width: AGENT_DRAWER_WIDTH,
            flexShrink: 0,
            zIndex: 0,
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
            left: 60,
            top: 56,
        },
        drawerOpen: {
            width: AGENT_DRAWER_WIDTH,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            backgroundColor: 'transparent',
            color: '#737378',
            left: 60,
            top: 56,
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: 0,
            backgroundColor: 'transparent',
            color: '#737378',
            border: 'none',
        },
        drawerHeader: {
            display: 'flex',
            height: `${TOP_BAR_HEIGHT}px !important`,
            color: theme.palette.white.main,
            borderBottom: ({ open }: { open: boolean }) =>
                open ? `1px solid ${theme.palette.grey[200]}` : 'none',
            backgroundColor: theme.palette.darkBlue.main,
            zIndex: 1,
        },
        drawerContent: {
            backgroundColor: ({ open }: { open: boolean }) =>
                open ? theme.palette.darkBlue.main : 'transparent',
        },
        chevron: {
            justifyContent: 'flex-end',
            alignItems: 'center',
        },

        logo: {
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100px',
        },
        title: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    }),
)

interface Props {
    drawerWidth: number
    open: boolean
    selectedChannel: LoriChannel
    setSelectedChannel: (channel: LoriChannel) => void
    onToggleDrawer?: () => void
}

export const Lori = (props: Props) => {
    const theme = useTheme()
    const classes = useStyles({ open: props.open })

    return (
        <React.Fragment>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: props.open,
                    [classes.drawerClose]: !props.open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: props.open,
                        [classes.drawerClose]: !props.open,
                    }),
                }}
            >
                <div className={classes.drawerContent}>
                    <Container>
                        <ChatPanel
                            theme={theme}
                            isOpen={props.open}
                            selectedChannel={props.selectedChannel}
                            setSelectedChannel={props.setSelectedChannel}
                        />
                    </Container>
                </div>
            </Drawer>
        </React.Fragment>
    )
}
