import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Container } from '../../Container'
import { LoriController } from '../../../hooks/useLori'

interface Props {
    loriController: LoriController
}

export const UserAnalysisTab = ({ loriController }: Props) => {
    const theme = useTheme()

    return (
        <Container
            style={{
                flex: 1,
                padding: theme.spacing(2),
                flexDirection: 'column',
            }}
        >
            {/* Header */}
            <Container
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h6">User Analysis</Typography>
            </Container>
        </Container>
    )
}
