import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { axiosInstance } from '../helpers/axios'
import { generateUUID } from '../models/modelServices'
import { useUser } from './useUser'

const initialChatMessage: string = "Hi I'm Lori, how can I help you today?"
type DeterminedAction =
    | 'create_project'
    | 'create_deadline'
    | 'create_action_item'
    | null

export interface SelectedProperty {
    id: number
    name: string
    selected: boolean
}

interface StringResponse {
    question: string
    answer: string
}

export interface ProjectResponse {
    projectName: StringResponse
    projectId: StringResponse
    budget: StringResponse
    startDate: StringResponse
    endDate: StringResponse
    color: StringResponse
    id?: number
}

export interface DeadlineResponse {
    deadline: StringResponse
    deadlineTitle: StringResponse
    description: StringResponse
    properties: { question: string; answer: number[] | string }
    id?: number
}

export interface ActionItemResponse {
    actionItemName: StringResponse
    startDate: StringResponse
    endDate: StringResponse
    service: StringResponse
    vendor: StringResponse
    units: StringResponse
    id?: number
}

export const useChatBot = () => {
    // State
    const [sessionId, setSessionId] = useState<number | null>(null)
    const [chatHistory, setChatHistory] = useState<DisplayChatMessage[]>([])
    const [navigationUrl, setNavigationUrl] = useState<string | null>(null)
    const [navigationTitle, setNavigationTitle] = useState<string | null>(null)
    const [countdown, setCountdown] = useState<number | null>(null)
    const [thinking, setThinking] = useState<boolean>(false)
    const [actionPanelOpen, setActionPanelOpen] = useState<boolean>(false)
    const [suggestionPanelOpen, setSuggestionPanelOpen] = useState<boolean>(
        false,
    )
    const [sessionHistory, setSessionHistory] = useState<SessionHistory[]>([])
    const [currentSession, setCurrentSession] = useState<SessionHistory | null>(
        null,
    )
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(-1)
    const [returnToQuestionIndex, setReturnToQuestionIndex] = useState<
        number | null
    >(null)
    const [editingQuestion, setEditingQuestion] = useState<boolean>(false)
    const [editMessageIndex, setEditMessageIndex] = useState<number | null>(
        null,
    )

    const [objectResponses, setObjectResponses] = useState<{
        projectResponse: ProjectResponse | null
        deadlineResponse: DeadlineResponse | null
        actionItemResponse: ActionItemResponse | null
    }>({
        projectResponse: null,
        deadlineResponse: null,
        actionItemResponse: null,
    })
    const [isCreateObjectMode, setIsCreateObjectMode] = useState(false)
    const [action, setAction] = useState<DeterminedAction>(null)
    const [
        currentQuestion,
        setCurrentQuestion,
    ] = useState<ObjectQuestion | null>(null)
    const [inputMessage, setInputMessage] = useState<string>('')
    const [objectCreated, setObjectCreated] = useState(false)
    const [actionQuestions, setActionQuestions] = useState<
        ObjectQuestion[] | null
    >(null)

    const [chatMessageId, setChatMessageId] = useState<number | null>(null)
    const [hint, setHint] = useState<string | null>(null)
    const [dataType, setDataType] = useState<
        | 'date'
        | 'string'
        | 'projectId'
        | 'number'
        | 'color'
        | 'properties_list'
        | null
    >(null)

    const [actionObjectId, setActionObjectId] = useState<string | null>(null)
    const [cardMode, setCardMode] = useState<'preview' | 'display'>('preview')
    const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false)
    const [objectId, setObjectId] = useState<number | null>(null)
    const [propertyIds, setPropertyIds] = useState<number[]>([])
    const [projectIdValid, setProjectIdValid] = useState<boolean>(false)

    const { rootUser } = useUser()

    useEffect(() => {
        if (currentSession) {
            const newChatHistory: DisplayChatMessage[] = [
                {
                    message: initialChatMessage,
                    isUser: false,
                    sessionId: currentSession.id,
                },
            ]

            currentSession.messages.forEach((message: ChatMessage) => {
                if (message.assistant === 'Lori') {
                    // Add user's initial message
                    newChatHistory.push({
                        message: message.user_message,
                        isUser: true,
                        sessionId: currentSession.id,
                    })

                    // Add bot's response
                    newChatHistory.push({
                        message: message.bot_response,
                        isUser: false,
                        sessionId: currentSession.id,
                    })

                    // Handle special messages with creation context
                    if (message.created_object_type) {
                        // Add each question and answer from the creation context
                        message.creation_context?.forEach(
                            (ctx: { question: string; answer: string }) => {
                                newChatHistory.push({
                                    message: ctx.question,
                                    isUser: false,
                                    sessionId: currentSession.id,
                                })
                                // property ids are returned rather than names, so we need to map them to names
                                if (
                                    ctx.question ===
                                    '(4/4) For which properties should this deadline apply?'
                                ) {
                                    const parsedAnswer = JSON.parse(ctx.answer)
                                    // Map IDs to workspace names
                                    const workspaceNames = parsedAnswer
                                        .map(
                                            (id: number) =>
                                                rootUser?.workspaces?.find(
                                                    (ws) =>
                                                        ws.active_workspace
                                                            ?.id === id,
                                                )?.active_workspace?.name,
                                        )
                                        .filter(Boolean)
                                        .join(', ')

                                    newChatHistory.push({
                                        message: workspaceNames,
                                        isUser: true,
                                        sessionId: currentSession.id,
                                    })
                                } else {
                                    newChatHistory.push({
                                        message: String(ctx.answer),
                                        isUser: true,
                                        sessionId: currentSession.id,
                                    })
                                }
                            },
                        )

                        // Add the created object component
                        newChatHistory.push({
                            message: `Successfully created ${message.created_object_type}!`,
                            isUser: false,
                            sessionId: currentSession.id,
                            component: {
                                created_object_type:
                                    message.created_object_type,
                                created_object_data:
                                    message.created_object_data,
                            },
                        })
                    }
                }
            })
            setChatHistory(newChatHistory)
            setCardMode('display')
        }
    }, [currentSession])

    const createSession = async () => {
        const res = await axiosInstance.post('chatbot/').catch((err: any) => {
            toast.error('Error creating session')
            console.log(err)
        })
        if (res) {
            setSessionId(res.data.id)
            setChatHistory([
                {
                    message: initialChatMessage,
                    isUser: false,
                    sessionId: res.data.id,
                },
            ])
            setActionPanelOpen(false)
            setSuggestionPanelOpen(false)
            setIsCreateObjectMode(false)
            setCurrentQuestion(null)
            setCurrentQuestionIndex(-1)
            setObjectResponses({
                projectResponse: null,
                deadlineResponse: null,
                actionItemResponse: null,
            })
            setEditingQuestion(false)
            setReturnToQuestionIndex(null)
            setChatMessageId(null)
            setInputMessage('')
        }
        return res
    }

    const getCurrentSession = async (sessionId: number | null = null) => {
        const res = await axiosInstance
            .get(`chatbot/get_single_session/`, {
                params: {
                    session_id: sessionId,
                },
            })
            .catch((err: any) => {
                toast.error('Error getting current session')
                console.log(err)
            })
        if (res) {
            setCurrentSession(res.data)
            setSessionId(res.data.id)
            return res.data
        }
    }

    const getHistory = async () => {
        const res = await axiosInstance.get(`chatbot/`).catch((err: any) => {
            toast.error('Error getting chat history')
        })
        if (res) {
            setSessionHistory(res.data)
        }
        return res?.data
    }

    const initializeObjectCreation = (
        action: Exclude<DeterminedAction, null>,
        sessionId: number,
        updatedHistory: DisplayChatMessage[],
    ) => {
        // Get questions directly from the mapping
        const questions = ACTION_QUESTIONS[action]
        setActionQuestions(questions)
        setCurrentQuestionIndex(0)
        setEditMessageIndex(null)

        // Use questions directly instead of checking actionQuestions state
        setCurrentQuestion(questions[0])
        setHint(questions[0].hint || null)
        setDataType(questions[0].dataType || null)
        setActionObjectId(generateUUID())

        return [
            ...updatedHistory,
            {
                message: "Ok, great! I'll need some information from you.",
                isUser: false,
                sessionId,
            },
            {
                message: questions[0].question,
                isUser: false,
                sessionId,
                actionObjectId: actionObjectId,
            },
        ]
    }

    const formatUserMessage = (
        message: string | SelectedProperty[],
        currentQuestion: ObjectQuestion,
    ): string => {
        let formattedValue: string = ''
        let property_ids: number[] = []

        if (currentQuestion.dataType === 'properties_list') {
            formattedValue = (message as SelectedProperty[])
                .map((property) => property.name)
                .join(', ')
            property_ids = (message as SelectedProperty[]).map(
                (property) => property.id,
            )
            setPropertyIds(property_ids)
        } else if (
            currentQuestion.dataType === 'date' &&
            typeof message === 'string'
        ) {
            formattedValue = new Date(message).toISOString().slice(0, 10)
        } else if (
            currentQuestion.dataType === 'number' &&
            typeof message === 'string'
        ) {
            formattedValue = message.replace(/,/g, '')
        } else {
            formattedValue = String(message)
        }

        return formattedValue
    }

    const sendMessageCreator = async (
        message: string | SelectedProperty[],
        sessionId: number,
        chatHistory: DisplayChatMessage[],
    ) => {
        if (!action || !currentQuestion) return

        const updatedHistory: DisplayChatMessage[] = chatHistory
        const questions = ACTION_QUESTIONS[action]
        const formattedValue = formatUserMessage(message, currentQuestion)

        // Validate the response
        const validation = validateProjectResponse(
            currentQuestion,
            formattedValue,
        )
        if (!validation.isValid) {
            setChatHistory([
                ...updatedHistory,
                {
                    message: `${validation.errorMessage}. Please try again: ${currentQuestion.question}`,
                    isUser: false,
                    sessionId,
                },
            ])
            return
        }

        // Next question
        let nextQuestionIndex: number = currentQuestionIndex + 1

        // Editing an answer
        if (
            returnToQuestionIndex !== null &&
            editingQuestion &&
            editMessageIndex !== null
        ) {
            // Update the edited response in history
            updatedHistory.splice(editMessageIndex, 1, {
                message: formattedValue,
                isUser: true,
                sessionId,
                actionQuestionId: currentQuestion.id,
                actionObjectId: actionObjectId, // Make sure actionObjectId is included
            })
            nextQuestionIndex = returnToQuestionIndex

            // If all questions were previously answered, update the card immediately
            if (allQuestionsAnswered) {
                const relevantMessages = updatedHistory.filter(
                    (msg) => msg.actionObjectId === actionObjectId,
                )

                // Transform messages into responses object
                const responses = questions.reduce((acc, question) => {
                    const answer =
                        relevantMessages.find(
                            (msg) => msg.actionQuestionId === question.id,
                        )?.message || ''
                    acc[question.key] = {
                        question: question.question,
                        answer: answer,
                    }
                    return acc
                }, {} as any)

                // Update object responses state
                setObjectResponses({
                    projectResponse:
                        action === 'create_project' ? responses : null,
                    deadlineResponse:
                        action === 'create_deadline' ? responses : null,
                    actionItemResponse:
                        action === 'create_action_item' ? responses : null,
                })

                // Update the card component in chat history
                const cardIndex = updatedHistory.findIndex(
                    (msg) =>
                        msg.component?.created_object_type ===
                        (action === 'create_project' ? 'schedule' : 'deadline'),
                )
                if (cardIndex !== -1) {
                    updatedHistory[cardIndex] = {
                        message: `Successfully created ${action}!`,
                        isUser: false,
                        sessionId: sessionId,
                        component: {
                            created_object_type:
                                action === 'create_project'
                                    ? 'schedule'
                                    : 'deadline',
                            created_object_data: responses,
                        },
                    }
                    setChatHistory([...updatedHistory])
                    setSuggestionPanelOpen(false)
                }
            } else {
                console.log('all questions not answered')
                setSuggestionPanelOpen(true)
            }
            setEditingQuestion(false)
            setEditMessageIndex(null)
        } else {
            // Add the users response
            if (currentQuestion.dataType === 'projectId') {
                validateProjectId(formattedValue)
            }
            updatedHistory.push({
                message: formattedValue,
                isUser: true,
                sessionId,
                actionQuestionId: currentQuestion.id,
                actionObjectId: actionObjectId,
            })
        }

        if (nextQuestionIndex < questions.length) {
            const nextQuestion = questions[nextQuestionIndex]
            setCurrentQuestion(nextQuestion)
            setCurrentQuestionIndex(nextQuestionIndex)
            setReturnToQuestionIndex(nextQuestionIndex)
            setHint(nextQuestion.hint || null)
            setDataType(nextQuestion.dataType || null)

            if (!editingQuestion) {
                setChatHistory([
                    ...updatedHistory,
                    {
                        message: nextQuestion.question,
                        isUser: false,
                        sessionId,
                        actionObjectId: actionObjectId,
                    },
                ])
            }
        } else {
            setCardMode('preview')
            setInputMessage('')
            setAllQuestionsAnswered(true)
            const relevantMessages = updatedHistory.filter(
                (msg) => msg.actionObjectId === actionObjectId,
            )

            // Transform array into object with correct shape
            const responses = questions.reduce((acc, question) => {
                acc[question.key] = {
                    question: question.question,
                    answer:
                        relevantMessages.find(
                            (msg) => msg.actionQuestionId === question.id,
                        )?.message || '',
                }
                return acc
            }, {} as any)

            setObjectResponses({
                projectResponse: action === 'create_project' ? responses : null,
                deadlineResponse:
                    action === 'create_deadline' ? responses : null,
                actionItemResponse:
                    action === 'create_action_item' ? responses : null,
            })

            // Add the created object component
            if (currentSession) {
                setChatHistory([
                    ...updatedHistory,
                    {
                        message: `Successfully created ${action}!`,
                        isUser: false,
                        sessionId: currentSession.id,
                        component: {
                            created_object_type:
                                action === 'create_project'
                                    ? 'schedule'
                                    : 'deadline',
                            created_object_data: responses,
                        },
                    },
                ])
            }

            setSuggestionPanelOpen(false)
        }
    }

    const validateProjectId = async (projectId: string) => {
        await axiosInstance
            .post('chatbot/create-project/validate_project_id/', {
                project_id: projectId,
            })
            .then((res) => {
                setProjectIdValid(res.data)
            })
            .catch((err) => {
                toast.error('Error validating project ID')
                console.error(err)
            })
    }

    const createObject = async () => {
        try {
            let newObjectResponses = objectResponses
            if (
                action === 'create_deadline' &&
                objectResponses.deadlineResponse
            ) {
                newObjectResponses = {
                    ...objectResponses,
                    deadlineResponse: {
                        ...objectResponses.deadlineResponse,
                        properties: {
                            ...objectResponses.deadlineResponse.properties,
                            answer: propertyIds,
                        },
                    },
                }
            }
            const res = await axiosInstance.post('chatbot/create-project/', {
                chat_message_id: chatMessageId,
                object_type: action,
                create_responses: newObjectResponses,
            })

            if (res?.data) {
                toast.success('Creation Successful!')

                // Update chat history to include the ID in the component data
                const updatedHistory = chatHistory.map((message) => {
                    if (
                        message.component?.created_object_type ===
                        (action === 'create_project' ? 'schedule' : 'deadline')
                    ) {
                        return {
                            ...message,
                            component: {
                                ...message.component,
                                created_object_data: {
                                    ...message.component.created_object_data,
                                    id: res.data.object_id,
                                },
                            },
                        }
                    }
                    return message
                })

                setChatHistory(updatedHistory)
                setObjectId(res.data.object_id)
                setCardMode('display')
            }
        } catch (err) {
            toast.error('Error creating object')
            console.error(err)
        }

        setIsCreateObjectMode(false)
        setCurrentQuestion(null)
        setCurrentQuestionIndex(-1)
        setActionObjectId(null)
    }

    const sendMessageAssistant = async (
        message: string,
        sessionId: number,
        currentUrl: string,
    ) => {
        const updatedHistory = [
            ...chatHistory,
            { message, isUser: true, sessionId },
        ]
        setChatHistory(updatedHistory)
        setThinking(true)
        try {
            const res = await axiosInstance.post<ChatMessage>(
                'chatbot/chatbot-message/',
                {
                    message,
                    session_id: sessionId,
                    current_url: currentUrl,
                },
            )

            if (res?.data) {
                const {
                    bot_response,
                    determined_action,
                    navigate_url,
                    title,
                } = res.data

                // If the Assistant has determined an action, begin object creation mode.
                if (determined_action) {
                    setIsCreateObjectMode(true)
                    setSuggestionPanelOpen(true)
                    setChatMessageId(res.data.id)
                    const newHistory = initializeObjectCreation(
                        determined_action,
                        sessionId,
                        updatedHistory,
                    )
                    setChatHistory(newHistory)
                    setAction(determined_action)
                } else {
                    setChatHistory([
                        ...updatedHistory,
                        {
                            message: bot_response,
                            isUser: false,
                            sessionId: sessionId,
                        },
                    ])

                    if (navigate_url && title) {
                        setActionPanelOpen(true)
                        setNavigationUrl(navigate_url)
                        setNavigationTitle(title)
                        setCountdown(5)
                    }
                }
            }
        } catch (err) {
            toast.error('Error sending message')
            console.error(err)
        }
        setThinking(false)
    }

    return {
        createSession,
        sessionId,
        setSessionId,
        chatHistory,
        setChatHistory,
        sendMessage: sendMessageAssistant,
        navigationUrl,
        setNavigationUrl,
        countdown,
        setCountdown,
        thinking,
        setThinking,
        navigationTitle,
        setNavigationTitle,
        actionPanelOpen,
        setActionPanelOpen,
        sessionHistory,
        setSessionHistory,
        getHistory,
        getCurrentSession,
        currentSession,
        setCurrentSession,
        currentQuestionIndex,
        setCurrentQuestionIndex,
        isCollectingProjectInfo: isCreateObjectMode,
        setIsCollectingProjectInfo: setIsCreateObjectMode,
        suggestionPanelOpen,
        setSuggestionPanelOpen,
        currentQuestion,
        setCurrentQuestion,
        inputMessage,
        setInputMessage,
        sendMessageCreator,
        isCreateObjectMode,
        objectCreated,
        setObjectCreated,
        action,
        setAction,
        setReturnToQuestionIndex,
        returnToQuestionIndex,
        setEditingQuestion,
        editMessageIndex,
        setEditMessageIndex,
        hint,
        setHint,
        dataType,
        setDataType,
        actionQuestions,
        setActionQuestions,
        cardMode,
        setCardMode,
        createObject,
        actionObjectId,
        setActionObjectId,
        objectResponses,
        setObjectResponses,
        allQuestionsAnswered,
        setAllQuestionsAnswered,
        objectId,
        setObjectId,
        projectIdValid,
    }
}

export type ChatBotController = ReturnType<typeof useChatBot>

export interface DisplayChatMessage {
    message: string
    isUser: boolean
    sessionId: number
    component?: { created_object_type: string; created_object_data: any }
    actionQuestionId?: number
    actionObjectId?: string | null
}

interface ChatMessage {
    id: number
    user_message: string
    bot_response: string
    determined_action: 'create_project' | 'create_deadline' | null
    assistant: string
    created_date: string
    navigate_url: string | null
    title: string | null
    created_object_type: string
    creation_context: { question: string; answer: string }[]
    created_object_data: any
}

export interface SessionHistory {
    id: number
    user: number
    description: string
    created_date: string
    thread_id: string
    messages: ChatMessage[]
}
interface ValidationResult {
    isValid: boolean
    errorMessage?: string
}

const validateProjectResponse = (
    question: ObjectQuestion,
    value: string | number[],
): ValidationResult => {
    let num: number
    let date: Date = new Date('01/01/1900')
    let normalizedValue: string

    switch (question.dataType) {
        case 'string':
            if (question.key === 'projectName' && typeof value === 'string') {
                if (!value.trim()) {
                    return {
                        isValid: false,
                        errorMessage: 'Project name cannot be empty',
                    }
                }
                if (value.length > 255) {
                    return {
                        isValid: false,
                        errorMessage:
                            'Project name must be less than 255 characters',
                    }
                }
            }
            if (question.key === 'projectId' && typeof value === 'string') {
                if (!value.trim()) {
                    return {
                        isValid: false,
                        errorMessage: 'Project ID cannot be empty',
                    }
                }
                if (value.length > 8) {
                    return {
                        isValid: false,
                        errorMessage:
                            'Project ID must be less than 8 characters',
                    }
                }
            }

            break
        case 'number':
            num = Number(value)
            if (isNaN(num)) {
                return {
                    isValid: false,
                    errorMessage: 'Please enter a valid number',
                }
            }
            if (question.key === 'budget' && num < 0) {
                return {
                    isValid: false,
                    errorMessage: 'Budget cannot be negative',
                }
            }
            break
        case 'date':
            if (typeof value === 'string') {
                date = new Date(value)
            }
            if (date.toISOString() === 'Invalid Date') {
                return {
                    isValid: false,
                    errorMessage: 'Please enter a valid date (MM/DD/YYYY)',
                }
            }
            break
    }
    return { isValid: true }
}

interface ObjectQuestion {
    id: number
    question: string
    key: string
    dataType:
        | 'date'
        | 'string'
        | 'projectId'
        | 'number'
        | 'color'
        | 'properties_list'
    hint?: string
}

export const DEADLINE_QUESTIONS: ObjectQuestion[] = [
    {
        id: 0,
        question: '(1/4) What is the deadline?',
        key: 'deadline',
        dataType: 'date',
    },
    {
        id: 1,
        question: '(2/4) What is the title?',
        key: 'deadlineTitle',
        dataType: 'string',
        hint: 'Title to be displayed on the calendar.',
    },
    {
        id: 2,
        question: '(3/4) Description for the deadline',
        key: 'description',
        dataType: 'string',
        hint: 'Describe the deadline in a few words.',
    },
    {
        id: 3,
        question: '(4/4) For which properties should this deadline apply?',
        key: 'properties',
        dataType: 'properties_list',
    },
]

export const PROJECT_QUESTIONS: ObjectQuestion[] = [
    {
        id: 0,
        question: '(1/6) What is the name of the project?',
        key: 'projectName',
        dataType: 'string',
        hint: 'Give your project a name.',
    },
    {
        id: 1,
        question: '(2/6) What is the project ID?',
        key: 'projectId',
        dataType: 'projectId',
        hint:
            'Shorthand identifier for the project; must be less than 9 characters and unique',
    },
    {
        id: 2,
        question: "(3/6) What is the project's budget?",
        key: 'budget',
        dataType: 'number',
        hint: 'Set budget for the project; set to 0 to adjust later.',
    },
    {
        id: 3,
        question: "(4/6) What is the project's start date?",
        key: 'startDate',
        dataType: 'date',
    },
    {
        id: 4,
        question: "(5/6) What is the project's end date?",
        key: 'endDate',
        dataType: 'date',
    },
    {
        id: 5,
        question: '(6/6) What color should be displayed for the project?',
        key: 'color',
        dataType: 'color',
    },
]

export const ACTION_ITEM_QUESTIONS: ObjectQuestion[] = [
    {
        id: 0,
        question: '(1/6) What is the name of the action item?',
        key: 'actionItemName',
        dataType: 'string',
        hint: 'Give your action item a name.',
    },
    {
        id: 1,
        question: '(2/6) What is the start date?',
        key: 'startDate',
        dataType: 'date',
    },
    {
        id: 2,
        question: '(3/6) What is the end date?',
        key: 'endDate',
        dataType: 'date',
    },
    {
        id: 3,
        question: '(4/6) What service needs to be provided?',
        key: 'service',
        dataType: 'string',
    },
    {
        id: 4,
        question: '(5/6) Who should be assigned to complete the action item?',
        key: 'vendor',
        dataType: 'string',
    },
    {
        id: 5,
        question: '(6/6) Which units should the action item be assigned to?',
        key: 'units',
        dataType: 'string',
    },
]

// Create a mapping of actions to their question sets
export const ACTION_QUESTIONS: Record<
    Exclude<DeterminedAction, null>,
    ObjectQuestion[]
> = {
    create_project: PROJECT_QUESTIONS,
    create_deadline: DEADLINE_QUESTIONS,
    create_action_item: ACTION_ITEM_QUESTIONS,
}
