import { WorkorderResponse } from '../../store'

export enum JbDrawerId {
    message,
    unitNotes,
    edit,
    serviceRequest,
}

export interface JbFormState {
    filterFormOpen: boolean
    createWorkorderForm: boolean
    serviceRequestModal: boolean
    vendorRuleModal: boolean
    ezNowModal: boolean
    propertyAnalyticsModal: boolean
    bulkEditModal: boolean
    detailFormOpen: {
        workorder: null | WorkorderResponse
        drawerId: null | JbDrawerId
    }
}

export const closedJbFormState: JbFormState = {
    filterFormOpen: false,
    createWorkorderForm: false,
    serviceRequestModal: false,
    vendorRuleModal: false,
    ezNowModal: false,
    propertyAnalyticsModal: false,
    bulkEditModal: false,
    detailFormOpen: {
        workorder: null,
        drawerId: null,
    },
}

export const THIS_WEEK = 0
export const NEXT_WEEK = 1
export const CUSTOM = 2

export const ALL = 0
export const IN_HOUSE = 1
export const CONTRACTOR = 2
export const MY_TEAM = 3
