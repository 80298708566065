import React from 'react'
import { Container } from '../../../../../components/Container'
import { ProjectCard } from './CustomCards/ProjectCard'
import { DeadlineCard } from './CustomCards/DeadlineCard'
import { HowToVideoCard } from './CustomCards/HowToVideoCard'
import { ContactSupportButton } from './CustomCards/ContactSupportButton'
import { UserFeedback } from './CustomCards/UserFeedback'

import { LoriController } from '../../../../../hooks/useLori'
import { ChatMessageContent } from '../../types'
import { useTheme } from '@material-ui/core'
import { NavigateCard } from './CustomCards/NavigateCard'
import { ProjectPlanCard } from './CustomCards/ProjectPlanCard'

interface RenderCardsProps {
    function_call: string
    loriController: LoriController
    message: ChatMessageContent
}

export const RenderCards: React.FC<RenderCardsProps> = ({
    function_call,
    loriController,
    message,
}) => {
    const theme = useTheme()

    switch (function_call) {
        case 'create_project':
            return (
                <Container style={{ display: 'flex', flexDirection: 'column' }}>
                    <ProjectCard theme={theme} message={message} />
                    <UserFeedback
                        message={message}
                        loriController={loriController}
                        context="create_project"
                    />
                </Container>
            )
        case 'create_deadline':
            return (
                <Container style={{ display: 'flex', flexDirection: 'column' }}>
                    <DeadlineCard theme={theme} message={message} />
                    <UserFeedback
                        message={message}
                        loriController={loriController}
                        context="create_deadline"
                    />
                </Container>
            )
        case 'how_to_video':
            return (
                <Container style={{ display: 'flex', flexDirection: 'column' }}>
                    <HowToVideoCard
                        message={message}
                        loriController={loriController}
                    />
                    <UserFeedback
                        message={message}
                        loriController={loriController}
                        context="how_to_video"
                    />
                </Container>
            )
        case 'contact_support':
            return (
                <ContactSupportButton
                    theme={theme}
                    conversation={loriController.activeConversation ?? null}
                />
            )
        case 'show_project_plan': {
            return (
                <Container style={{ display: 'flex', flexDirection: 'column' }}>
                    <ProjectPlanCard
                        message={message}
                        loriController={loriController}
                    />
                    <UserFeedback
                        message={message}
                        loriController={loriController}
                        context="create_project_plan"
                    />
                </Container>
            )
        }
        case 'navigate_url': {
            return (
                <Container style={{ display: 'flex', flexDirection: 'column' }}>
                    <NavigateCard
                        message={message}
                        loriController={loriController}
                    />
                    <UserFeedback
                        message={message}
                        loriController={loriController}
                        context="navigate"
                    />
                </Container>
            )
        }
        default:
            return null
    }
}
