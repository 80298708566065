import { useState, useEffect } from 'react'
import { axiosInstance } from '../helpers/axios'
import { IdentifiableNamedObject } from '../models'

export interface PlotData {
    x: string[]
    y: number[]
    total_count: number
    remaining_count: number
    first_month_count: number
}

export interface Additions {
    workspace: PlotData
    unit: PlotData
}

export interface Inspections {
    x: string[]
    y: number[]
    trend: number[]
    text: string[]
}

export interface Schedules {
    x: string[]
    y: number[]
    trend: number[]
    text: string[]
}

export interface ChangeOrders {
    x: string[]
    y: number[]
    trend: number[]
    text: string[]
}

export interface Coordinates {
    x: string[]
    y: number[]
}

export interface Damages {
    x: string[]
    y: number[]
}

export interface Invoices {
    q1: Coordinates
    q2: Coordinates
    q3: Coordinates
    q4: Coordinates
}

export interface UserData {
    x: string[]
    y: number[]
    mom_changes: number[]
    total_users: number
}

export interface VendorData {
    contract: {
        x: string[]
        y: number[]
    }
    in_house: {
        x: string[]
        y: number[]
    }
    total_users: number
}

export interface Users {
    Workspace: UserData
    Tenant: UserData
    Vendor: VendorData
}

export type LeaseAuditStatus =
    | 'REQUESTED'
    | 'SETUP'
    | 'WAITING_FOR_APPROVAL'
    | 'RUNNING_PIPELINE'
    | 'COMPLETED'
    | 'APPROVED'
    | 'REJECTED'

export interface LeaseAudit {
    id: number
    name: string
    status: LeaseAuditStatus
    apartment: IdentifiableNamedObject
    created_date: string
    created_by: IdentifiableNamedObject
    criteria_group: number
    page_count: number
    document_count: number
}

export const useKPI = () => {
    const [additions, setAdditions] = useState<Additions | null>(null)
    const [inspections, setInspections] = useState<Inspections | null>(null)
    const [damages, setDamages] = useState<Damages | null>(null)
    const [
        displayFilteredApartmentStatus,
        setDisplayFilteredApartmentStatus,
    ] = useState<string[]>([])
    const [
        displayFilteredDamageStatus,
        setDisplayFilteredDamageStatus,
    ] = useState<string[]>([])
    const [invoices, setInvoices] = useState<Invoices | null>(null)
    const [schedules, setSchedules] = useState<Schedules | null>(null)
    const [changeOrders, setChangeOrders] = useState<ChangeOrders | null>(null)
    const [users, setUsers] = useState<Users | null>(null)
    const [leaseAudits, setLeaseAudits] = useState<LeaseAudit[]>([])

    useEffect(() => {
        getAdditions()
        getInspections()
        getInvoices()
        getSchedules()
        getChangeOrders()
        getUsers()
        getLeaseAudits()
    }, [])

    const getAdditions = async () => {
        const res = await axiosInstance
            .get('/stats/kpi/get_additions')
            .then((res) => {
                setAdditions(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getInspections = async () => {
        const res = await axiosInstance
            .get('/stats/kpi/get_inspections')
            .then((res) => {
                setInspections(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getDamages = async (
        selectedApartmentStatus: string[],
        selectedDamageStatus: string[],
    ) => {
        await axiosInstance
            .get('/stats/kpi/get_damages', {
                params: {
                    apartment_status_filter: selectedApartmentStatus,
                    damage_status_filter: selectedDamageStatus,
                },
            })
            .then((res) => {
                setDamages(res.data)
                setDisplayFilteredApartmentStatus(selectedApartmentStatus)
                setDisplayFilteredDamageStatus(selectedDamageStatus)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getInvoices = async () => {
        const res = await axiosInstance
            .get('/stats/kpi/get_invoices')
            .then((res) => {
                setInvoices(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getSchedules = async () => {
        const res = await axiosInstance
            .get('/stats/kpi/get_schedules')
            .then((res) => {
                setSchedules(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getChangeOrders = async () => {
        const res = await axiosInstance
            .get('/stats/kpi/get_change_orders')
            .then((res) => {
                setChangeOrders(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getUsers = async () => {
        const res = await axiosInstance
            .get('/stats/kpi/get_users')
            .then((res) => {
                setUsers(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getLeaseAudits = async () => {
        const res = await axiosInstance
            .get('/stats/kpi/get_lease_audit')
            .then((res) => {
                setLeaseAudits(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return {
        additions,
        inspections,
        damages,
        getDamages,
        setDamages,
        displayFilteredApartmentStatus,
        displayFilteredDamageStatus,
        invoices,
        schedules,
        changeOrders,
        users,
        leaseAudits,
        getLeaseAudits,
    }
}

export type KPIController = ReturnType<typeof useKPI>
