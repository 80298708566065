import React from 'react'

interface LoriTextProps {
    width?: number | string
    height?: number | string
    color?: string
}

export const LoriText = ({
    width = 500,
    height = 500,
    color = 'currentColor',
}: LoriTextProps) => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="15o.000000pt"
            height="20.000000pt"
            viewBox="0 0 586.000000 219.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <g
                transform="translate(-500.000000,219.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
            >
                <path
                    d="M1769 1948 c-29 -51 -161 -280 -292 -508 -132 -228 -253 -442 -268
-475 -148 -310 -6 -713 306 -866 156 -76 131 -74 859 -74 l648 0 205 355 c473
821 457 787 457 985 1 116 -2 138 -26 206 -62 175 -180 315 -329 388 -137 67
-172 73 -455 78 l-251 5 -146 -255 c-81 -141 -147 -258 -147 -261 0 -3 166 -6
369 -8 357 -3 371 -4 398 -24 45 -34 73 -82 73 -128 0 -35 -38 -107 -283 -531
-156 -269 -301 -519 -322 -554 l-39 -63 -80 160 -80 161 -296 3 c-265 3 -298
5 -325 21 -46 29 -68 65 -72 121 l-4 51 367 635 c201 349 369 643 371 653 5
16 -14 17 -290 17 l-295 0 -53 -92z"
                />
                <path
                    d="M40 1065 l0 -965 500 0 500 0 0 195 0 195 -305 0 -305 0 0 770 0 770
-195 0 -195 0 0 -965z"
                />
                <path
                    d="M3740 1065 l0 -965 195 0 195 0 0 370 0 370 93 0 92 0 194 -370 194
-370 219 0 219 0 -35 63 c-19 34 -120 213 -224 397 -177 315 -188 336 -168
344 100 42 138 66 192 122 263 266 197 772 -122 941 -112 59 -143 63 -616 63
l-428 0 0 -965z m817 562 c137 -74 138 -318 2 -388 -34 -17 -60 -19 -234 -19
l-195 0 0 216 0 216 198 -4 c163 -3 202 -6 229 -21z"
                />
                <path d="M5370 1065 l0 -965 195 0 195 0 0 965 0 965 -195 0 -195 0 0 -965z" />
                <path
                    d="M2099 1105 c-14 -8 -32 -28 -39 -45 -37 -89 90 -160 150 -83 57 73
-30 174 -111 128z"
                />
                <path
                    d="M2349 1091 c-20 -20 -29 -39 -29 -61 0 -39 47 -90 83 -90 14 0 39 12
56 26 25 20 31 34 31 64 0 30 -6 44 -31 64 -40 34 -74 33 -110 -3z"
                />
                <path
                    d="M2630 1107 c-70 -36 -57 -145 19 -162 41 -9 79 10 98 50 16 33 16 37
0 70 -24 50 -69 66 -117 42z"
                />
            </g>
        </svg>
    )
}
