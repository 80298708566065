import { useTheme } from '@material-ui/core'
import React from 'react'
import loadingGif from '../assets/Loading_Animation_2.gif'
import BackgroundImage from '../assets/login_background.png'
import EzosLogo from '../assets/ezos-logo-primary-dark.png'
import { Container } from '../components'

export const LoadingScreen: React.FC = () => {
    const theme = useTheme()

    return (
        <Container
            style={{
                height: '100vh',
                width: '100vw',
                backgroundImage: `url(${BackgroundImage})`,
                backgroundSize: 'cover',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <Container
                style={{
                    backgroundColor: 'white',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
                    borderRadius: '40px',
                    flexDirection: 'column',
                }}
            >
                <Container
                    style={{
                        alignItems: 'center',
                        flexDirection: 'column',
                        margin: theme.spacing(8),
                    }}
                >
                    <img
                        src={EzosLogo}
                        style={{
                            padding: theme.spacing(1),
                            height: 80,
                            marginBottom: theme.spacing(2),
                        }}
                        alt="Ezos Logo"
                    />
                    <img
                        src={loadingGif}
                        alt="Loading animation"
                        style={{
                            width: 64 * 8,
                            height: 64 * 8,
                        }}
                    />
                    <span
                        style={{
                            ...theme.typography.h4,
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.grey[700],
                            marginBottom: theme.spacing(1),
                        }}
                    >
                        Loading...
                    </span>
                    <span
                        style={{
                            ...theme.typography.subtitle1,
                            color: theme.palette.grey[600],
                        }}
                    >
                        Please wait while we setup your workspace
                    </span>
                </Container>
            </Container>
        </Container>
    )
}
