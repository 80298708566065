import React, { useEffect, useState } from 'react'

import { Container } from '../../Container'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    useTheme,
    TextField,
} from '@material-ui/core'
import { usePagination } from '../../../hooks'
import { toMMDDYYYY } from '../../../helpers'
import { Pagination } from '@material-ui/lab'
import { useLori } from '../../../hooks/useLori'

type Order = 'asc' | 'desc'
type OrderBy =
    | 'property'
    | 'name'
    | 'start_date'
    | 'end_date'
    | 'completed_tasks'
    | 'total_tasks'
    | 'overdue_tasks'

export const ProjectPlansTab = () => {
    const LoriController = useLori()
    const theme = useTheme()

    const [order, setOrder] = useState<Order>('asc')
    const [orderBy, setOrderBy] = useState<OrderBy>('property')
    const [searchTerm, setSearchTerm] = useState('')

    const handleRequestSort = (property: OrderBy) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const today = new Date()

    const filteredAndSortedData = React.useMemo(() => {
        if (!LoriController.projectPlanList) return []

        const filtered = LoriController.projectPlanList.filter((plan) =>
            plan.project.apartment.name
                .toLowerCase()
                .includes(searchTerm.toLowerCase()),
        )

        return [...filtered].sort((a, b) => {
            let aValue: any
            let bValue: any

            switch (orderBy) {
                case 'property':
                    aValue = a.project.apartment.name
                    bValue = b.project.apartment.name
                    break
                case 'name':
                    aValue = a.project.name
                    bValue = b.project.name
                    break
                case 'start_date':
                    aValue = new Date(a.project.start_date)
                    bValue = new Date(b.project.start_date)
                    break
                case 'end_date':
                    aValue = new Date(a.project.end_date)
                    bValue = new Date(b.project.end_date)
                    break
                case 'completed_tasks':
                    aValue = a.tasks.filter((task) => task.completed).length
                    bValue = b.tasks.filter((task) => task.completed).length
                    break
                case 'total_tasks':
                    aValue = a.tasks.length
                    bValue = b.tasks.length
                    break
                case 'overdue_tasks': {
                    const projectAStartDate = new Date(a.project.start_date)
                    const projectBStartDate = new Date(b.project.start_date)
                    aValue = a.tasks.filter((task) => {
                        const dueDate = new Date(projectAStartDate)
                        dueDate.setDate(
                            dueDate.getDate() - task.complete_days_prior,
                        )
                        return !task.completed && today > dueDate
                    }).length
                    bValue = b.tasks.filter((task) => {
                        const dueDate = new Date(projectBStartDate)
                        dueDate.setDate(
                            dueDate.getDate() - task.complete_days_prior,
                        )
                        return !task.completed && today > dueDate
                    }).length
                    break
                }
                default:
                    aValue = a
                    bValue = b
            }

            if (order === 'desc') {
                const temp = aValue
                aValue = bValue
                bValue = temp
            }

            if (aValue < bValue) return -1
            if (aValue > bValue) return 1
            return 0
        })
    }, [LoriController.projectPlanList, order, orderBy, searchTerm])

    const { page, setPage, pageData, numPages, start, end } = usePagination(
        filteredAndSortedData,
    )
    useEffect(() => {
        LoriController.getProjectPlanList()
    }, [])

    return (
        <Container style={{ flex: 1, flexDirection: 'column' }}>
            <Container style={{ padding: theme.spacing(2) }}>
                <TextField
                    variant="outlined"
                    placeholder="Search property name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    size="small"
                    style={{ maxWidth: '500px', minWidth: '300px' }}
                />
            </Container>
            <TableContainer style={{ maxHeight: '100%', height: '100%' }}>
                <Table
                    stickyHeader
                    style={{
                        width: '100%',
                    }}
                >
                    <TableHead>
                        <TableRow>
                            {[
                                {
                                    id: 'property',
                                    label: 'Property',
                                },
                                {
                                    id: 'name',
                                    label: 'Name',
                                },
                                {
                                    id: 'start_date',
                                    label: 'Start Date',
                                },
                                {
                                    id: 'end_date',
                                    label: 'End Date',
                                },
                                {
                                    id: 'completed_tasks',
                                    label: 'Completed Tasks',
                                },
                                {
                                    id: 'total_tasks',
                                    label: 'Total Tasks',
                                },
                                {
                                    id: 'overdue_tasks',
                                    label: 'Overdue Tasks',
                                },
                            ].map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    style={{
                                        ...theme.typography.body1,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                    }}
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={
                                            orderBy === headCell.id
                                                ? order
                                                : 'asc'
                                        }
                                        onClick={() =>
                                            handleRequestSort(
                                                headCell.id as OrderBy,
                                            )
                                        }
                                    >
                                        {headCell.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pageData.map((projectPlan) => {
                            const completedTasks = projectPlan.tasks.filter(
                                (task) => task.completed,
                            ).length
                            const totalTasks = projectPlan.tasks.length

                            // Calculate overdue tasks
                            const projectStartDate = new Date(
                                projectPlan.project.start_date,
                            )
                            const overdueTasks = projectPlan.tasks.filter(
                                (task) => {
                                    const dueDate = new Date(projectStartDate)
                                    dueDate.setDate(
                                        dueDate.getDate() -
                                            task.complete_days_prior,
                                    )
                                    return !task.completed && today > dueDate
                                },
                            ).length

                            return (
                                <TableRow key={projectPlan.id}>
                                    <TableCell>
                                        {projectPlan.project.apartment.name}
                                    </TableCell>
                                    <TableCell>
                                        {projectPlan.project.name}
                                    </TableCell>
                                    <TableCell>
                                        {toMMDDYYYY(
                                            new Date(
                                                projectPlan.project.start_date,
                                            ),
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {toMMDDYYYY(
                                            new Date(
                                                projectPlan.project.end_date,
                                            ),
                                        )}
                                    </TableCell>
                                    <TableCell>{completedTasks}</TableCell>
                                    <TableCell>{totalTasks}</TableCell>
                                    <TableCell>{overdueTasks}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* footer */}
            <Container
                style={{
                    ...theme.typography.subtitle1,
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(1),
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Pagination
                    count={numPages}
                    page={page}
                    onChange={(_: unknown, newPage: number) => {
                        setPage(newPage)
                    }}
                />
                <span>
                    {start} - {end} of {filteredAndSortedData?.length ?? 0}
                </span>
            </Container>
        </Container>
    )
}
