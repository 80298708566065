import React, { useState, useEffect } from 'react'
import { Container } from '../../components/Container'
import { useTheme } from '@material-ui/core'
import Plot from 'react-plotly.js'
import { KPIController } from '../../hooks/useKPI'

export const Invoices = ({
    KPIController,
}: {
    KPIController: KPIController
}) => {
    const theme = useTheme()
    const [plotWidth, setPlotWidth] = useState(0)
    const [plotHeight, setPlotHeight] = useState(0)

    // set the plot size
    useEffect(() => {
        const updateDimensions = () => {
            // Get the container element and calculate dimensions
            const container = document.querySelector('.plot-container')
            if (container) {
                // Subtracting 20px from each dimension for padding
                setPlotWidth(container.clientWidth - 20)
                setPlotHeight(container.clientHeight - 20)
            }
        }
        // Initial calculation
        updateDimensions()

        // Add resize listener
        window.addEventListener('resize', updateDimensions)

        // Cleanup
        return () => window.removeEventListener('resize', updateDimensions)
    }, [])

    const calculateQuarterlyGrowth = (quarterData: {
        x: string[]
        y: number[]
    }) => {
        const growthText = new Array(quarterData.x.length).fill('')

        quarterData.x.forEach((year, index) => {
            // Find the index of previous year's data point
            const prevYearIndex = quarterData.x.findIndex(
                (y) => parseInt(y) === parseInt(year) - 1,
            )

            if (prevYearIndex !== -1) {
                const currentValue = quarterData.y[index]
                const prevValue = quarterData.y[prevYearIndex]

                // Calculate growth percentage
                const growth = ((currentValue - prevValue) / prevValue) * 100
                growthText[index] = `${growth.toFixed(1)}%`
            }
        })

        return growthText
    }

    const data = KPIController.invoices
        ? [
              {
                  x: KPIController.invoices.q1.x,
                  y: KPIController.invoices.q1.y,
                  text: calculateQuarterlyGrowth(KPIController.invoices.q1),
                  textposition: 'outside' as const,
                  type: 'bar' as const,
                  name: 'Q1',
                  hovertemplate: '$%{y:,.0f}<extra></extra>',
              },
              {
                  x: KPIController.invoices.q2.x,
                  y: KPIController.invoices.q2.y,
                  text: calculateQuarterlyGrowth(KPIController.invoices.q2),
                  textposition: 'outside' as const,
                  type: 'bar' as const,
                  name: 'Q2',
                  hovertemplate: '$%{y:,.0f}<extra></extra>',
              },
              {
                  x: KPIController.invoices.q3.x,
                  y: KPIController.invoices.q3.y,
                  text: calculateQuarterlyGrowth(KPIController.invoices.q3),
                  textposition: 'outside' as const,
                  type: 'bar' as const,
                  name: 'Q3',
                  hovertemplate: '$%{y:,.0f}<extra></extra>',
              },
              {
                  x: KPIController.invoices.q4.x,
                  y: KPIController.invoices.q4.y,
                  text: calculateQuarterlyGrowth(KPIController.invoices.q4),
                  textposition: 'outside' as const,
                  type: 'bar' as const,
                  name: 'Q4',
                  hovertemplate: '$%{y:,.0f}<extra></extra>',
              },
          ]
        : []

    const layout = {
        barmode: 'group' as const,
        title: 'Invoices - YoY Quarterly Growth',
        xaxis: {
            title: 'Year',
        },
        yaxis: {
            title: 'Invoiced Dollars',
        },
        hoverlabel: {
            font: {
                size: 16,
            },
        },
    }

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                height: 'calc(100vh - 120px)',
            }}
            className="plot-container"
        >
            <Container
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {KPIController.invoices ? (
                    <Plot
                        data={data}
                        layout={layout}
                        useResizeHandler={true}
                        style={{ width: '100%', height: '100%' }}
                    />
                ) : (
                    <div>Loading...</div>
                )}
            </Container>
        </Container>
    )
}
