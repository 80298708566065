import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Tab, Tabs, useTheme } from '@material-ui/core'

import { Container } from '../../components'
import { InspectionFrequencyTab } from './InspectionFrequencyTab'
import {
    useAppDispatch,
    useAptConfig,
    useCompany,
    useRootInfrastructure,
    useService,
    useUser,
} from '../../hooks'
import { workorderContext } from '../../contexts'
import { FrequencyList } from '../ActionItem/FrequencyList'
import { useApartmentVendorRule } from '../../hooks/useApartmentVendorRule'
import { VendorServiceRuleTab } from './VendorServiceRuleTab'
import { useInspectionFrequency } from '../../hooks/useInspectionFrequency'
import { useMoveOutRules } from '../../hooks/useMoveOutRules'
import { MoveOutRuleTab } from './MoveOutRuleTab'
import { setWorkSpaceUser, UpdateApartmentRequest } from '../../store'

export const PropertyAutomationContainer = () => {
    const history = useHistory()
    const location = useLocation()

    const TabHashMap: { [key: string]: string } = {
        [INS_FREQUENCY_TAB]: 'inspection-frequencies',
        [ACTION_ITEM_FREQUENCY_TAB]: 'action-item-frequencies',
        [MOVE_OUT_RULE_TAB]: 'move-out-rules',
        [VENDOR_SERVICE_RULE_TAB]: 'vendor-service-rules',
    }

    const hashToTab = Object.fromEntries(
        Object.entries(TabHashMap).map(([key, value]) => [value, key]),
    )

    useEffect(() => {
        const hash = location.hash.replace('#', '')
        if (hash in hashToTab) {
            setTab(hashToTab[hash])
        }
    }, [location])

    const [tab, setTab] = useState(() => {
        const hash = location.hash.replace('#', '')
        return hash in hashToTab ? hashToTab[hash] : INS_FREQUENCY_TAB
    })

    const handleTabChange = (_: any, newValue: string) => {
        if (newValue !== tab) {
            setTab(newValue)
            history.push(`/property-automation#${TabHashMap[newValue]}`)
        }
    }

    const theme = useTheme()

    const { getFrequencyList, frequencyList } = workorderContext()

    const { getAreaConfigMap, inspectionTypeList } = useAptConfig({
        areaConfigList: true,
        inspectionTypeList: true,
    })

    const {
        apartmentVendorRuleDict,
        setApartmentVendorRuleDict,
        getApartmentVendorRules,
        createOrUpdateAptVendorRule,
        deleteAptVendorRule,
    } = useApartmentVendorRule()

    const {
        frequencyList: inspectionFrequencyList,
        getFrequencyList: getInspectionFrequencyList,
        selectedFrequency: selectedInspectionFrequency,
        setSelectedFrequency: setSelectedInspectionFrequency,
        setFrequencyList: setInspectionFrequencyList,
        toggleFrequencyActive: toggleInspectionFrequencyActive,
    } = useInspectionFrequency()

    const {
        moveOutRuleList,
        selectedMoveOutRule,
        setSelectedMoveOutRule,
        getMoveOutRuleList,
        createMoveOutRule,
        createOrUpdateMoveOutStep,
        deleteMoveOutStep,
        deleteMoveOutRule,
        createOrUpdateVendorRule,
        deleteMoveOutVendorRule,
        setDefaultMoveOutRule,
    } = useMoveOutRules()

    const areaConfigMap = getAreaConfigMap()

    const { serviceList } = useService({ getServiceList: true, cleanUp: true })
    const { vendorList, updateApartment } = useCompany({
        getVendorList: true,
        cleanUp: true,
    })
    const { userList, workspaceUser, actions } = useUser()
    const { getUserList } = actions

    const { tree } = useRootInfrastructure(true)

    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            await Promise.allSettled([
                getUserList({ params: { my_team: true } }),
                getFrequencyList(),
                getApartmentVendorRules(),
                getInspectionFrequencyList(),
                getMoveOutRuleList(),
            ])
            setLoading(false)
        }
        fetchData()
    }, [])

    const onUpdateApartment = (req: UpdateApartmentRequest) => {
        updateApartment(req).then((apartment) => {
            if (workspaceUser?.active_workspace) {
                workspaceUser.active_workspace = apartment
                dispatch(setWorkSpaceUser(workspaceUser))
            }
        })
    }

    const renderTabContent = () => {
        switch (tab) {
            case INS_FREQUENCY_TAB:
                return (
                    <InspectionFrequencyTab
                        areaConfigMap={areaConfigMap}
                        frequencyList={inspectionFrequencyList}
                        selectedFrequency={selectedInspectionFrequency}
                        loading={loading}
                        workspaceUser={workspaceUser}
                        setSelectedFrequency={setSelectedInspectionFrequency}
                        setFrequencyList={setInspectionFrequencyList}
                        toggleFrequencyActive={toggleInspectionFrequencyActive}
                        onUpdateApartment={onUpdateApartment}
                    />
                )
            case ACTION_ITEM_FREQUENCY_TAB:
                return (
                    <FrequencyList
                        frequencyList={frequencyList ?? []}
                        serviceList={serviceList}
                        userList={userList}
                        vendorList={vendorList}
                        root={tree.root}
                        areaConfigMap={areaConfigMap}
                        propertyAutomationMode={true}
                        workspaceUser={workspaceUser}
                        onUpdateApartment={onUpdateApartment}
                    />
                )
            case VENDOR_SERVICE_RULE_TAB:
                return (
                    <VendorServiceRuleTab
                        apartmentVendorRuleDict={apartmentVendorRuleDict}
                        serviceList={serviceList}
                        vendorList={vendorList}
                        loading={loading}
                        createOrUpdateAptVendorRule={
                            createOrUpdateAptVendorRule
                        }
                        deleteAptVendorRule={deleteAptVendorRule}
                        setApartmentVendorRuleDict={setApartmentVendorRuleDict}
                    />
                )
            case MOVE_OUT_RULE_TAB:
                return (
                    <MoveOutRuleTab
                        moveOutRuleList={moveOutRuleList}
                        serviceList={serviceList}
                        inspectionTypeList={inspectionTypeList ?? []}
                        vendorList={vendorList}
                        selectedMoveOutRule={selectedMoveOutRule}
                        loading={loading}
                        workspaceUser={workspaceUser}
                        setSelectedMoveOutRule={setSelectedMoveOutRule}
                        createMoveOutRule={createMoveOutRule}
                        deleteMoveOutRule={deleteMoveOutRule}
                        createOrUpdateMoveOutStep={createOrUpdateMoveOutStep}
                        deleteMoveOutStep={deleteMoveOutStep}
                        createOrUpdateVendorRule={createOrUpdateVendorRule}
                        onDeleteVendorRule={deleteMoveOutVendorRule}
                        setDefaultMoveOutRule={setDefaultMoveOutRule}
                        onUpdateApartment={onUpdateApartment}
                    />
                )
            default:
                return null
        }
    }

    return (
        <Container style={{ flex: 1 }} direction="column">
            <Container
                style={{
                    borderBottom: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(1),
                }}
            >
                <Tabs value={tab} onChange={handleTabChange}>
                    <Tab
                        label="Inspection Frequencies"
                        value={INS_FREQUENCY_TAB}
                    />
                    <Tab
                        label="Action Item Frequencies"
                        value={ACTION_ITEM_FREQUENCY_TAB}
                    />
                    <Tab label="Move Out Rules" value={MOVE_OUT_RULE_TAB} />
                    <Tab
                        label="Vendor Service Rules"
                        value={VENDOR_SERVICE_RULE_TAB}
                    />
                </Tabs>
            </Container>
            {renderTabContent()}
        </Container>
    )
}

const INS_FREQUENCY_TAB = 'INS_FREQUENCY_TAB'
const ACTION_ITEM_FREQUENCY_TAB = 'ACTION_ITEM_TRIGGER_TAB'
const MOVE_OUT_RULE_TAB = 'MOVE_OUT_RULE_TAB'
const VENDOR_SERVICE_RULE_TAB = 'VENDOR_SERVICE_RULE_TAB'
