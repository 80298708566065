// const roomName = JSON.parse(document.getElementById('room-name').textContent);

// const chatSocket = new WebSocket(
//     'ws://'
//     + window.location.host
//     + '/ws/chat/'
//     + roomName
//     + '/'
// );

// chatSocket.onmessage = function(e) {
//     const data = JSON.parse(e.data);
//     document.querySelector('#chat-log').value += (data.message + '\n');
// };

// chatSocket.onclose = function(e) {
//     console.error('Chat socket closed unexpectedly');
// };

// document.querySelector('#chat-message-input').focus();
// document.querySelector('#chat-message-input').onkeyup = function(e) {
//     if (e.key === 'Enter') {  // enter, return
//         document.querySelector('#chat-message-submit').click();
//     }
// };

// document.querySelector('#chat-message-submit').onclick = function(e) {
//     const messageInputDom = document.querySelector('#chat-message-input');
//     const message = messageInputDom.value;
//     chatSocket.send(JSON.stringify({
//         'message': message
//     }));
//     messageInputDom.value = '';
// };

import { useState, useEffect, useCallback } from 'react'

export const useWsChannel = (roomName: string) => {
    const [messages, setMessages] = useState<string[]>([])
    const [socket, setSocket] = useState<WebSocket | null>(null)

    const createWebSocket = useCallback(() => {
        const newSocket = new WebSocket(
            'ws://' + 'localhost:8000' + '/ws/chat/' + roomName + '/',
        )

        newSocket.onmessage = function (e) {
            const data = JSON.parse(e.data)
            setMessages((prevMessages) => [...prevMessages, data.message])
        }

        newSocket.onclose = function (e) {
            console.error('Chat socket closed unexpectedly')
            // Optionally, you can attempt to reconnect here
        }

        setSocket(newSocket)
    }, [roomName])

    useEffect(() => {
        createWebSocket()

        return () => {
            if (socket) {
                socket.close()
            }
        }
    }, [createWebSocket])

    // ... existing code ...

    return { messages, socket }
}
