import React, { useState, useEffect } from 'react'
import { Container } from '../../components/Container'
import {
    Checkbox,
    ListItemText,
    MenuItem,
    FormControl,
    Typography,
    useTheme,
    InputLabel,
} from '@material-ui/core'
import Plot from 'react-plotly.js'
import { KPIController } from '../../hooks/useKPI'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { normalizeString } from '../../helpers/stringHelpers'
import {
    formatNumber,
    padNumberForAlignment,
} from '../../helpers/numberHelpers'

export const Additions = ({
    KPIController,
}: {
    KPIController: KPIController
}) => {
    const theme = useTheme()
    const [plotWidth, setPlotWidth] = useState(0)
    const [plotHeight, setPlotHeight] = useState(0)
    const [selectedAddition, setSelectedAddition] = useState<
        'Workspace' | 'Unit'
    >('Workspace')

    // set the plot size
    useEffect(() => {
        const updateDimensions = () => {
            // Get the container element and calculate dimensions
            const container = document.querySelector('.plot-container')
            if (container) {
                // Subtracting 20px from each dimension for padding
                setPlotWidth(container.clientWidth - 20)
                setPlotHeight(container.clientHeight - 20)
            }
        }
        // Initial calculation
        updateDimensions()

        // Add resize listener
        window.addEventListener('resize', updateDimensions)

        // Cleanup
        return () => window.removeEventListener('resize', updateDimensions)
    }, [])

    const handleChange = (e: SelectChangeEvent<string>) => {
        setSelectedAddition(e.target.value as 'Workspace' | 'Unit')
    }

    // Add this near the top of your component to prepare the numbers array
    const getAdditionsNumbers = () => {
        if (!KPIController.additions) return []
        return [
            selectedAddition === 'Workspace'
                ? KPIController.additions.workspace.total_count
                : KPIController.additions.unit.total_count,
            selectedAddition === 'Workspace'
                ? KPIController.additions.workspace.first_month_count
                : KPIController.additions.unit.first_month_count,
            selectedAddition === 'Workspace'
                ? KPIController.additions.workspace.remaining_count
                : KPIController.additions.unit.remaining_count,
        ]
    }

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                height: 'calc(100vh - 200px)',
            }}
            className="plot-container"
        >
            {/* header */}
            <Container
                style={{
                    flexDirection: 'row',
                }}
            >
                {/* selector */}
                <Container>
                    <FormControl
                        variant="outlined"
                        style={{
                            width: 300,
                            margin: theme.spacing(2),
                        }}
                    >
                        <InputLabel id={`category-label`}>
                            Type of Adds
                        </InputLabel>
                        <Select
                            labelId={`category-label`}
                            id={`category-select`}
                            value={selectedAddition}
                            onChange={(e: SelectChangeEvent<string>) => {
                                handleChange(e)
                            }}
                            label="Type of Adds"
                            style={{ paddingLeft: theme.spacing(1) }}
                            renderValue={() => selectedAddition}
                        >
                            {Object.keys(KPIController.additions || {}).map(
                                (category) => (
                                    <MenuItem
                                        key={`CATEGORY_SELECT__${category}`}
                                        value={normalizeString(category)}
                                    >
                                        <ListItemText
                                            primary={normalizeString(category)}
                                        />
                                    </MenuItem>
                                ),
                            )}
                        </Select>
                    </FormControl>
                </Container>
                {/* Summary Stats */}
                <Container
                    style={{
                        flexDirection: 'column',
                        marginLeft: theme.spacing(2),
                        alignItems: 'flex-end',
                    }}
                >
                    <Container
                        style={{
                            flexDirection: 'row',
                        }}
                    >
                        <Typography
                            style={{
                                ...theme.typography.body1,
                            }}
                        >
                            Total Adds:{' '}
                        </Typography>
                        <Typography
                            style={{
                                ...theme.typography.body1,
                                fontWeight: 'bold',
                            }}
                        >
                            {padNumberForAlignment(
                                getAdditionsNumbers(),
                                selectedAddition === 'Workspace'
                                    ? KPIController.additions?.workspace
                                          .total_count
                                    : KPIController.additions?.unit.total_count,
                            )}
                        </Typography>
                    </Container>

                    <Container
                        style={{
                            flexDirection: 'row',
                        }}
                    >
                        <Typography
                            style={{
                                ...theme.typography.body1,
                            }}
                        >
                            Adds before May 2023:{' '}
                        </Typography>
                        <Typography
                            style={{
                                ...theme.typography.body1,
                                fontWeight: 'bold',
                            }}
                        >
                            {padNumberForAlignment(
                                getAdditionsNumbers(),
                                selectedAddition === 'Workspace'
                                    ? KPIController.additions?.workspace
                                          .first_month_count
                                    : KPIController.additions?.unit
                                          .first_month_count,
                            )}
                        </Typography>
                    </Container>

                    <Container
                        style={{
                            flexDirection: 'row',
                        }}
                    >
                        <Typography
                            style={{
                                ...theme.typography.body1,
                            }}
                        >
                            Adds after May 2023:{' '}
                        </Typography>
                        <Typography
                            style={{
                                ...theme.typography.body1,
                                fontWeight: 'bold',
                                whiteSpace: 'pre',
                            }}
                        >
                            {padNumberForAlignment(
                                getAdditionsNumbers(),
                                selectedAddition === 'Workspace'
                                    ? KPIController.additions?.workspace
                                          .remaining_count
                                    : KPIController.additions?.unit
                                          .remaining_count,
                            )}
                        </Typography>
                    </Container>
                </Container>
            </Container>

            {/* plot */}
            <Container
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {KPIController.additions ? (
                    selectedAddition === 'Workspace' ? (
                        <Plot
                            data={[
                                {
                                    type: 'bar',
                                    x: KPIController.additions.workspace.x,
                                    y: KPIController.additions.workspace.y,
                                    text: KPIController.additions.workspace.y.map(
                                        String,
                                    ),
                                    marker: { color: 'blue' },
                                    hovertemplate:
                                        '%{x|%B, %Y}<br>Count: %{y}<extra></extra>',
                                },
                            ]}
                            layout={{
                                width: plotWidth,
                                height: plotHeight,
                                title: {
                                    text: 'Monthly Workspace Additions',
                                },
                                margin: { t: 50, b: 50, l: 50, r: 50 },
                            }}
                            useResizeHandler={true}
                            style={{ width: '100%', height: '100%' }}
                        />
                    ) : (
                        <Plot
                            data={[
                                {
                                    type: 'bar',
                                    x: KPIController.additions.unit.x,
                                    y: KPIController.additions.unit.y,
                                    text: KPIController.additions.unit.y.map(
                                        String,
                                    ),
                                    marker: { color: 'blue' },
                                    hovertemplate:
                                        '%{x|%B, %Y}<br>Count: %{y}<extra></extra>',
                                },
                            ]}
                            layout={{
                                width: plotWidth,
                                height: plotHeight,
                                title: { text: 'Monthly Unit Additions' },
                                margin: { t: 50, b: 50, l: 50, r: 50 },
                            }}
                            useResizeHandler={true}
                            style={{ width: '100%', height: '100%' }}
                        />
                    )
                ) : (
                    <div>Loading...</div>
                )}
            </Container>
        </Container>
    )
}
