import React, { useState, useEffect } from 'react'
import { Container } from '../../components/Container'
import { CircularProgress, Typography, useTheme } from '@material-ui/core'
import Plot from 'react-plotly.js'
import { KPIController } from '../../hooks/useKPI'

export const ChangeOrders = ({
    KPIController,
}: {
    KPIController: KPIController
}) => {
    const theme = useTheme()
    const [plotWidth, setPlotWidth] = useState(0)
    const [plotHeight, setPlotHeight] = useState(0)

    // set the plot size
    useEffect(() => {
        const updateDimensions = () => {
            // Get the container element and calculate dimensions
            const container = document.querySelector('.plot-container')
            if (container) {
                // Subtracting 20px from each dimension for padding
                setPlotWidth(container.clientWidth - 20)
                setPlotHeight(container.clientHeight - 20)
            }
        }
        // Initial calculation
        updateDimensions()

        // Add resize listener
        window.addEventListener('resize', updateDimensions)

        // Cleanup
        return () => window.removeEventListener('resize', updateDimensions)
    }, [])

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                height: 'calc(100vh - 120px)',
            }}
            className="plot-container"
        >
            <Container
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {KPIController.changeOrders ? (
                    <Plot
                        data={[
                            {
                                type: 'scatter',
                                x: KPIController.changeOrders.x,
                                y: KPIController.changeOrders.y,
                                mode: 'text+lines',
                                text: KPIController.changeOrders.text,
                                textposition: 'top center',
                                marker: { color: 'blue' },
                                name: 'Count',
                                hovertemplate:
                                    '%{x|%B, %Y}<br>Count: %{y}<extra></extra>',
                                textfont: {
                                    size: 18,
                                    color: 'black',
                                },
                            },
                            {
                                type: 'scatter',
                                x: KPIController.changeOrders.x,
                                y: KPIController.changeOrders.trend,
                                text: KPIController.changeOrders.trend.map(
                                    String,
                                ),
                                marker: { color: 'orange' },
                                name: 'Trend',
                                hovertemplate:
                                    '%{x|%B, %Y}<br>Trend: %{y}<extra></extra>',
                            },
                        ]}
                        layout={{
                            width: plotWidth,
                            height: plotHeight,
                            title: {
                                text:
                                    'Monthly Count of Unique Workspace with a Change Order',
                            },
                            margin: { t: 50, b: 50, l: 50, r: 50 },
                            legend: {
                                x: 0.01,
                                y: 0.99,
                                xanchor: 'left',
                                yanchor: 'top',
                                bgcolor: 'rgba(255, 255, 255, 0.8)', // semi-transparent white background
                            },
                        }}
                        useResizeHandler={true}
                        style={{ width: '100%', height: '100%' }}
                    />
                ) : (
                    <Container
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress />
                    </Container>
                )}
            </Container>
        </Container>
    )
}
