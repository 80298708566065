import React, { useState, useEffect, useMemo } from 'react'
import { Container } from '../../components/Container'
import {
    CircularProgress,
    Typography,
    useTheme,
    IconButton,
    Tooltip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core'
import Plot from 'react-plotly.js'
import { KPIController } from '../../hooks/useKPI'
import { LeaseAudit } from '../../models/DocumentAudit/types'
import TableChartIcon from '@material-ui/icons/TableChart'
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot'
import { makeStyles } from '@material-ui/core/styles'
import { currencyFormatterNoDecimals } from '../../helpers/CurrencyFormatter'

// Add styles for the tooltip
const useStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        maxWidth: 'none',
        border: `1px solid ${theme.palette.divider}`,
        boxShadow: theme.shadows[2],
        fontSize: '0.875rem',
        padding: theme.spacing(1),
    },
    detailRow: {
        display: 'grid',
        gridTemplateColumns: '120px 180px 200px 120px 120px',
        gap: theme.spacing(2),
        padding: theme.spacing(0.5),
        '& > div': {
            whiteSpace: 'nowrap',
        },
        '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
    },
    header: {
        fontWeight: 'bold',
        borderBottom: `2px solid ${theme.palette.divider}`,
    },
    sectionBorder: {
        borderLeft: `2px solid ${theme.palette.divider}`,
    },
}))

interface LeaseAuditProps {
    KPIController: KPIController
}

export const LeaseAudits = ({ KPIController }: LeaseAuditProps) => {
    if (!KPIController) return null
    const theme = useTheme()
    const [plotWidth, setPlotWidth] = useState(0)
    const [plotHeight, setPlotHeight] = useState(0)
    const [tableView, setTableView] = useState(false)
    const classes = useStyles()

    const leaseAudits = KPIController.leaseAudits

    // set the plot size
    useEffect(() => {
        const updateDimensions = () => {
            // Get the container element and calculate dimensions
            const container = document.querySelector('.plot-container')
            if (container) {
                // Subtracting 20px from each dimension for padding
                setPlotWidth(container.clientWidth - 20)
                setPlotHeight(container.clientHeight - 20)
            }
        }
        // Initial calculation
        updateDimensions()

        // Add resize listener
        window.addEventListener('resize', updateDimensions)

        // Cleanup
        return () => window.removeEventListener('resize', updateDimensions)
    }, [])

    const plotData = useMemo(() => {
        // Define status colors
        const statusColors = {
            completed: theme.palette.success.main,
            waiting_for_approval: theme.palette.warning.main,
            rejected: theme.palette.error.main,
        }

        // Group audits by date AND status
        const auditsByDateAndStatus = leaseAudits.reduce((acc, audit) => {
            const date = audit.created_date.split('T')[0]
            if (!acc[date]) {
                acc[date] = {
                    completed: { count: 0, totalPages: 0 },
                    waiting_for_approval: { count: 0, totalPages: 0 },
                    rejected: { count: 0, totalPages: 0 },
                }
            }

            // Map the status to one of our three categories
            let statusKey: keyof typeof statusColors = 'waiting_for_approval'
            if (audit.status === 'COMPLETED') statusKey = 'completed'
            if (audit.status === 'REJECTED') statusKey = 'rejected'

            acc[date][statusKey].count += 1
            acc[date][statusKey].totalPages += audit.page_count
            return acc
        }, {} as Record<string, Record<keyof typeof statusColors, { count: number; totalPages: number }>>)

        // Get unique months from the data
        const monthYearArray = Array.from(
            new Set(
                Object.keys(auditsByDateAndStatus).map((date) => {
                    const d = new Date(date)
                    return `${d.toLocaleString('en-US', {
                        month: 'short',
                    })} ${d.getFullYear().toString().slice(-2)}`
                }),
            ),
        ).sort((a, b) => {
            const [aMonth, aYear] = a.split(' ')
            const [bMonth, bYear] = b.split(' ')
            return (
                new Date(`${aMonth} 1, 20${aYear}`).getTime() -
                new Date(`${bMonth} 1, 20${bYear}`).getTime()
            )
        })

        // Create tickvals array
        const tickvals = Array.from(
            { length: monthYearArray.length },
            (_, i) => i,
        )

        // Create traces for each status
        const traces = Object.entries(statusColors)
            .map(([status, color]) => {
                const validData = Object.keys(auditsByDateAndStatus)
                    .map((date) => {
                        const d = new Date(date)
                        const monthYear = `${d.toLocaleString('en-US', {
                            month: 'short',
                        })} ${d.getFullYear().toString().slice(-2)}`
                        const monthIndex = monthYearArray.indexOf(monthYear)

                        if (
                            monthIndex !== -1 &&
                            auditsByDateAndStatus[date][
                                status as keyof typeof statusColors
                            ].count > 0
                        ) {
                            const dayPosition = (d.getDate() - 1) / 31
                            const position = monthIndex + dayPosition

                            return {
                                date,
                                position,
                                count:
                                    auditsByDateAndStatus[date][
                                        status as keyof typeof statusColors
                                    ].count,
                                pageCount:
                                    auditsByDateAndStatus[date][
                                        status as keyof typeof statusColors
                                    ].totalPages,
                            }
                        }
                        return null
                    })
                    .filter(
                        (item): item is NonNullable<typeof item> =>
                            item !== null,
                    )
                    .sort((a, b) => a.position - b.position)

                if (validData.length === 0) return null

                const maxPages = Math.max(...validData.map((d) => d.pageCount))
                const markerSizes = validData.map((d) =>
                    Math.max(10, Math.sqrt(d.pageCount / maxPages) * 50),
                )

                return {
                    type: 'scatter' as const,
                    mode: 'markers' as const,
                    name: status
                        .replace(/_/g, ' ')
                        .replace(/\b\w/g, (l) => l.toUpperCase()),
                    x: validData.map((d) => d.position),
                    y: validData.map((d) => d.count),
                    marker: {
                        size: markerSizes,
                        sizemode: 'diameter' as const,
                        sizeref: 1,
                        color: color,
                        opacity: 0.7,
                    },
                    hovertemplate:
                        'Date: %{text}<br>' +
                        'Status: ' +
                        status
                            .replace(/_/g, ' ')
                            .replace(/\b\w/g, (l) => l.toUpperCase()) +
                        '<br>' +
                        'Audits: %{y}<br>' +
                        'Pages: %{customdata:,}<br>' +
                        '<extra></extra>',
                    text: validData.map((d) => d.date),
                    customdata: validData.map((d) => d.pageCount),
                }
            })
            .filter(
                (trace): trace is NonNullable<typeof trace> => trace !== null,
            )

        const layout = {
            title: 'Lease Audit Timeline',
            width: plotWidth,
            height: plotHeight,
            showlegend: true, // Changed to true to show status legend
            xaxis: {
                title: 'Date',
                showgrid: true,
                gridcolor: theme.palette.divider,
                tickmode: 'array' as const,
                tickvals: tickvals,
                ticktext: monthYearArray,
                tickangle: -45,
                range: [-0.5, monthYearArray.length - 0.5], // Ensure full month display
            },
            yaxis: {
                title: 'Number of Audits',
                showgrid: true,
                gridcolor: theme.palette.divider,
                zeroline: true,
                nticks: Math.max(...traces.flatMap((t) => t.y)) + 1,
            },
            hovermode: 'closest' as const,
            plot_bgcolor: 'white',
            paper_bgcolor: 'white',
        }

        // Add this at the end of useMemo to make the monthly data available for the table
        const monthlyData = monthYearArray.map((monthYear) => {
            const [month, year] = monthYear.split(' ')
            const startDate = new Date(`${month} 1, 20${year}`)
            const endDate = new Date(
                new Date(startDate).setMonth(startDate.getMonth() + 1),
            )

            let completedAudits = 0
            let completedPages = 0
            let rejectedAudits = 0
            let rejectedPages = 0
            let waitingAudits = 0
            let waitingPages = 0

            // Store detailed audits for this month
            const detailedAudits = leaseAudits.filter((audit) => {
                const auditDate = new Date(audit.created_date)
                return auditDate >= startDate && auditDate < endDate
            })

            // Calculate totals
            detailedAudits.forEach((audit) => {
                if (audit.status === 'COMPLETED') {
                    completedAudits++
                    completedPages += audit.page_count
                } else if (audit.status === 'REJECTED') {
                    rejectedAudits++
                    rejectedPages += audit.page_count
                } else {
                    waitingAudits++
                    waitingPages += audit.page_count
                }
            })

            return {
                date: monthYear,
                completedAudits,
                completedPages,
                completedRevenue: completedPages * 0.04,
                rejectedAudits,
                rejectedPages,
                lostRevenue: rejectedPages * 0.04,
                waitingAudits,
                waitingPages,
                potentialRevenue: waitingPages * 0.04,
                detailedAudits,
            }
        })

        return {
            data: traces,
            layout,
            monthlyData,
        }
    }, [leaseAudits, plotWidth, plotHeight, theme])

    // Create a tooltip component
    const AuditTooltip = ({ audits }: { audits: LeaseAudit[] }) => {
        const classes = useStyles()

        return (
            <div style={{ minWidth: '600px' }}>
                <div className={`${classes.detailRow} ${classes.header}`}>
                    <div>Status</div>
                    <div>Apartment</div>
                    <div>Name</div>
                    <div>Page Count</div>
                    <div>Revenue</div>
                </div>
                {audits.map((audit) => (
                    <div key={audit.id} className={classes.detailRow}>
                        <div>{audit.status}</div>
                        <div>{audit.apartment.name}</div>
                        <div>{audit.name}</div>
                        <div>{audit.page_count}</div>
                        <div>${(audit.page_count * 0.04).toFixed(2)}</div>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                height: 'calc(100vh - 120px)',
            }}
            className="plot-container"
        >
            {/* header */}
            <Container
                style={{
                    flexDirection: 'row',
                }}
            >
                {tableView ? (
                    <Tooltip title="Switch to Plot">
                        <IconButton
                            onClick={() => setTableView(false)}
                            style={{ fontSize: 'large' }}
                        >
                            <ScatterPlotIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Switch to Table">
                        <IconButton
                            onClick={() => setTableView(true)}
                            style={{ fontSize: 'large' }}
                        >
                            <TableChartIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Container>

            <Container
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {leaseAudits ? (
                    !tableView ? (
                        <Plot
                            data={plotData.data}
                            layout={plotData.layout}
                            config={{ responsive: true }}
                        />
                    ) : (
                        <TableContainer
                            component={Paper}
                            style={{
                                maxHeight: '100%',
                                maxWidth: '1200px',
                                overflowY: 'auto',
                            }}
                        >
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell
                                            align="right"
                                            className={classes.sectionBorder}
                                        >
                                            Completed Audits
                                        </TableCell>
                                        <TableCell align="right">
                                            Completed Pages
                                        </TableCell>
                                        <TableCell align="right">
                                            Completed Revenue ($)
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            className={classes.sectionBorder}
                                        >
                                            Potential Audits
                                        </TableCell>
                                        <TableCell align="right">
                                            Potential Pages
                                        </TableCell>
                                        <TableCell align="right">
                                            Potential Revenue ($)
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            className={classes.sectionBorder}
                                        >
                                            Rejected Audits
                                        </TableCell>
                                        <TableCell align="right">
                                            Rejected Pages
                                        </TableCell>
                                        <TableCell align="right">
                                            Lost Revenue ($)
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {plotData.monthlyData.map((row, index) => (
                                        <Tooltip
                                            key={row.date}
                                            title={
                                                <AuditTooltip
                                                    audits={row.detailedAudits}
                                                />
                                            }
                                            classes={{
                                                tooltip: classes.tooltip,
                                            }}
                                            placement="bottom-end"
                                            interactive
                                        >
                                            <TableRow hover>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.date}
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    className={
                                                        classes.sectionBorder
                                                    }
                                                >
                                                    {row.completedAudits}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.completedPages.toLocaleString()}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {currencyFormatterNoDecimals.format(
                                                        row.completedRevenue,
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    className={
                                                        classes.sectionBorder
                                                    }
                                                >
                                                    {row.waitingAudits}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.waitingPages.toLocaleString()}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {currencyFormatterNoDecimals.format(
                                                        row.potentialRevenue,
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    className={
                                                        classes.sectionBorder
                                                    }
                                                >
                                                    {row.rejectedAudits}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.rejectedPages.toLocaleString()}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {currencyFormatterNoDecimals.format(
                                                        row.lostRevenue,
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        </Tooltip>
                                    ))}
                                    <TableRow>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            Total
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            className={classes.sectionBorder}
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            {plotData.monthlyData.reduce(
                                                (sum, row) =>
                                                    sum + row.completedAudits,
                                                0,
                                            )}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            {plotData.monthlyData
                                                .reduce(
                                                    (sum, row) =>
                                                        sum +
                                                        row.completedPages,
                                                    0,
                                                )
                                                .toLocaleString()}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            {currencyFormatterNoDecimals.format(
                                                plotData.monthlyData.reduce(
                                                    (sum, row) =>
                                                        sum +
                                                        row.completedRevenue,
                                                    0,
                                                ),
                                            )}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            className={classes.sectionBorder}
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            {plotData.monthlyData.reduce(
                                                (sum, row) =>
                                                    sum + row.waitingAudits,
                                                0,
                                            )}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            {plotData.monthlyData
                                                .reduce(
                                                    (sum, row) =>
                                                        sum + row.waitingPages,
                                                    0,
                                                )
                                                .toLocaleString()}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            {currencyFormatterNoDecimals.format(
                                                plotData.monthlyData.reduce(
                                                    (sum, row) =>
                                                        sum +
                                                        row.potentialRevenue,
                                                    0,
                                                ),
                                            )}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            className={classes.sectionBorder}
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            {plotData.monthlyData.reduce(
                                                (sum, row) =>
                                                    sum + row.rejectedAudits,
                                                0,
                                            )}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            {plotData.monthlyData
                                                .reduce(
                                                    (sum, row) =>
                                                        sum + row.rejectedPages,
                                                    0,
                                                )
                                                .toLocaleString()}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            {currencyFormatterNoDecimals.format(
                                                plotData.monthlyData.reduce(
                                                    (sum, row) =>
                                                        sum + row.lostRevenue,
                                                    0,
                                                ),
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                ) : (
                    <CircularProgress />
                )}
            </Container>
        </Container>
    )
}
