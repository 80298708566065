import React, { useEffect, useState } from 'react'
import {
    Table,
    TableCell,
    TableHead,
    TableContainer,
    TableRow,
    Typography,
    TableBody,
    TablePagination,
    makeStyles,
    TextField,
    createStyles,
    TableSortLabel,
    CircularProgress,
    Switch,
    FormControlLabel,
} from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

import { useTheme } from '@material-ui/core/styles'
import { Container } from '../../Container'

import {
    ConversationAnalysis,
    Workspace,
} from '../../../navigation/layouts/Lori/types'
import { LoriController } from '../../../hooks/useLori'
import { Autocomplete } from '@mui/material'
import { ChatMessage } from '../../../navigation/layouts/Lori/Channels/LoriChannelContent/ChatMessage'
import { AGENT_DRAWER_WIDTH } from '../../../navigation/constants'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        workspaceDropdown: {
            width: 252,
            height: '56px',
            '& .MuiFormControl-root, .MuiInputBase-root': {
                height: '100%',
                color: theme.palette.text.primary,
            },
            '& .MuiInputBase-root': {
                paddingLeft: '12px',
            },
            '& .MuiButtonBase-root': {
                color: theme.palette.text.primary,
            },
            '& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
        },
    }),
)

interface Props {
    loriController: LoriController
}

export const ConversationAnalysisTab = ({ loriController }: Props) => {
    const theme = useTheme()
    const classes = useStyles()
    const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace>({
        id: -1,
        name: 'All',
    })
    const [
        selectedAnalysis,
        setSelectedAnalysis,
    ] = useState<ConversationAnalysis | null>(null)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [orderBy, setOrderBy] = useState<'message_count' | 'created_date'>(
        'created_date',
    )
    const [order, setOrder] = useState<'asc' | 'desc'>('desc')
    const [excludeEzosUsers, setExcludeEzosUsers] = useState(false)

    useEffect(() => {
        loriController.getWorkspaces()
        loriController.getConversationAnalysis(
            rowsPerPage,
            selectedWorkspace.id,
            excludeEzosUsers,
            page + 1,
            orderBy,
            order,
        )
    }, [page, rowsPerPage, selectedWorkspace.id, orderBy, order])

    useEffect(() => {
        const results = loriController.conversationAnalysis?.results
        if (results && results.length > 0 && !selectedAnalysis) {
            setSelectedAnalysis(results[0])
        }
    }, [loriController.conversationAnalysis, selectedWorkspace])

    // Helper functions (to be implemented later)

    const getPositiveFeedbackCount = (analysis: ConversationAnalysis) => {
        return analysis.chat_messages.filter(
            (message) => message.user_feedback?.feedback === true,
        ).length
    }

    const getNegativeFeedbackCount = (analysis: ConversationAnalysis) => {
        return analysis.chat_messages.filter(
            (message) => message.user_feedback?.feedback === false,
        ).length
    }

    const getMessageCount = (analysis: ConversationAnalysis) => {
        return analysis.chat_messages.length
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleSort = (property: 'message_count' | 'created_date') => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    return (
        <Container
            style={{
                flexDirection: 'row',
            }}
        >
            {/* Left side with workspace selector and table */}
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                }}
            >
                {/* Workspace dropdown container */}
                <Container
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Autocomplete
                        disableClearable
                        id="combo-box-demo"
                        options={loriController.workspaces ?? []}
                        getOptionLabel={(option) =>
                            option.name ? option.name : ''
                        }
                        className={classes.workspaceDropdown}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ color: '#FFF' }}
                                placeholder="Search"
                            />
                        )}
                        value={selectedWorkspace}
                        onChange={(
                            event: React.ChangeEvent<{}>,
                            newValue: any,
                        ) => {
                            setSelectedWorkspace(newValue)
                            loriController.getConversationAnalysis(
                                rowsPerPage,
                                selectedWorkspace.id,
                                excludeEzosUsers,
                                page + 1,
                                orderBy,
                                order,
                            )
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={excludeEzosUsers}
                                onChange={(e) => {
                                    setExcludeEzosUsers(e.target.checked)
                                    loriController.getConversationAnalysis(
                                        rowsPerPage,
                                        selectedWorkspace.id,
                                        e.target.checked,
                                        page + 1,
                                        orderBy,
                                        order,
                                    )
                                }}
                                color="primary"
                            />
                        }
                        label="Exclude Ezos Users"
                    />
                </Container>

                {/* Table container */}
                {loriController.conversationAnalysis ? (
                    <Container
                        style={{
                            overflowY: 'scroll',
                            maxHeight: 'calc(100vh - 300px)',
                            maxWidth: '1000px',
                            flex: 1,
                        }}
                    >
                        <TableContainer
                            style={{
                                padding: theme.spacing(2),
                            }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Workspace</TableCell>
                                        <TableCell>User</TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={
                                                    orderBy === 'created_date'
                                                }
                                                direction={
                                                    orderBy === 'created_date'
                                                        ? order
                                                        : 'asc'
                                                }
                                                onClick={() =>
                                                    handleSort('created_date')
                                                }
                                            >
                                                Date
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="right">
                                            Positive Feedback
                                        </TableCell>
                                        <TableCell align="right">
                                            Negative Feedback
                                        </TableCell>
                                        <TableCell align="right">
                                            <TableSortLabel
                                                active={
                                                    orderBy === 'message_count'
                                                }
                                                direction={
                                                    orderBy === 'message_count'
                                                        ? order
                                                        : 'asc'
                                                }
                                                onClick={() =>
                                                    handleSort('message_count')
                                                }
                                            >
                                                Messages
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loriController.conversationAnalysis?.results.map(
                                        (analysis) => (
                                            <TableRow
                                                key={analysis.id}
                                                onClick={() =>
                                                    setSelectedAnalysis(
                                                        analysis,
                                                    )
                                                }
                                                selected={
                                                    selectedAnalysis?.id ===
                                                    analysis.id
                                                }
                                                hover
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <TableCell>
                                                    {analysis.apartment.name}
                                                </TableCell>
                                                <TableCell>
                                                    {analysis.user.name}
                                                </TableCell>
                                                <TableCell>
                                                    {new Date(
                                                        analysis.created_date,
                                                    ).toLocaleDateString()}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {getPositiveFeedbackCount(
                                                        analysis,
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {getNegativeFeedbackCount(
                                                        analysis,
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {getMessageCount(analysis)}
                                                </TableCell>
                                            </TableRow>
                                        ),
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>
                ) : (
                    <Container
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <CircularProgress />
                    </Container>
                )}

                {/* Pagination container */}
                <Container
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bottom: 0,
                    }}
                >
                    <TablePagination
                        component={'div' as React.ElementType}
                        count={loriController.conversationAnalysis?.count ?? 0}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                    />
                </Container>
            </Container>

            {/* Right side messages panel - always show but with placeholder if no selection */}
            <Container
                style={{
                    width: AGENT_DRAWER_WIDTH,
                    height: 'calc(100vh - 220px)',
                    overflowY: 'auto',
                    padding: theme.spacing(2),
                    marginRight: theme.spacing(2),
                    borderRadius: '10px',
                    border: '1px solid #333',
                }}
            >
                {selectedAnalysis ? (
                    <Container
                        style={{
                            flexDirection: 'column',
                            gap: theme.spacing(2),
                        }}
                    >
                        <Typography variant="h6">
                            Conversation with {selectedAnalysis.user.name}
                        </Typography>
                        {selectedAnalysis.chat_messages.map((message) => (
                            <ChatMessage
                                key={message.id}
                                message={message}
                                theme={theme}
                                loriController={loriController}
                            />
                        ))}
                    </Container>
                ) : (
                    <Typography variant="body1" color="textSecondary">
                        Select a conversation to view messages
                    </Typography>
                )}
            </Container>
        </Container>
    )
}
