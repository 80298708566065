import React from 'react'

import { Routes } from './routes'

import { NavigationItem } from '../types/navigation'

import { ReactComponent as ClipboardTickSBIcon } from '../../assets/icons/clipboard-tick.svg'

import { ReactComponent as HomeSBIcon } from '../../assets/icons/grid.svg'
import { ReactComponent as CalendarSBIcon } from '../../assets/icons/group-39654.svg'
import { ReactComponent as HelpCircleSBIcon } from '../../assets/icons/help-circle.svg'
import { ReactComponent as ListSBIcon } from '../../assets/icons/action-item.svg'
import { ReactComponent as SettingsSBIcon } from '../../assets/icons/settings.svg'
import { ReactComponent as MessageSBIcon } from '../../assets/icons/vector-1.svg'
import { ReactComponent as UsersSBIcon } from '../../assets/icons/ion_people-outline.svg'
import { ReactComponent as StarSBIcon } from '../../assets/icons/star.svg'
import { ReactComponent as DollarSignSBIcon } from '../../assets/icons/dollar-sign.svg'
import { ReactComponent as ToolSBIcon } from '../../assets/icons/tool.svg'
import { ReactComponent as FileSBIcon } from '../../assets/icons/file.svg'

import BusinessIcon from '@material-ui/icons/Business'
import HomeIcon from '@material-ui/icons/Home'
import PeopleIcon from '@material-ui/icons/People'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import BubbleChartIcon from '@material-ui/icons/BubbleChart'
import AssessmentIcon from '@material-ui/icons/Assessment';

import CompareIcon from '@material-ui/icons/Compare'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import TableChartOutlined from '@material-ui/icons/TableChartOutlined'
import { VENDOR_INVOICE_PERMISSION, VIEW_INVOICE } from '../../models'
import { ReceiptOutlined } from '@material-ui/icons'
import { AiIcon } from '../layouts/Lori/Icons/_AiIcon'
import { LoriIcon } from '../layouts/Lori/Icons/LoriIcon'
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber'

export const navigationConfig: NavigationConfig = {
    apartment: {
        mainItems: [
            {
                link: Routes.apartment.schedule.root,
                icon: <CalendarSBIcon />,
                name: 'Scheduler',
            },
            {
                link: Routes.apartment.inspection.root,
                icon: <ClipboardTickSBIcon />,
                name: 'Inspection',
            },
            {
                link: Routes.apartment.jobBoard,
                icon: <TableChartOutlined fontSize="large" />,
                name: 'Job Board',
            },
            {
                link: Routes.apartment.actionItems,
                icon: <ListSBIcon height={32} width={32} />,
                name: 'Action Items',
            },
            {
                link: Routes.public.nvl.root,
                icon: <StarSBIcon />,
                name: 'National Vendors List',
            },
            {
                link: Routes.shared.workforce,
                icon: <UsersSBIcon />,
                name: 'Workforce',
            },
            {
                link: Routes.apartment.lease.root,
                icon: <FileSBIcon />,
                name: 'Leases',
            },
            // {
            //     link: Routes.apartment.lease.audit,
            //     icon: <CompareIcon fontSize="large" />,
            //     name: 'Lease Audit',
            // },
            {
                link: Routes.shared.communication.ezchat,
                icon: <MessageSBIcon />,
                name: 'Chat',
            },
            {
                // TODO: change this to the correct route
                link: Routes.apartment.config.damage,
                icon: <ToolSBIcon />,
                name: 'Damages',
            },
            {
                link: Routes.shared.financial.invoiceHome,
                icon: <DollarSignSBIcon />,
                name: 'Finances',
            },
            {
                link: Routes.apartment.config.propertyAutomation,
                icon: <BubbleChartIcon fontSize="large" />,
                name: 'Property Automation',
            },
        ],
        footerItems: [
            // {
            //     link: Routes.shared.settings.root,
            //     icon: <SettingsSBIcon />,
            //     name: 'Settings',
            // },
            // {
            //     link: Routes.shared.help,
            //     icon: <HelpCircleSBIcon />,
            //     name: 'Help',
            // },
        ],
    },
    vendor: {
        mainItems: [
            {
                link: Routes.vendor.ezwork,
                icon: <AssignmentOutlinedIcon fontSize="large" />,
                name: 'EZWork',
            },
            {
                link: Routes.shared.workforce,
                icon: <UsersSBIcon />,
                name: 'Workforce',
            },
            {
                link: Routes.shared.financial.invoiceHome,
                icon: <ReceiptOutlined fontSize="large" />,
                name: 'Invoice',
                requiredPermissions: [VIEW_INVOICE],
            },
        ],
        footerItems: [
            {
                link: Routes.vendor.settings,
                icon: <SettingsSBIcon />,
                name: 'Settings',
            },
            {
                link: Routes.vendor.help,
                icon: <HelpCircleSBIcon />,
                name: 'Help',
            },
        ],
    },
    shared: {
        mainItems: [],
        footerItems: [],
    },
    organization: {
        mainItems: [
            // {
            //     link: Routes.organization.root,
            //     icon: <HomeSBIcon />,
            //     name: 'Dashboard',
            // },
            {
                link: Routes.organization.jobBoard,
                icon: <TableChartOutlined fontSize="large" />,
                name: 'Job Board',
            },
            {
                link: Routes.organization.facilities,
                icon: <ListSBIcon style={{ height: 32, width: 32 }} />,
                name: 'Facilities',
            },
            {
                link: Routes.organization.projects,
                icon: <AssignmentTurnedInIcon fontSize="large" />,
                name: 'Projects',
            },
        ],
        footerItems: [],
    },
    admin: {
        mainItems: [
            {
                link: Routes.admin.organization.list,
                icon: <BusinessIcon fontSize="large" />,
                name: 'Organizations',
            },
            {
                link: Routes.admin.properties.root,
                icon: <HomeIcon fontSize="large" />,
                name: 'Properties',
            },
            {
                link: Routes.admin.users.root,
                icon: <PeopleIcon fontSize="large" />,
                name: 'Users',
            },
            {
                link: Routes.admin.vendors.root,
                icon: <HomeSBIcon fontSize="large" />,
                name: 'Vendors',
            },
            {
                link: Routes.admin.invoices.root,
                icon: <AttachMoneyIcon fontSize="large" />,
                name: 'Invoices',
            },
            {
                link: Routes.admin.loriManager,
                icon: <LoriIcon width={24} height={24} color={'#f4f7f6'} />,
                name: 'Lori Manager',
            },
            {
                link: Routes.admin.supportTicket,
                icon: <ConfirmationNumberIcon />,
                name: 'Support Tickets',
            },
            {
                link: Routes.admin.kpi,
                icon: <AssessmentIcon />,
                name: 'KPI',
            },
        ],
        footerItems: [],
    },
    manage: {
        mainItems: [
            {
                link: Routes.manage.lease.root,
                icon: <FileSBIcon />,
                name: 'Leases',
            },
            {
                link: Routes.manage.lease.audit,
                icon: <CompareIcon fontSize="large" />,
                name: 'Lease Audit',
            },
        ],
        footerItems: [],
    },
}

type NavigationConfig = {
    apartment: {
        mainItems: NavigationItem[]
        footerItems: NavigationItem[]
    }
    vendor: {
        mainItems: NavigationItem[]
        footerItems: NavigationItem[]
    }
    shared: {
        mainItems: NavigationItem[]
        footerItems: NavigationItem[]
    }
    organization: {
        mainItems: NavigationItem[]
        footerItems: NavigationItem[]
    }
    admin: {
        mainItems: NavigationItem[]
        footerItems: NavigationItem[]
    }
    manage: {
        mainItems: NavigationItem[]
        footerItems: NavigationItem[]
    }
}
