import React from 'react'
import { Container } from '../../../../../../components/Container'
import {
    List,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Checkbox,
    Box,
    makeStyles,
} from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/Info'
import { Task } from '../../../types'

const useStyles = makeStyles((theme: Theme) => ({
    listItem: {
        alignItems: 'center',
        marginLeft: theme.spacing(1.5),
        marginTop: 0,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))

interface TaskGroupProps {
    tasks: Task[]
    checked: number[]
    handleToggle: (taskId: number) => () => void
    handleTaskClick: (taskName: string) => void
    theme: Theme
}

export const TaskGroup = ({
    tasks,
    checked,
    handleToggle,
    handleTaskClick,
    theme,
}: TaskGroupProps) => {
    const classes = useStyles()
    return (
        <Box
            mb={2}
            style={{
                maxHeight: 123,
                overflowY: 'scroll',
            }}
        >
            <List dense>
                {tasks.map((task) => (
                    <Container key={task.id} className={classes.listItem}>
                        <ListItemIcon
                            style={{
                                minWidth: 30,
                                display: 'flex',
                            }}
                        >
                            <Checkbox
                                edge="start"
                                checked={checked.indexOf(task.id) !== -1}
                                tabIndex={-1}
                                onClick={handleToggle(task.id)}
                                disableRipple
                            />
                        </ListItemIcon>
                        <Tooltip title="Help me do this">
                            <InfoIcon
                                onClick={() =>
                                    handleTaskClick(task.planned_task.name)
                                }
                                style={{
                                    marginRight: theme.spacing(1),
                                }}
                            />
                        </Tooltip>

                        <ListItemText
                            primary={task.planned_task.name}
                            onClick={() =>
                                handleTaskClick(task.planned_task.name)
                            }
                        />
                    </Container>
                ))}
            </List>
        </Box>
    )
}
