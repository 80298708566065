import React, { useEffect, useState } from 'react'
import { Container } from '../../../../../../components/Container'
import { IconButton, Tooltip, Typography, useTheme } from '@material-ui/core'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import {
    ChatMessageContent,
    FeedbackContext,
    MessageFeedback,
} from '../../../types'
import { LoriController } from '../../../../../../hooks/useLori'

interface UserFeedbackProps {
    message: ChatMessageContent
    loriController: LoriController
    context: FeedbackContext
}

export const UserFeedback = (props: UserFeedbackProps) => {
    const { message, loriController, context } = props
    const theme = useTheme()
    const [feedback, setFeedback] = useState<'positive' | 'negative' | null>(
        null,
    )

    useEffect(() => {
        const existingFeedback = loriController.conversationFeedback.find(
            (f) => f.message_id === message.id,
        )

        if (existingFeedback) {
            setFeedback(existingFeedback.feedback ? 'positive' : 'negative')
        }
    }, [message.id, loriController.conversationFeedback])

    return (
        <Container
            style={{
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <Typography style={{ ...theme.typography.body2 }}>
                Was this response helpful?
            </Typography>
            <IconButton
                onClick={() => {
                    if (message.id) {
                        loriController.addFeedback(true, context, message.id)
                        setFeedback('positive')
                    }
                }}
            >
                <ThumbUpIcon
                    style={{
                        color:
                            feedback === 'positive'
                                ? theme.palette.success.main
                                : 'inherit',
                    }}
                />
            </IconButton>
            <IconButton
                onClick={() => {
                    if (message.id) {
                        loriController.addFeedback(false, context, message.id)
                        setFeedback('negative')
                    }
                    loriController.handleSendMessage(
                        'I am not happy with those results. Can I contact support.',
                        false,
                    )
                }}
            >
                <ThumbDownIcon
                    style={{
                        color:
                            feedback === 'negative'
                                ? theme.palette.error.main
                                : 'inherit',
                    }}
                />
            </IconButton>
        </Container>
    )
}
