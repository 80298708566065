import { Conversation } from '../navigation/layouts/Lori/types'
import { WorkSpaceUser } from './Users'

export interface IdentifiableObject {
    id: number
}

export interface IdString {
    id: string
}

export interface IdentifiableNamedObject extends IdentifiableObject {
    name: string
}

export type UserOrCompanyString = 'USER' | 'COMPANY'

export interface IdIntMap {
    [id: number]: number | undefined
}

export interface ModelMap<T extends IdentifiableObject> {
    [id: number]: T | undefined
    readyForUse?: boolean
}

export interface IdBoolMap {
    [id: number]: boolean
}

export interface LengthTrackingIdBoolMap extends IdBoolMap {
    length: number
}

export interface ModelListMap<T extends IdentifiableObject> {
    [id: number]: T[] | undefined
    readyForUse?: boolean
}

export interface Timeline {
    startDate: Date
    endDate: Date
}

export interface StringNumberMap {
    [str: string]: number
}

export interface BaseModel extends IdentifiableObject {
    created_date: string
    modified_date: string
}

export interface Address extends BaseModel {
    address1: string
    address2: string
    city: string
    state: string
    zip_code: string
    country: string
    utc_offset: number
    dst_offset: number
}

export interface VideoAdvertisement extends IdentifiableObject {
    name: string
    video: string
    expiration_date: string
    created_date: string
    modified_date: string
    companies: number[]
    video_name: string
    active: boolean
}

export enum SupportTicketStatus {
    OPEN = 'OPEN',
    COMPLETE = 'COMPLETE',
}

export enum SupportTicketCategory {
    WEBSITE = 'WEBSITE',
    APP = 'APP',
}

export enum SupportTicketSubCategory {
    LOGIN = 'LOGIN',
    SCHEDULE = 'SCHEDULE',
    PRICING_INVOICING = 'PRICING_INVOICING',
    INSPECTION = 'INSPECTION',
    OTHER = 'OTHER',
}

export interface SupportTicketAttachment extends IdentifiableObject {
    file: string
    support_ticket: number
}

export interface SupportTicket extends IdentifiableObject {
    user: WorkSpaceUser | null
    name: string
    email: string | null
    phone_number: string | null
    issue_description: string
    property_info: string | null
    category: SupportTicketCategory | null
    sub_category: SupportTicketSubCategory | null
    status: SupportTicketStatus
    is_vendor: boolean
    twilio_call_event: number | null
    attachments: SupportTicketAttachment[]
    conversation: Conversation | null
    created_date: string
    completed_by: WorkSpaceUser | null
}
